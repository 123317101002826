import { useContext, memo, useEffect, useState } from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  Paper,
  TableRow,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TableCell,
  TableBody,
} from '@material-ui/core';
import MtaContext from './MtaContext';
import { MESSAGE_TYPES } from '../../../../../api/messageTypes';
import { callWs } from '../../../../../api/websocket';
import { useMutation } from 'react-query';
import * as customToast from '../../../../toast/customToast';
import { ContactsOutlined } from '@material-ui/icons';
import { dateFormater } from '../../../../../utils/valueFormatter';
import { format } from 'date-fns';

function createData(
  name: string,
  premium: number,
  premiumPercentage: number,
  taxesAndFees: number,
  totalWithTaxes: number,
) {
  return {
    name,
    premium,
    premiumPercentage,
    taxesAndFees,
    totalWithTaxes,
  };
}

function createDataAnalysis(
  name: string,
  policyFee: number,
  surplusLinesTax: number,
  surplusLinesStumpingFee: number,
) {
  return {
    name,
    policyFee,
    surplusLinesTax,
    surplusLinesStumpingFee,
  };
}

const InfoPanel: React.FunctionComponent<any> = (props) => {
  const { selectedCard, setSelectedCard } = useContext(MtaContext);
  const { policy, fetchPolicy } = props;
  const [amounts, setAmounts] = useState<any>([]);
  const [title, setTitle] = useState<any>();

  const {
    mutateAsync: fetchAmounts,
    isLoading: isFetchAmountsLoading,
  } = useMutation(MESSAGE_TYPES.FETCH_AMOUNTS, (data: any) =>
    callWs(MESSAGE_TYPES.FETCH_AMOUNTS, data),
  );

  let cancellation = {
    cancellationDate: '',
    total: 0,
    tax: 0,
    earnedPremium: 0,
    earnedTax: 0,
    paidPremium: 0,
    paidTax: 0,
    minimumPremium: null,
    cBPremium: 0,
    cBTax: 0,
    cBTotal: 0,
  };
  const _fetchAmounts = async () => {
    await fetchAmounts({ policy_id: policy.id }).then((data: any) => {
      setAmounts(data);
      setSelectedCard(data.length - 1);
    });
  };

  useEffect(() => {
    _fetchAmounts();
  }, []);

  const mta0 = {
    title: amounts[selectedCard]?.key,
    data: [
      createData(
        'Insurer',
        amounts[selectedCard]?.amounts?.insurerPremium,
        70,
        amounts[selectedCard]?.amounts?.insurerTaxes,
        amounts[selectedCard]?.amounts?.insurerPremiumWithTaxes,
      ),
      createData(
        'MGA',
        amounts[selectedCard]?.amounts?.mgaPremium,
        10,
        amounts[selectedCard]?.amounts?.mgaTaxes,
        amounts[selectedCard]?.amounts?.mgaPremiumWithTaxesAndSurcharge,
      ),
      createData(
        'Agent',
        amounts[selectedCard]?.amounts?.agentPremium,
        20,
        amounts[selectedCard]?.amounts?.agentTaxes,
        amounts[selectedCard]?.amounts?.agentPremiumWithTaxes,
      ),
      createData(
        'Total',
        amounts[selectedCard]?.amounts?.total,
        100,
        amounts[selectedCard]?.amounts?.taxes,
        amounts[selectedCard]?.amounts?.totalWithTaxesAndSurcharge,
      ),
      /* createDataAnalysis(
        'Tax Analysis',
        amounts[selectedCard]?.additionalCharges?.surplusLinesTax,
        amounts[selectedCard]?.additionalCharges?.surplusLinesStumpingFee,
        amounts[selectedCard]?.additionalCharges?.policyFee,
      ),*/
    ],
  };

  const taxAnalysis = {
    name: 'Tax Analysis',
    surplusLinesTax: amounts[selectedCard]?.additionalCharges?.surplusLinesTax,
    surplusLinesStampingFee: amounts[selectedCard]?.additionalCharges?.surplusLinesStampingFee,
    policyFee: amounts[selectedCard]?.additionalCharges?.policyFee,
  };
  if (amounts.length >= 1) {
    if (amounts[amounts.length - 1]['cancellation']?.total) {
      cancellation = {
        cancellationDate: format(
          dateFormater(amounts[amounts.length - 1].cancellation?.cancellationDate),
          'MM-dd-yyyy',
        ),
        total: amounts[amounts.length - 1].cancellation?.total,
        tax: amounts[amounts.length - 1].cancellation?.tax,
        earnedPremium: amounts[amounts.length - 1].cancellation?.earnedPremium,
        earnedTax: amounts[amounts.length - 1].cancellation?.earnedTax,
        paidPremium: amounts[amounts.length - 1].cancellation?.paidPremium,
        paidTax: amounts[amounts.length - 1].cancellation?.paidTax,
        minimumPremium: amounts[amounts.length - 1].cancellation?.minimumPremium,
        cBPremium: amounts[amounts.length - 1].cancellation?.cBPremium,
        cBTax: amounts[amounts.length - 1].cancellation?.cBTax,
        cBTotal: amounts[amounts.length - 1].cancellation?.cBTotal,
      };
    }
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <h2>{mta0.title}</h2>
              </TableCell>
              <TableCell align="center">Premium</TableCell>
              <TableCell align="center">Premium Percentage&nbsp;(%)</TableCell>
              <TableCell align="center">Taxes and Fees&nbsp;</TableCell>
              <TableCell align="center">Total with Taxes&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mta0.data.map((row) =>
              row.name === 'Tax Analysis' ? null : (
                <TableRow key={row.name}>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="center">{row.premium}</TableCell>
                  <TableCell align="center">{row.premiumPercentage}</TableCell>
                  <TableCell align="center">{row.taxesAndFees}</TableCell>
                  <TableCell align="center">{row.totalWithTaxes}</TableCell>
                </TableRow>
              ),
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <h3>Additional Charges</h3>
      {
        <TableContainer style={{ marginTop: '5px' }} component={Paper}>
          <Table>
            <TableHead title="tax analysis">
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="center">Surplus Lines</TableCell>
                <TableCell align="center">Surplus Lines Stamping Fee&nbsp;</TableCell>
                <TableCell align="center">Policy Fee&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key={''}>
                <TableCell component="th" scope="row">
                  {taxAnalysis.name}
                </TableCell>
                <TableCell align="center">{taxAnalysis.surplusLinesTax}</TableCell>
                <TableCell align="center">{taxAnalysis.surplusLinesStampingFee}</TableCell>
                <TableCell align="center">{taxAnalysis.policyFee}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      }
      {cancellation?.total ? (
        <>
          <h3>Cancellation Information</h3>
          <TableContainer style={{ marginTop: '5px' }} component={Paper}>
            <Table>
              <TableHead title="tax analysis">
                <TableRow>
                  <TableCell align="center">Cancellation Date</TableCell>
                  <TableCell align="center">Policy Premium</TableCell>
                  <TableCell align="center">Policy Tax</TableCell>
                  <TableCell align="center">Earned Premium</TableCell>
                  <TableCell align="center">Earned Tax</TableCell>
                  <TableCell align="center">Paid Premium</TableCell>
                  <TableCell align="center">Paid Tax</TableCell>
                  <TableCell align="center">Minimum Premium</TableCell>
                  <TableCell align="center">
                    {/* {cancellation?.cBPremium < 0 ? 'Return ' : ' Additional'} Premium */}
                    Due Premium
                  </TableCell>
                  <TableCell align="center">
                    {/* {cancellation?.cBTax < 0 ? 'Return ' : ' Additional'} Tax */}
                    Due Tax
                  </TableCell>
                  <TableCell align="center">
                    {/* {cancellation?.cBTotal < 0 ? 'Return ' : ' Additional'} Total */}
                    Due Total
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key={''}>
                  <TableCell align="center">{cancellation?.cancellationDate}</TableCell>
                  <TableCell align="center">{cancellation?.total}</TableCell>
                  <TableCell align="center">{cancellation?.tax}</TableCell>
                  <TableCell align="center">{cancellation?.earnedPremium}</TableCell>
                  <TableCell align="center">{cancellation?.earnedTax}</TableCell>
                  <TableCell align="center">{cancellation?.paidPremium}</TableCell>
                  <TableCell align="center">{cancellation?.paidTax}</TableCell>
                  <TableCell align="center">{cancellation?.minimumPremium}</TableCell>
                  <TableCell align="center">{cancellation?.cBPremium}</TableCell>
                  <TableCell align="center">{cancellation?.cBTax}</TableCell>
                  <TableCell align="center">{cancellation?.cBTotal}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default memo(InfoPanel);

import {
  Grid,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { useMutation } from 'react-query';
import { callWs } from '../../../api/websocket';
import { MESSAGE_TYPES } from '../../../api/messageTypes';
import * as customToast from '../../toast/customToast';
import BooleanQuestion from '../questions/boolean-question';

const inputDescriptions = [
  {
    name: 'mtaNumber',
    label: 'MTA Number',
    type: 'text',
    presentation: 'text',
    validation: {
      front: { required: false },
    },
  },
  {
    name: 'effectiveDate',
    label: 'Effective Date',
    type: 'date',
    presentation: 'text',
    validation: {
      front: { required: true },
    },
  },
  {
    name: 'status',
    label: 'MTA Status',
    type: 'text',
    presentation: 'text',
    validation: {
      front: { required: true },
    },
  },
];

const CreateMtaDialog = (props) => {
  const { open, handleClose, selectedRow, installments, canMerge, handleMtaCreate } = props;

  const [isMtaLoading, setIsMtaLoading] = useState(false);

  const { control, watch, getValues, reset, handleSubmit, trigger, errors } = useForm<any>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      ...inputDescriptions
        .map((inputDescription) => ({
          [inputDescription.name]: inputDescription.type === 'boolean' ? false : null,
        }))
        .reduce((acc, next) => Object.assign(acc, next), {}),
    },
  });

  const [warning, setWarning] = useState(false);

  const DialogWarning = () => {
    return (
      <>
        <Dialog open={warning} onClose={() => setWarning(false)}>
          <DialogContent>
            The System indicates that those invoices can not be merged. Please select Number of
            Invoices.
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setWarning(false)} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create MTA</DialogTitle>
        <DialogContent style={{ overflow: 'hidden' }}>
          <Grid container item spacing={4}>
            <Grid item xs={6} md={6} style={{ display: 'flex', justifyContent: 'center' }}>
              <Controller
                render={({ onChange, value }) => (
                  <BooleanQuestion
                    questionName="emailDocument"
                    questionLabel="Receiving Invoices by email?"
                    labelPlacement="top"
                    onChange={(data) => {
                      onChange(data);
                    }}
                    value={value}
                  />
                )}
                control={control}
                defaultValue={selectedRow.emailDocument}
                name="emailDocument"
              />
            </Grid>
            <Grid item xs={6} md={6} style={{ display: 'flex', justifyContent: 'center' }}>
              <Controller
                render={({ onChange, value }) => (
                  <BooleanQuestion
                    questionName="postDocument"
                    questionLabel="Receiving Invoices by post mail?"
                    labelPlacement="top"
                    onChange={(data) => {
                      onChange(data);
                    }}
                    value={value}
                  />
                )}
                defaultValue={selectedRow.postDocument}
                control={control}
                name="postDocument"
              />
            </Grid>
            <Grid item xs={6} md={6} style={{ display: 'flex', justifyContent: 'center' }}>
              <Controller
                render={({ onChange, value }) => (
                  <BooleanQuestion
                    questionName="emailDocMga"
                    questionLabel="Email MGA?"
                    labelPlacement="top"
                    onChange={(data) => {
                      onChange(data);
                    }}
                    value={value}
                  />
                )}
                defaultValue={selectedRow.emailDocMga}
                control={control}
                name="emailDocMga"
              />
            </Grid>
            <Grid item xs={6} md={6} style={{ display: 'flex', justifyContent: 'center' }}>
              <Controller
                render={({ onChange, value }) => (
                  <BooleanQuestion
                    questionName="emailDocAgent"
                    questionLabel="Email Agent?"
                    labelPlacement="top"
                    onChange={(data) => {
                      onChange(data);
                    }}
                    value={value}
                  />
                )}
                defaultValue={selectedRow.emailDocAgent}
                control={control}
                name="emailDocAgent"
              />
            </Grid>{' '}
            <Grid item xs={6} md={12} style={{ display: 'flex', justifyContent: 'center' }}>
              <Controller
                render={(props) => (
                  <KeyboardDatePicker
                    required
                    margin="dense"
                    label={'Effective Date'}
                    format="MM/dd/yyyy"
                    name="effectiveDate"
                    autoOk
                    onChange={(data) => {
                      props.onChange(data);
                    }}
                    value={props.value}
                  />
                )}
                name={'effectiveDate'}
                control={control}
              />
            </Grid>
            <Grid item xs={6} md={12} style={{ display: 'flex', justifyContent: 'center' }}>
              <Grid item>
                <Button
                  color="primary"
                  fullWidth
                  variant="contained"
                  onClick={() => {
                    const updatedValues = getValues();
                    handleMtaCreate(updatedValues);
                    handleClose();
                  }}
                >
                  {isMtaLoading ? <CircularProgress color="secondary" size={24} /> : 'Submit'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <DialogWarning />
    </>
  );
};

export default CreateMtaDialog;

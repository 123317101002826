import {
  Grid,
  Button,
  CircularProgress,
  Dialog,
  TextField,
  MenuItem,
  DialogContent,
  DialogActions,
  DialogTitle,
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { format } from 'date-fns';
import { KeyboardDatePicker } from '@material-ui/pickers';

import { callWs } from '../../../api/websocket';
import { MESSAGE_TYPES } from '../../../api/messageTypes';
import * as customToast from '../../toast/customToast';
import BooleanQuestion from '../questions/boolean-question';
import { useHistory } from 'react-router-dom';

const CancelDialog = (props) => {
  const { open, handleClose, policy, fetchPolicy, options, specificOptions } = props;
  const history = useHistory();

  const { control, watch, getValues, errors } = useForm<any>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      isCancelFlat: false,
      hasMinimumPremium: true,
      minimumPremium: 500,
      cancellationDate: new Date(),
      reason: 17000,
      specificUWReason: 17101,
    },
  });

  const selectedReason = watch('reason');
  const hasMinimumPremium = watch('hasMinimumPremium');

  const {
    mutateAsync: cancelPolicy,
    isLoading: isCancelPolicyLoading,
  } = useMutation(MESSAGE_TYPES.POLICY_CANCELLATION, (data: any) =>
    callWs(MESSAGE_TYPES.POLICY_CANCELLATION, data),
  );

  const _handlePolicyCancellation = async (data) => {
    try {
      data.policyId = policy.id;
      data.cancellationDate = format(data.cancellationDate, 'yyyy-MM-dd');
      const cancelResponse: any = await cancelPolicy(data);
      history.push('/policy/edit/' + cancelResponse);
      customToast.success('Policy was cancelled successfully');
      fetchPolicy();
      handleClose();
    } catch (err) {
      customToast.error(err.messageCode, {
        id: 'cancellation-error',
      });
    }
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Cancel Policy</DialogTitle>
        <DialogContent style={{ overflow: 'hidden' }}>
          <Grid container item spacing={4}>
            <Grid item xs={6} md={6} style={{ display: 'flex', justifyContent: 'center' }}>
              <Controller
                render={({ onChange, value }) => (
                  <BooleanQuestion
                    questionName="isCancelFlat"
                    questionLabel="Cancel Flat"
                    labelPlacement="top"
                    onChange={(data) => {
                      onChange(data);
                    }}
                    value={value}
                  />
                )}
                defaultValue={false}
                control={control}
                name="isCancelFlat"
              />
            </Grid>
            <Grid item xs={6} md={6} style={{ display: 'flex', justifyContent: 'center' }}>
              <Controller
                render={({ value, onChange }) => (
                  <KeyboardDatePicker
                    required
                    margin="dense"
                    fullWidth
                    label="Cancellation Date"
                    format="MM/dd/yyyy"
                    name="cancellationDate"
                    autoOk
                    onChange={(data) => {
                      onChange(data);
                    }}
                    value={value}
                  />
                )}
                error={errors.cancellationDate !== undefined}
                name="cancellationDate"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Cancellation Date is required',
                  },
                }}
              />
            </Grid>
            <Grid item xs={6} md={6} style={{ display: 'flex', justifyContent: 'center' }}>
              <Controller
                render={({ onChange, value }) => (
                  <BooleanQuestion
                    questionName="hasMinimumPremium"
                    questionLabel="Apply minimum premium"
                    labelPlacement="top"
                    onChange={(data) => {
                      onChange(data);
                    }}
                    value={value}
                  />
                )}
                defaultValue={false}
                control={control}
                name="hasMinimumPremium"
              />
            </Grid>
            {hasMinimumPremium === true ? (
              <Grid item xs={6} md={6} style={{ display: 'flex', justifyContent: 'center' }}>
                <Controller
                  render={({ onChange, value }) => (
                    <TextField
                      required
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      type="number"
                      InputProps={{ inputProps: { min: 0 } }}
                      label="Minimum Premium"
                      onChange={(data) => {
                        onChange(data);
                      }}
                      value={value}
                    />
                  )}
                  control={control}
                  name="minimumPremium"
                />
              </Grid>
            ) : (
              <Grid item xs={6} md={6} style={{ display: 'flex', justifyContent: 'center' }}></Grid>
            )}
            <Grid item xs={6} md={6} style={{ display: 'flex', justifyContent: 'center' }}>
              <Controller
                render={({ value, onChange }) => (
                  <TextField
                    required
                    fullWidth
                    select
                    label="Reason"
                    margin="dense"
                    value={value}
                    InputLabelProps={{ shrink: true }}
                    error={errors.reason !== undefined}
                    helperText={errors.reason?.message}
                    defaultValue={options[0]}
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                  >
                    {options.map((option, index) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
                control={control}
                name="reason"
                rules={{
                  required: {
                    value: true,
                    message: 'Reason is required',
                  },
                }}
              />
            </Grid>
            {selectedReason === 17001 ? (
              <Grid item xs={6} md={6} style={{ display: 'flex', justifyContent: 'center' }}>
                <Controller
                  render={({ value, onChange }) => (
                    <TextField
                      required
                      fullWidth
                      select
                      label="Specific Reason"
                      margin="dense"
                      value={value}
                      InputLabelProps={{ shrink: true }}
                      error={errors.reason !== undefined}
                      helperText={errors.reason?.message}
                      defaultValue={options[0]}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                    >
                      {specificOptions.map((option, index) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                  control={control}
                  name="specificUWReason"
                  rules={{
                    required: {
                      value: true,
                      message: 'Specific Reason is required',
                    },
                  }}
                />
              </Grid>
            ) : (
              <></>
            )}
            <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                color="primary"
                fullWidth
                variant="contained"
                size="medium"
                onClick={() => {
                  const data = getValues();
                  _handlePolicyCancellation(data);
                }}
              >
                {isCancelPolicyLoading ? <CircularProgress size={20} /> : 'Cancel Policy'}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CancelDialog;

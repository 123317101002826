import { useContext, useState } from 'react';
import {
  AppBar,
  IconButton,
  makeStyles,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { AccountCircle as AccountCircleIcon, Menu as MenuIcon } from '@material-ui/icons';

import { AuthContext } from '../../shared/providers/AuthProvider';
import { LayoutContext } from '../../shared/providers/LayoutProvider';
import { useSidebar } from '../../shared/hooks/use-sidebar';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
}));

const HeaderBar = () => {
  const classes = useStyles();

  const {
    authenticationData: { isAuthenticated },
    setAuthenticationData,
  } = useContext(AuthContext);

  const {
    layoutData: { headerTitle },
  } = useContext(LayoutContext);

  const [_, toggleSidebar] = useSidebar();

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const _handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const _handleClose = () => {
    setAnchorEl(null);
  };

  const _handleLogout = () => {
    localStorage.removeItem('auth');
    setAuthenticationData({ isAuthenticated: false, token: undefined, permissions: undefined });
    _handleClose();
  };

  return (
    <header className={classes.root}>
      <AppBar position="fixed">
        <Toolbar variant="dense">
          {isAuthenticated && (
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={toggleSidebar(true)}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography variant="h6" className={classes.title}>
            {headerTitle}
          </Typography>
          {isAuthenticated && (
            <div>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={_handleMenu}
                color="inherit"
              >
                <AccountCircleIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={_handleClose}
              >
                <MenuItem onClick={_handleLogout}>Logout</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </header>
  );
};

export default HeaderBar;

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ConfirmProvider } from 'material-ui-confirm';

import AppLayout from './layout/AppLayout';

const App: React.FC = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      mutations: {
        retry: false,
      },
      queries: {
        retry: false,
        staleTime: 5 * 1000 * 60,
        refetchOnWindowFocus: false,
      },
    },
  });

  return (
    <ConfirmProvider>
      <QueryClientProvider client={queryClient}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <AppLayout />
          <ReactQueryDevtools />
        </MuiPickersUtilsProvider>
      </QueryClientProvider>
    </ConfirmProvider>
  );
};

export default App;

import { Grid, Button, Typography, Chip, Box, Paper } from '@material-ui/core';
import { useState, memo, useReducer, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Edit as EditIcon } from '@material-ui/icons';

import ClientsDialog from '../../../../../clients-dialog/ClientsDialog';
import { MESSAGE_TYPES } from '../../../../../../../../api/messageTypes';
import { callWs } from '../../../../../../../../api/websocket';
import { useMutation } from 'react-query';
import { removeNullValues } from '../../../../../../../../utils/utils';
import * as customToast from '../../../../../../../toast/customToast';

export interface LossPayeesProps {
  rowData: any;
  rowIndex: number;
  insuredEntityName: string;
  insuredEntities: any;
  setInsuredEntities: any;
}

const LossPayees: React.FC<LossPayeesProps> = (props) => {
  const { rowData, rowIndex, insuredEntityName, insuredEntities, setInsuredEntities } = props;

  const [showLossPayees, setShowLossPayees] = useState(false);
  const [showClientsModal, setShowClientsModal] = useState(false);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const { mutateAsync: createLossPayee } = useMutation(MESSAGE_TYPES.PERSON_CREATE, (data: any) =>
    callWs(MESSAGE_TYPES.PERSON_CREATE, data),
  );

  const { mutateAsync: addLossPayee } = useMutation(MESSAGE_TYPES.ADD_LOSS_PAYEE, (data: any) =>
    callWs(MESSAGE_TYPES.ADD_LOSS_PAYEE, data),
  );

  const { mutateAsync: updateLossPayee } = useMutation(MESSAGE_TYPES.PERSON_UPDATE, (data: any) =>
    callWs(MESSAGE_TYPES.PERSON_UPDATE, data),
  );

  const { mutateAsync: deleteLossPayee } = useMutation(
    MESSAGE_TYPES.DELETE_LOSS_PAYEE,
    (data: any) => callWs(MESSAGE_TYPES.DELETE_LOSS_PAYEE, data),
  );

  useEffect(() => {
    if (rowData?.lossPayees?.length > 0) {
      setShowLossPayees(true);
    }
  }, [rowData?.lossPayees]);

  const _createLossPayee = async (data) => {
    try {
      const id = await createLossPayee({
        ...removeNullValues({ ...data }),
        relationId: rowData?.id,
        relationType: 'insuredEntity',
      });
      rowData.lossPayees = [...(rowData?.lossPayees || []), { id, ...data }];
      setInsuredEntities({
        ...insuredEntities,
        [insuredEntityName]: [
          ...insuredEntities[insuredEntityName]?.slice(0, rowIndex),
          { ...rowData },
          ...insuredEntities[insuredEntityName]?.slice(
            rowIndex + 1,
            insuredEntities[insuredEntityName].length,
          ),
        ],
      });
      forceUpdate();
    } catch (err) {
      customToast.error(err.messageCode);
      throw err;
    }
  };

  const _addLossPayee = async (data) => {
    try {
      await addLossPayee({
        insuredEntityId: rowData?.id,
        id: data.id,
      });

      rowData.lossPayees = [...(rowData?.lossPayees || []), { ...data }];
      setInsuredEntities({
        ...insuredEntities,
        [insuredEntityName]: [
          ...insuredEntities[insuredEntityName]?.slice(0, rowIndex),
          { ...rowData },
          ...insuredEntities[insuredEntityName]?.slice(
            rowIndex + 1,
            insuredEntities[insuredEntityName].length,
          ),
        ],
      });
      forceUpdate();
    } catch (err) {
      customToast.error(err.messageCode);
      throw err;
    }
  };

  const _updateLossPayee = async (updatedId, newData) => {
    try {
      await updateLossPayee({
        id: updatedId,
        ...removeNullValues({ ...newData }),
      });
      const updatedIndex = rowData?.lossPayees.findIndex((row: any) => row.id === updatedId);
      rowData.lossPayees = [
        ...rowData?.lossPayees.slice(0, updatedIndex),
        newData,
        ...rowData?.lossPayees.slice(updatedIndex + 1, rowData?.lossPayees.length),
      ];
      setInsuredEntities({
        ...insuredEntities,
        [insuredEntityName]: [
          ...insuredEntities[insuredEntityName]?.slice(0, rowIndex),
          { ...rowData },
          ...insuredEntities[insuredEntityName]?.slice(
            rowIndex + 1,
            insuredEntities[insuredEntityName].length,
          ),
        ],
      });
      forceUpdate();
    } catch (err) {
      customToast.error(err.messageCode);
      throw err;
    }
  };

  const _deleteLossPayee = async (removedId) => {
    try {
      await deleteLossPayee({
        id: removedId,
        insuredEntityId: rowData?.id,
      });

      rowData.lossPayees = rowData?.lossPayees.filter((details) => details.id !== removedId);
      setInsuredEntities({
        ...insuredEntities,
        [insuredEntityName]: [
          ...insuredEntities[insuredEntityName]?.slice(0, rowIndex),
          { ...rowData },
          ...insuredEntities[insuredEntityName]?.slice(
            rowIndex + 1,
            insuredEntities[insuredEntityName].length,
          ),
        ],
      });
      forceUpdate();
    } catch (err) {
      customToast.error(err.messageCode);
      throw err;
    }
  };

  return (
    <Grid container item xs={12} justify="flex-end">
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            rowData?.lossPayees?.length !== 0
              ? setShowLossPayees((previousValue) => !previousValue)
              : setShowClientsModal(true)
          }
        >
          {rowData?.lossPayees?.length !== 0
            ? (showLossPayees ? 'Hide' : 'Show') + ' Loss Payees'
            : 'Add loss payee'}
        </Button>
      </Grid>
      {showLossPayees && (
        <Grid container item spacing={2}>
          <Grid item xs>
            <Typography>Loss payees</Typography>
          </Grid>
          <Grid container item>
            <Grid item xs={10}>
              {rowData?.lossPayees?.map((lossPayee) => (
                <Chip
                  key={lossPayee.id}
                  label={
                    lossPayee.isCompany
                      ? lossPayee.companyName
                      : `${lossPayee.firstName} ${lossPayee.lastName}`
                  }
                  style={{ marginLeft: 8 }}
                  onDelete={() => _deleteLossPayee(lossPayee.id)}
                />
              ))}
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<EditIcon />}
                onClick={() => setShowClientsModal(true)}
              >
                Edit Loss payees
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
      <ClientsDialog
        open={showClientsModal}
        savedClients={rowData?.lossPayees}
        handleInsert={_createLossPayee}
        handleAdd={_addLossPayee}
        handleUpdate={_updateLossPayee}
        handleRemove={_deleteLossPayee}
        handleClose={() => setShowClientsModal(false)}
      />
    </Grid>
  );
};

export default memo(LossPayees);

import Sarus from '@anephenix/sarus';

let connection;

export const callWs = (message, data = {}) => {
  return new Promise((resolve, reject) => {
    //TODO: Fix multiple connections
    // if (!connection) {
    connection = new Sarus({
      url: process.env.REACT_APP_TIMEKEEPER_URL || 'ws://localhost:9090',
    });
    // connection = new WebSocket(process.env.REACT_APP_TIMEKEEPER_URL || 'ws://localhost:9090');
    // }

    connection.send(
      JSON.stringify({
        message,
        data,
      }),
    );

    let jobId = null;

    connection.on('message', function (evt) {
      const msg = JSON.parse(evt.data);

      if (msg.jobStatus === 'pending') {
        jobId = msg.jobId;
      } else {
        if (jobId === msg.jobId) {
          msg.jobStatus === 'success' ? resolve(msg.result.data) : reject(msg.error);
        }
      }
    });
  });
};

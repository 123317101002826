import React from 'react';
import { InputWrapperProps } from '../InputWrapper';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { format } from 'date-fns';
import { handleKey } from '../../../../utils/formUtils';

export interface DateInputProps extends InputWrapperProps {
  value: any;
  onChange: (value: any) => void;
}

const DateInput: React.FC<DateInputProps> = (props) => {
  const {
    properties,
    displayMode,
    value,
    onChange,
    afterOnChange,
    evaluateRules,
    onKeyDown,
    displayLabel,
    error,
    helperText,
  } = props;

  if (displayMode === 'read') {
    if (!value) {
      return <span> - </span>;
    }
    const date = properties.presentation === 'year' ? new Date(value, 0, 1) : new Date(value);
    let formattedDate = format(date, 'MM/dd/yyyy');
    if (properties.presentation === 'year') {
      formattedDate = format(date, 'yyyy');
    }

    return <span>{formattedDate}</span>;
  }
  let min;
  if (properties?.validation?.front?.min < 0) {
    min = new Date().getUTCFullYear() + parseInt(properties?.validation?.front?.min);
  } else if (properties?.validation?.front?.min === '@startOfYear') {
    min = new Date().getFullYear();
  } else {
    min = properties?.validation?.front?.min;
  }

  return (
    <KeyboardDatePicker
      fullWidth
      required={properties?.validation?.front?.required}
      views={properties.presentation === 'year' ? ['year'] : ['date']}
      variant="inline"
      minDate={min ? new Date(min, 0, 1) : undefined}
      minDateMessage={`Date should not be earlier than ${min}`}
      disableFuture={properties?.validation?.front?.max === 'now'}
      maxDateMessage="Date should not be in the future"
      label={displayLabel ? properties.label : undefined}
      format={properties.presentation === 'year' ? 'yyyy' : 'MM/dd/yyyy'}
      autoOk
      value={
        !value ? null : properties.presentation === 'year' ? new Date(value, 0, 1) : new Date(value)
      }
      onChange={(_, newValue) => {
        onChange(newValue);
        if (evaluateRules) {
          evaluateRules(newValue);
        }
        afterOnChange?.();
      }}
      onKeyDown={(e) => (onKeyDown ? onKeyDown(e) : handleKey(e))}
      InputLabelProps={{ shrink: true }}
      KeyboardButtonProps={{
        'aria-label': 'change date',
      }}
      {...(error ? { error } : undefined)}
      {...(helperText ? { helperText } : undefined)}
      margin="dense"
    />
  );
};

export default DateInput;

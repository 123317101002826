import { memo, useState } from 'react';
import { AppBar, Tab, Tabs, Typography, Box, Paper, Grid } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';

import InsuredEntitiesForm from '../insured-entities-form/InsuredEntitiesForm';
import InsuredEntitySingleton from '../insured-entity-singleton/InsuredEntitySingleton';
import { formatValueFrom } from '../../../../../../utils/valueFormatter';
import { groupBy } from '../../../../../../utils/utils';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface InsuredEntitiesWrapperProps {
  policy: any;
  onPopup: any;
  insuredEntities: any;
  setInsuredEntities: any;
}

const InsuredEntitiesWrapper: React.FC<InsuredEntitiesWrapperProps> = (props) => {
  const { policy, onPopup, insuredEntities, setInsuredEntities } = props;

  const [tab, setTab] = useState(0);
  const handleChange = (_, newValue: number) => {
    setTab(newValue);
  };

  const data = policy.productDescription.jsonDescription;

  return (
    <Grid container item xs={12}>
      <Box width="100%">
        <Grid item xs={12}>
          {data.insuredEntities.length === 1 && (
            <>
              {data.insuredEntities.map((childItem, index) => (
                <>
                  {!data.insuredEntitiesDescriptions[childItem].singleton ? (
                    <InsuredEntitiesForm
                      name={childItem}
                      onPopup={onPopup}
                      insuredEntity={data.insuredEntitiesDescriptions[childItem]}
                      policyId={policy.id}
                      insuredEntities={insuredEntities}
                      setInsuredEntities={setInsuredEntities}
                      coverProperties={data.coverProperties}
                      formMethods={{}}
                      policyTax={policy.taxPct}
                      policySurcharge={policy.surcharge}
                      policy={policy}
                    />
                  ) : (
                    <InsuredEntitySingleton
                      onPopup={onPopup}
                      insuredEntityName={childItem}
                      productDescription={data.insuredEntitiesDescriptions[childItem]}
                    />
                  )}
                </>
              ))}
            </>
          )}
          {data.insuredEntities.length > 1 && (
            <>
              <AppBar position="static" color="secondary">
                <Tabs
                  value={tab}
                  onChange={handleChange}
                  aria-label="simple tabs"
                  indicatorColor="primary"
                >
                  {data.insuredEntities.map((childItem, index) => (
                    <Tab
                      key={childItem['key']}
                      label={childItem['label']}
                      {...a11yProps({ index })}
                    />
                  ))}
                </Tabs>
              </AppBar>
              {data.insuredEntities.map((childItem, index) => (
                <TabPanel value={tab} key={childItem['key']} index={index}>
                  {!data.insuredEntitiesDescriptions[childItem['key']].singleton ? (
                    <InsuredEntitiesForm
                      name={childItem['key']}
                      onPopup={onPopup}
                      insuredEntity={data.insuredEntitiesDescriptions[childItem['key']]}
                      policyId={policy.id}
                      insuredEntities={insuredEntities}
                      setInsuredEntities={setInsuredEntities}
                      coverProperties={data.coverProperties}
                      formMethods={{}}
                      policyTax={policy.taxPct}
                      policySurcharge={policy.surcharge}
                      policy={policy}
                    />
                  ) : (
                    <InsuredEntitySingleton
                      onPopup={onPopup}
                      insuredEntityName={childItem['key']}
                      productDescription={data.insuredEntitiesDescriptions[childItem['key']]}
                    />
                  )}
                </TabPanel>
              ))}
            </>
          )}
        </Grid>
      </Box>
    </Grid>
  );
};

export default memo(InsuredEntitiesWrapper);

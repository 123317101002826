export const handleKey = (e: any) => {
  if (e.key === 'Enter' && !e.ctrlKey) {
    e.stopPropagation();
  }
};

export const handleSubmitKeyBinding = (e, func) => {
  if (e.key === 'Enter' && e.ctrlKey) {
    func();
  }
};

export const rulesFromDescription = (element) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return {
    required: {
      value: element?.type !== 'boolean' ? element?.validation?.front?.required : false,
      message: 'The field is required',
    },
    min: {
      value: element?.validation?.front?.min,
      message: `The minimum value is ${formatter.format(element?.validation?.front?.min)}`,
    },
    max: {
      value: element?.validation?.front?.max,
      message: `The maximum value is ${formatter.format(element?.validation?.front?.max)}`,
    },
  };
};

import { Grid, Link, LinearProgress, Button } from '@material-ui/core';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { MESSAGE_TYPES } from '../../../api/messageTypes';
import { callWs } from '../../../api/websocket';

import PolicyForm from '../../../shared/components/policy-form/PolicyForm';
import { usePageTitle } from '../../../shared/hooks/use-page-title';

const PolicyEditPage = () => {
  usePageTitle('Edit Policy');

  const history = useHistory();

  const { id } = useParams<{ id: string }>();
  const { data: policy = null, isLoading, refetch: fetchPolicy } = useQuery<any, any>(
    ['policy', id],
    () => callWs(MESSAGE_TYPES.POLICY_SHOW, { id: id }),
    { staleTime: 0 },
  );

  return isLoading ? (
    <LinearProgress />
  ) : (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Link component={RouterLink} to="/dashboard">
          Back to list
        </Link>
      </Grid>
      <Grid item xs>
        <PolicyForm policy={policy} fetchPolicy={fetchPolicy} />
      </Grid>
    </Grid>
  );
};

export default PolicyEditPage;

import { Parser } from 'expr-eval';
import strtotime from '../../utils/strtotime';

class ExpressionLanguage extends Parser {
  constructor() {
    super({
      operators: {
        add: false,
        concatenate: true,
        conditional: true,
        divide: false,
        factorial: false,
        multiply: false,
        power: false,
        remainder: false,
        subtract: false,

        logical: true,
        comparison: true,

        in: true,
        assignment: false,
      },
    });
    this.functions = {
      strtotime: strtotime,
      year: (timestamp) => new Date(timestamp * 1000).getFullYear().toString(),
    };
  }
}

export default ExpressionLanguage;

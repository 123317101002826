import { Grid, Link } from '@material-ui/core';
import { useContext, useLayoutEffect } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import PolicyForm from '../../../shared/components/policy-form/PolicyForm';
import { LayoutContext } from '../../../shared/providers/LayoutProvider';

const PolicyCreatePage = () => {
  const { layoutData, setLayoutData } = useContext(LayoutContext);
  const history = useHistory();

  useLayoutEffect(() => {
    setLayoutData({ ...layoutData, headerTitle: 'Create Policy' });
  }, []);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Link component={RouterLink} to="/dashboard">
          Back to list
        </Link>
      </Grid>
      <Grid item xs>
        <PolicyForm />
      </Grid>
    </Grid>
  );
};

export default PolicyCreatePage;

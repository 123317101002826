import { forwardRef, useEffect, useRef, useContext } from 'react';
import MaterialTable, { MTableAction, Icons } from '@material-table/core';
import { Button } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { format } from 'date-fns';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { MESSAGE_TYPES } from '../../../api/messageTypes';
import { callWs } from '../../../api/websocket';
import * as customToast from '../../../shared/toast/customToast';
import { dateFormater } from '../../../utils/valueFormatter';
import cardContext from '../../../pages/dashboard/CardContext';
const tableIcons: Icons = {
  Filter: forwardRef((props, ref) => <SearchIcon {...props} ref={ref} />),
};

const PolicyList: React.FC<any> = (props) => {
  const { referredOnly = false } = props;
  const history = useHistory();
  const tableRef: any = useRef();

  const policyFilterArg = useContext(cardContext);

  useEffect(() => {
    if (tableRef && tableRef.current) {
      tableRef.current.onQueryChange();
    }
  }, [policyFilterArg]);

  const { mutateAsync: deletePolicy } = useMutation(MESSAGE_TYPES.POLICY_DELETE, (data: any) =>
    callWs(MESSAGE_TYPES.POLICY_DELETE, data),
  );

  const _formatInsuredPersonsToDisplay = (insuredPersons: any) => {
    let formattedDisplay = '';

    if (insuredPersons?.length > 0) {
      const firstInsuredPerson = insuredPersons[0];
      if (firstInsuredPerson.isCompany) {
        formattedDisplay =
          firstInsuredPerson.companyName +
          (firstInsuredPerson.hasCareOfName
            ? ` (${firstInsuredPerson.firstName} ${firstInsuredPerson.lastName})`
            : '');
      } else {
        formattedDisplay = `${firstInsuredPerson.firstName} ${firstInsuredPerson.lastName}`;
      }

      formattedDisplay += insuredPersons.length > 1 ? ', ...' : '';
    }

    return formattedDisplay;
  };

  const columns: any[] = [
    {
      title: 'Product Name',
      field: 'productDescription.product.name',
    },
    {
      title: 'Policy Number',
      field: 'policyNumber',
    },
    // {
    //   title: 'Quote Number',
    //   field: 'quoteNumber',
    // },
    {
      title: 'Insured Persons',
      field: 'insuredPersons',
      render: (rowData) => <span>{_formatInsuredPersonsToDisplay(rowData?.insuredPersons)}</span>,
      sorting: false,
      filtering: false,
    },
    {
      title: 'Agent',
      field: 'agent.name',
    },
    {
      title: 'Insurer',
      field: 'insurer.name',
    },
    {
      title: 'Created At',
      field: 'createdAt',
      render: (rowData) => (
        <span>{format(dateFormater(rowData.createdAt), 'MM-dd-yyyy HH:mm:ss')}</span>
      ),
      defaultSort: 'asc',
    },
    {
      title: 'Inception Date',
      field: 'inceptionDate',
      render: (rowData) => <span>{format(dateFormater(rowData.inceptionDate), 'MM-dd-yyyy')}</span>,
    },
    {
      title: 'Expiration Date',
      field: 'expirationDate',
      render: (rowData) => (
        <span>{format(dateFormater(rowData.expirationDate), 'MM-dd-yyyy')}</span>
      ),
    },
    {
      title: 'Cancellation Date',
      field: 'cancellationDate',
      render: (rowData) => (
        <span>
          {rowData?.cancellation
            ? format(dateFormater(rowData?.cancellation?.cancellationDate), 'MM-dd-yyyy')
            : '-'}
        </span>
      ),
    },
    {
      title: 'Is Referral',
      field: 'refer',
      render: (rowData) => <span>{rowData.refer ? 'Yes' : 'No'}</span>,
    },
    {
      title: 'Status',
      field: 'status',
    },
    {
      title: 'Descriptive Status',
      field: 'descriptiveStatus.name',
      render: (rowData) => <span>{rowData?.descriptiveStatusName}</span>,
    },
  ];
  const _getSortFieldName = (fieldName?: string) => {
    if (!fieldName) return undefined;
    if (fieldName.includes('product')) {
      return 'product.name';
    } else {
      return fieldName;
    }
  };

  return (
    <MaterialTable
      tableRef={tableRef}
      data={(query) =>
        new Promise(async (resolve, reject) => {
          const response: any = await callWs(MESSAGE_TYPES.POLICIES_SEARCH, {
            paginate: {
              page: query.page + 1,
              perPage: query.pageSize,
            },
            filter: {
              ...query.filters
                .map((filter: any) => {
                  return {
                    [filter.column.field]: filter.value,
                  };
                })
                .reduce((acc, next) => Object.assign(acc, next), {}),
              [policyFilterArg]: true,
              searchBucket: query.search,
            },
            include: [
              'agent',
              'insurer',
              'cancellation',
              'insuredPersons',
              'descriptiveStatus',
              'productDescription',
              'productDescription.product',
            ].join(','),
            sort: query.orderBy
              ? `${query.orderDirection === 'asc' ? '-' : ''}${_getSortFieldName(
                  query.orderBy.field?.toString(),
                )}`
              : undefined,
          });
          resolve({
            data: response?.data ?? [],
            page: response?.currentPage - 1 ?? 0,
            totalCount: response?.total ?? 0,
          });
        })
      }
      columns={columns}
      components={{
        Action: (props) => {
          const action = props.action?.action ? props.action.action(props.data) : props.action;

          if (action.icon === 'add') {
            return (
              <Button
                onClick={(event) => action.onClick(event)}
                color="primary"
                variant="contained"
                style={{ textTransform: 'none' }}
                size="small"
              >
                Create Policy
              </Button>
            );
          } else {
            return <MTableAction {...props} />;
          }
        },
      }}
      actions={[
        {
          isFreeAction: true,
          icon: 'add',
          tooltip: 'Create Policy',
          onClick: () => {
            history.push('/policy/create');
          },
        },
        (rowData: any) => ({
          icon: 'edit',
          tooltip: 'Edit Policy',
          onClick: () => {
            history.push(`/policy/edit/${rowData.id}`);
          },
          // TODO: remove me at some later time
          // hidden: rowData.status !== 'CREATED',
        }),
      ]}
      editable={{
        isEditHidden: () => true,
        isDeleteHidden: (rowData) => rowData.status !== 'CREATED',
        onRowDelete: (data: any) => {
          return new Promise<void>(async (resolve, reject) => {
            try {
              await deletePolicy({ id: data.id });
              customToast.success('Policy deleted successfully');
              resolve();
            } catch (err) {
              customToast.error(err.messageCode);
              reject();
            }
          });
        },
      }}
      icons={tableIcons}
      options={{
        search: true,
        paging: true,
        filtering: true,
        draggable: false,
        sorting: true,
        showTitle: false,
        pageSize: 10,
        pageSizeOptions: [10],
        rowStyle: { fontFamily: 'Roboto', fontSize: 14 },
        debounceInterval: 800,
        actionsColumnIndex: -1,
      }}
    />
  );
};

export default PolicyList;

import { Checkbox, FormControlLabel, Switch } from '@material-ui/core';
import { InputWrapperProps } from '../InputWrapper';

export interface BooleanInputProps extends InputWrapperProps {
  value: boolean | undefined;
  onChange: (value: boolean) => void;
}

const BooleanInput = (props) => {
  const {
    properties,
    displayMode,
    value,
    onChange,
    afterOnChange,
    onKeyDown,
    evaluateRules,
    displayLabel,
    disabled,
  } = props;

  const _handleKey = (e: any) => {
    if (e.key === 'Enter' && !e.ctrlKey) {
      e.stopPropagation();
      onChange(!value);
      afterOnChange?.();

      if (evaluateRules) {
        evaluateRules(!value);
      }
    }
  };

  const _onKeyDown = (e: any) => {
    if (e.key === 'Enter' && !e.ctrlKey) {
      e.stopPropagation();
      onChange(!value);
      afterOnChange?.();

      if (evaluateRules) {
        evaluateRules(!value);
      }
    }
    onKeyDown(e);
  };

  const ControlComponent = properties.presentation === 'checkbox' ? Checkbox : Switch;

  return (
    <FormControlLabel
      control={
        <ControlComponent
          disabled={disabled || displayMode === 'read'}
          color="primary"
          checked={value || false}
          onChange={() => {
            onChange(!value);
            if (evaluateRules) {
              evaluateRules(!value);
            }
            afterOnChange?.();
          }}
          onKeyDown={(e) => (onKeyDown ? _onKeyDown(e) : _handleKey(e))}
        />
      }
      label={displayLabel ? properties.label : undefined}
    />
  );
};

export default BooleanInput;

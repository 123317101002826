import { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { KeyboardDatePicker } from '@material-ui/pickers';
import {
  Grid,
  Button,
  CircularProgress,
  Dialog,
  makeStyles,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
} from '@material-ui/core';
import MaterialTable, { MTableAction } from '@material-table/core';
import { Controller, useForm } from 'react-hook-form';
import { validateInput } from '../../../../../utils/validationUtils';
import InputWrapper from '../../../inputs/InputWrapper';
import { rulesFromDescription } from '../../../../../utils/formUtils';
import { useMutation } from 'react-query';
import { format } from 'date-fns';
import BooleanQuestion from '../../../questions/boolean-question';
import { MESSAGE_TYPES } from '../../../../../api/messageTypes';
import { callWs } from '../../../../../api/websocket';
import { dateFormater } from '../../../../../utils/valueFormatter';
import * as customToast from '../../../../toast/customToast';
import { useConfirm } from 'material-ui-confirm';
import { Clear as ClearIcon, Check as CheckIcon } from '@material-ui/icons';

const inputDescriptions = [
  {
    name: 'mtaNumber',
    label: 'MTA Number',
    type: 'text',
    presentation: 'text',
    validation: {
      front: { required: false },
    },
  },
  {
    name: 'effectiveDate',
    label: 'Effective Date',
    type: 'date',
    presentation: 'text',
    validation: {
      front: { required: false },
    },
  },
  {
    name: 'previousTotal',
    label: 'Previous Total',
    type: 'text',
    presentation: 'text',
    validation: {
      front: { required: false },
    },
  },
  {
    name: 'difference',
    label: 'Difference',
    type: 'text',
    presentation: 'text',
    validation: {
      front: { required: false },
    },
  },
  {
    name: 'currentTotal',
    label: 'Current Total',
    type: 'text',
    presentation: 'text',
    validation: {
      front: { required: false },
    },
  },

  {
    name: 'status',
    label: 'MTA Status',
    type: 'text',
    presentation: 'text',
    validation: {
      front: { required: false },
    },
  },
];
const MidtermAdjustments = (props) => {
  const history = useHistory();
  const confirm = useConfirm();
  const [open, setOpen] = useState(false);
  const [selectedRowDate, setSelectedRowDate] = useState('2021-11-02');
  const { policy, fetchPolicy } = props;
  const [mtas, setMtas] = useState<any[]>([]);
  const [isMtasLoading, setIsMtasLoading] = useState(false);
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0');
  var yyyy = today.getFullYear();
  var today2 = yyyy + '/' + mm + '/' + dd;
  const initialize = {
    effectiveDate: today2,
    emailDocAgent: false,
    emailDocMga: false,
    emailDocument: false,
    id: policy.id,
    mergeInvoices: false,
    postDocument: false,
  };
  const [selectedRow, setSelectedRow] = useState(initialize);
  const [canMerge, setCanMerge] = useState(true);
  useEffect(() => {}, []);
  const handleClose = () => {
    setOpen(false);
  };
  const { control, watch, getValues, reset, handleSubmit, trigger, errors } = useForm<any>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      ...inputDescriptions
        .map((inputDescription) => ({
          [inputDescription.name]: inputDescription.type === 'boolean' ? false : null,
        }))
        .reduce((acc, next) => Object.assign(acc, next), {}),
    },
  });

  const {
    mutateAsync: getMtas,
    isLoading: isMtaLoading,
  } = useMutation(MESSAGE_TYPES.MTA_LIST_PER_POLICY, (data: any) =>
    callWs(MESSAGE_TYPES.MTA_LIST_PER_POLICY, data),
  );

  const { mutateAsync: voidMtas, isLoading: isVoidMtaLoading } = useMutation(
    MESSAGE_TYPES.MTA_VOID,
    (data: any) => callWs(MESSAGE_TYPES.MTA_VOID, data),
  );
  const {
    mutateAsync: getDocument,
    isLoading: isDocumentLoading,
  } = useMutation(MESSAGE_TYPES.DOCUMENT_GET, (path: any) =>
    callWs(MESSAGE_TYPES.DOCUMENT_GET, { path }),
  );
  const _fetchDocument = async (path) => {
    try {
      const urlResponse: any = await getDocument(path);
      window.open(urlResponse, '_blank');
    } catch (e) {}
  };
  const {
    mutateAsync: createMtas,
    isLoading: isMtaCreateLoading,
  } = useMutation(MESSAGE_TYPES.MTA_CREATE, (data: any) => callWs(MESSAGE_TYPES.MTA_CREATE, data));
  const {
    mutateAsync: deleteMtas,
    isLoading: isMtaDeleteLoading,
  } = useMutation(MESSAGE_TYPES.MTA_DELETE, (data: any) => callWs(MESSAGE_TYPES.MTA_DELETE, data));
  const {
    mutateAsync: updateMtas,
    isLoading: isMtaUpdateLoading,
  } = useMutation(MESSAGE_TYPES.MTA_UPDATE, (data: any) => callWs(MESSAGE_TYPES.MTA_UPDATE, data));

  const _handleDelete = async (data) => {
    try {
      const mtas: any = await deleteMtas(data);
      fetchPolicy();
    } catch (err) {
      customToast.error(err.messageCode, {
        toastId: `delete-mta-failed`,
      });
    }
  };

  const _handleVoid = async (data) => {
    try {
      const mtaVoidResponse: any = await voidMtas(data);
      history.push('/policy/edit/' + mtaVoidResponse);
      fetchPolicy();
    } catch (err) {
      customToast.error(err.messageCode, {
        toastId: `void-mta-failed`,
      });
    }
  };

  const _handleInsert = async (data) => {
    try {
      const newlyCreatedRow: any = await createMtas({ policyId: policy.id, ...data });
      fetchPolicy();
      _clearInputRow();
    } catch (error) {
      customToast.error(error.messageCode, {
        toastId: `create-policy-address-failed`,
      });
    }
  };
  const _handleUpdate = async (data, id) => {
    const newRow = {
      effectiveDate: format(data.effectiveDate, 'yyyy-MM-dd'),
      emailDocAgent: data.emailDocAgent,
      emailDocMga: data.emailDocMga,
      emailDocument: data.emailDocument,
      id: selectedRow.id,
      mergeInvoices: data.mergeInvoices,
      postDocument: data.postDocument,
    };
    const newlyUpdatedRow: any = await updateMtas(newRow);
    fetchPolicy();
  };
  useEffect(() => {
    setMtas(policy?.mtas);
  }, [policy]);

  const _clearInputRow = () => {
    reset();
  };

  const goToMtaPolicy = (id) => {
    history.push('/policy/edit/' + id);
  };
  const columns: any[] = [
    { title: '', field: 'id', hidden: true },

    ...inputDescriptions.map((element) =>
      element.label !== 'Effective Date'
        ? {
            title: element.label + (element?.validation?.front?.required ? ' *' : ''),
            field: element.name,
            validate: (rowData) => validateInput(rowData, element),
            editComponent: ({ value, onChange, error, helperText }) => {
              return (
                <InputWrapper
                  properties={element}
                  value={value}
                  onChange={onChange}
                  error={error}
                  displayLabel={false}
                  helperText={helperText}
                />
              );
            },
            render: (rowData) => {
              if (rowData.id) {
                return (
                  <InputWrapper
                    properties={element}
                    value={rowData[element.name]}
                    onChange={() => {}}
                    displayMode="read"
                    displayLabel={false}
                  />
                );
              } else {
                return (
                  <Controller
                    render={({ onChange, value }) => (
                      <InputWrapper
                        properties={element}
                        displayLabel={false}
                        error={!!errors[element.name]?.message}
                        helperText={errors[element.name]?.message}
                        value={value}
                        onChange={(value) => {
                          onChange(value);
                          trigger(element.name);
                        }}
                      />
                    )}
                    control={control}
                    name={element.name}
                    rules={rulesFromDescription(element)}
                  />
                );
              }
            },
          }
        : {
            title: 'Effective Date',
            field: 'effectiveDate',
            type: 'date',
            render: (rowData) => format(dateFormater(rowData.effectiveDate), 'MM-dd-yyyy'),
          },
    ),
  ];

  const DialogWarning = () => {
    return (
      <>
        <Dialog open={true} onClose={handleClose}>
          <DialogContent>Hi</DialogContent>
        </Dialog>
      </>
    );
  };
  const DialogEdit = () => {
    return (
      <>
        <Dialog open={open} onClose={handleClose}>
          <DialogContent>
            <Grid container item spacing={4}>
              <Grid item xs={6} md={4} style={{ display: 'flex', justifyContent: 'center' }}>
                <Controller
                  render={({ onChange, value }) => (
                    <BooleanQuestion
                      questionName="emailDocument"
                      questionLabel="Receiving Invoices by email?"
                      labelPlacement="top"
                      onChange={(data) => {
                        onChange(data);
                      }}
                      value={value}
                    />
                  )}
                  control={control}
                  defaultValue={selectedRow.emailDocument}
                  name="emailDocument"
                />
              </Grid>
              <Grid item xs={6} md={4} style={{ display: 'flex', justifyContent: 'center' }}>
                <Controller
                  render={({ onChange, value }) => (
                    <BooleanQuestion
                      questionName="mergeInvoices"
                      questionLabel="Merge Invoices?"
                      labelPlacement="top"
                      onChange={(data) => {
                        onChange(data);
                      }}
                      value={value}
                    />
                  )}
                  defaultValue={selectedRow.mergeInvoices}
                  control={control}
                  name="mergeInvoices"
                />
              </Grid>
              <Grid item xs={6} md={4} style={{ display: 'flex', justifyContent: 'center' }}>
                <Controller
                  render={({ onChange, value }) => (
                    <BooleanQuestion
                      questionName="postDocument"
                      questionLabel="Receiving Invoices by post mail?"
                      labelPlacement="top"
                      onChange={(data) => {
                        onChange(data);
                      }}
                      value={value}
                    />
                  )}
                  defaultValue={selectedRow.postDocument}
                  control={control}
                  name="postDocument"
                />
              </Grid>
              <Grid item xs={6} md={4} style={{ display: 'flex', justifyContent: 'center' }}>
                <Controller
                  render={({ onChange, value }) => (
                    <BooleanQuestion
                      questionName="emailDocMga"
                      questionLabel="Email MGA?"
                      labelPlacement="top"
                      onChange={(data) => {
                        onChange(data);
                      }}
                      value={value}
                    />
                  )}
                  defaultValue={selectedRow.emailDocMga}
                  control={control}
                  name="emailDocMga"
                />
              </Grid>
              <Grid item xs={6} md={4} style={{ display: 'flex', justifyContent: 'center' }}>
                <Controller
                  render={({ onChange, value }) => (
                    <BooleanQuestion
                      questionName="emailDocAgent"
                      questionLabel="Email Agent?"
                      labelPlacement="top"
                      onChange={(data) => {
                        onChange(data);
                      }}
                      value={value}
                    />
                  )}
                  defaultValue={selectedRow.emailDocAgent}
                  control={control}
                  name="emailDocAgent"
                />
              </Grid>{' '}
              <Grid item xs={6} md={4} style={{ display: 'flex', justifyContent: 'center' }}>
                <Controller
                  render={({ onChange, value }) => (
                    <KeyboardDatePicker
                      required
                      margin="dense"
                      label={'Effective Date'}
                      format="MM/dd/yyyy"
                      name="effectiveDate"
                      autoOk
                      onChange={(data) => {
                        onChange(data);
                      }}
                      value={value || new Date(selectedRow?.effectiveDate)}
                    />
                  )}
                  name={'effectiveDate'}
                  control={control}
                />
              </Grid>
              <Grid item xs={6} md={12} style={{ display: 'flex', justifyContent: 'center' }}>
                <Grid item>
                  <Button
                    color="primary"
                    fullWidth
                    variant="contained"
                    onClick={() => {
                      const updatedValues = getValues();
                      _handleUpdate(updatedValues, selectedRow.id);
                      setOpen(false);
                    }}
                  >
                    {isMtaLoading ? <CircularProgress color="secondary" size={24} /> : 'Submit'}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  return (
    <>
      <Grid container spacing={2} style={{ margin: 0 }}>
        <MaterialTable
          style={{ width: '100%' }}
          columns={columns}
          data={mtas}
          title="MTAS"
          editable={{
            isDeleteHidden: (rowData) => !rowData.id,
            isEditHidden: (rowData) => !rowData.id,
          }}
          options={{
            search: false,
            paging: false,
            filtering: false,
            addRowPosition: 'first',
            loadingType: 'linear',
            draggable: false,
            showTitle: false,
            actionsColumnIndex: -1,
            rowStyle: (rowData) => ({
              fontFamily: 'Roboto',
              fontSize: 14,
            }),
          }}
          components={{
            Action: (props) => {
              const action = props.action?.action ? props.action.action(props.data) : props.action;
              if (action.hidden) return <></>;
              if (action.icon === 'void') {
                return (
                  <Grid md={6}>
                    <Button
                      onClick={(event) => action.onClick(event, props.data)}
                      color="primary"
                      variant="contained"
                      style={{ textTransform: 'none', marginLeft: 3, marginTop: 5 }}
                    >
                      Void
                    </Button>
                  </Grid>
                );
              } else {
                return <MTableAction {...props} />;
              }
            },
          }}
          actions={[
            (rowData) => ({
              icon: CheckIcon,
              disabled: false,
              tooltip: 'Save',
              onClick: handleSubmit(async (data) => {
                try {
                  await _handleInsert(data);
                } catch (err) {
                  console.log(err);
                } finally {
                }
              }),
              hidden: rowData.id,
            }),
            (rowData) => ({
              icon: 'preview',
              tooltip: 'Preview Document',
              hidden: policy.status === 'BOUND' && rowData.status === 'Open',
              onClick: (event, rowData) => {
                _fetchDocument(rowData.filePath);
              },
            }),
            (rowData) => ({
              icon: ClearIcon,
              tooltip: 'Clear',
              onClick: () => {
                _clearInputRow();
              },
              hidden: rowData.id,
            }),
            (rowData) => ({
              icon: 'edit',
              tooltip: 'edit',
              hidden: rowData.status === 'Void',
              onClick: (event, rowData) => {
                const row = {
                  effectiveDate: rowData.effectiveDate,
                  emailDocAgent: rowData.emailDocAgent,
                  emailDocMga: rowData.emailDocMga,
                  emailDocument: rowData.emailDocument,
                  id: rowData.id,
                  mergeInvoices: rowData.mergeInvoices,
                  postDocument: rowData.postDocument,
                };
                setSelectedRow(row);
                setSelectedRowDate(rowData.effectiveDate);
                setOpen(true);
              },
            }),
            (rowData) => ({
              icon: 'preview',
              tooltip: 'preview Policy',
              hidden:
                (policy.status === 'BOUND' && rowData.status === 'In_effect') ||
                rowData.status === 'Void',
              onClick: (event, rowData) => {
                goToMtaPolicy(rowData.policyId);
              },
            }),
            (rowData) => ({
              icon: 'delete',
              tooltip: 'delete',
              hidden: rowData.status === 'Void',
              onClick: async (event, rowData) => {
                try {
                  await _handleDelete({ id: rowData.id, position: rowData.position });
                } catch (err) {}
              },
            }),
            (rowData) => ({
              icon: 'void',
              tooltip: 'void',
              hidden: rowData.status === 'Open' || rowData.status === 'Void',
              onClick: async (event, rowData) => {
                try {
                  await _handleVoid({ id: rowData.id });
                } catch (err) {}
              },
            }),
          ]}
        />
        <DialogEdit></DialogEdit>
      </Grid>
    </>
  );
};
export default memo(MidtermAdjustments);

export enum MESSAGE_TYPES {
  AUTHENTICATE_USER = 'authenticateUser',
  INSURED_ENTITY_CREATE = 'insuredEntityCreate',
  INSURED_ENTITY_DUPLICATE = 'insuredEntityDuplicate',
  INSURED_ENTITY_UPDATE = 'insuredEntityUpdate',
  INSURED_ENTITY_DELETE = 'insuredEntityDelete',
  INSURED_ENTITY_COVER_CREATE = 'insuredEntityCoverCreate',
  INSURED_ENTITY_COVER_UPDATE = 'insuredEntityCoverUpdate',
  INSURED_ENTITY_COVER_DELETE = 'insuredEntityCoverDelete',

  POLICY_CREATE = 'policyCreate',
  POLICY_UPDATE = 'policyUpdate',
  POLICY_EXTRA_FIELDS_UPDATE = 'policyExtraFieldsUpdate',
  POLICY_SHOW = 'policyShow',
  POLICY_DELETE = 'policyDelete',
  POLICY_BIND = 'policyBind',
  GENERATE_PAYMENT_PLAN = 'generatePaymentPlan',
  POLICIES_SEARCH = 'policiesSearch',
  POLICY_DASHBOARD_STATS = 'policyDashboardStats',
  POLICY_ADDRESS_CREATE = 'policyAddressCreate',
  POLICY_ADDRESS_UPDATE = 'policyAddressUpdate',
  POLICY_ADDRESS_DELETE = 'policyAddressDelete',
  POLICY_UPLOAD_DOCUMENT = 'documentUpload',
  POLICY_DO_NOT_RENEW = 'policyDoNotRenew',
  POLICY_ALLOW_RENEWAL = 'policyAllowRenewal',

  POLICY_NON_PAYMENT_PROCESS = 'policyNonPaymentProcess',
  POLICY_CANCELLATION_DROPDOWN_OPTIONS = 'policyCancellationGetDropdownOptions',
  POLICY_CANCELLATION = 'policyCancellation',
  POLICY_CANCELLATION_DOCUMENT_SENT = 'policyCancellationDocumentSent',
  POLICY_NOR_CANCELLATION_DOCUMENT_SENT = 'policyCancellationDocumentSent',
  POLICY_CANCELLATION_REINSTATE = 'policyCancellationReinstate',

  POLICY_INVOICE_VOID = 'voidInvoice',
  POLICY_INVOICE_UNVOID = 'unVoidInvoice',

  PAYMENT_CREATE = 'paymentCreate',
  PAYMENTS_LIST = 'paymentsList',
  PAYMENTS_SEARCH = 'paymentsSearch',
  PAYMENT_DISTRIBUTION_CREATE = 'moneyDistributionCreate',
  PAYMENT_DISTRIBUTION_LIST = 'moneyDistributionGetByPayment',

  PAYMENTS_VOID = 'paymentVoid',

  DOCUMENT_GET = 'documentGet',
  DOCUMENT_DELETE = 'documentDelete',
  DOCUMENT_SEND_BY_EMAIL = 'documentEmail',
  RENEW = 'policyRenew',
  PRODUCTS_LIST = 'productList',
  AGENTS_LIST = 'agentList',
  AGENTS_PRODUCTS_DESCRIPTIONS = 'agentProductDescriptions',
  ADJUSTERS_LIST = 'adjusterList',
  INSURERS_LIST = 'insurerList',
  REINSURERS_LIST = 'reinsurerList',
  QUESTION_ANSWERS_UPDATE = 'questionAnswersUpdate',
  PERSON_CREATE = 'personCreate',
  PERSON_UPDATE = 'personUpdate',
  PERSONS_SEARCH = 'personsSearch',
  ADD_INSURED_PERSON = 'addInsuredPerson',
  DELETE_INSURED_PERSON = 'deleteInsuredPerson',
  ADD_LOSS_PAYEE = 'addLossPayee',
  DELETE_LOSS_PAYEE = 'deleteLossPayee',
  POST_MAIL_OVERVIEW = 'getPostMailOverview',
  MARK_ALL_INVOICES_AS_SENT = 'markAllInvoicesAsSent',
  MTA_LIST = 'mtaList',
  MTA_CREATE = 'mtaCreate',
  MTA_UPDATE = 'mtaUpdate',
  MTA_DELETE = 'mtaDelete',
  MTA_LIST_PER_POLICY = 'mtaListPerPolicy',
  MTA_VOID = 'mtaVoid',
  MTA_ACTIVATE = 'activateMta',
  FETCH_AMOUNTS = 'getAmounts',
  MTA_INVOICE_ANALYSIS = 'mtaInvoiceAnalysis',
}

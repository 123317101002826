import { forwardRef, useEffect, useState, useReducer, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';

import MaterialTable, { MTableBodyRow } from '@material-table/core';
import {
  ChevronRight as ChevronRightIcon,
  Clear as ClearIcon,
  Check as CheckIcon,
  Search as SearchIcon,
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons';
import {
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { Close as CloseIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import { enabledRules, validateInput } from '../../../../utils/validationUtils';
import InputWrapper from '../../inputs/InputWrapper';
import { USState } from '../../../enums/USState';
import ClientsSearch from './search/ClientsSearch';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'fixed',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  container: {
    padding: '5rem 2rem',
  },
}));

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const inputDescriptions = [
  {
    name: 'isCompany',
    label: 'Is company?',
    type: 'boolean',
    presentation: 'switch',
  },
  {
    name: 'companyName',
    label: 'Company Name',
    type: 'text',
    presentation: 'text',
    required: true,
    rules: {
      enable: {
        isCompany: true,
      },
    },
  },
  {
    name: 'hasCareOfName',
    label: 'Has care of name?',
    type: 'boolean',
    presentation: 'switch',
    rules: {
      enable: {
        isCompany: true,
      },
    },
  },

  {
    name: 'firstName',
    label: 'First Name',
    type: 'text',
    presentation: 'text',
    required: true,
    rules: {
      enable: {
        customClientDialogRule: true,
      },
    },
  },
  {
    name: 'lastName',
    label: 'Last Name',
    type: 'text',
    presentation: 'text',
    required: true,
    rules: {
      enable: {
        customClientDialogRule: true,
      },
    },
  },
  {
    name: 'primaryPhone',
    label: 'Primary Phone',
    type: 'text',
    presentation: 'text',
    required: true,
  },
  {
    name: 'contactEmail',
    label: 'Contact E-mail',
    type: 'text',
    presentation: 'email',
    required: true,
  },
  {
    name: 'payersEmail',
    label: "Payer's E-mail",
    type: 'text',
    presentation: 'email',
    required: false,
  },
  {
    name: 'country',
    label: 'Country',
    type: 'enum',
    presentation: 'autocomplete',
    required: true,
    isDisabled: true,
    defaultValue: 'US',
    values: ['US', 'GR'],
  },
  {
    name: 'addressLine1',
    label: 'Address 1',
    type: 'text',
    presentation: 'text',
    required: true,
  },
  {
    name: 'addressLine2',
    label: 'Address 2',
    type: 'text',
    presentation: 'text',
    required: false,
  },
  {
    name: 'city',
    label: 'Town/City',
    type: 'text',
    presentation: 'text',
    required: true,
  },
  {
    name: 'state',
    label: 'State',
    type: 'enum',
    presentation: 'autocomplete',
    required: true,
    values: Object.keys(USState),
  },
  {
    name: 'zip',
    label: 'Zip Code',
    type: 'text',
    presentation: 'text',
    required: true,
  },
];

const ClientsDialog = (props) => {
  const {
    title = 'Clients',
    savedClients = [],
    handleInsert,
    handleAdd,
    handleUpdate,
    handleRemove,
    open,
    handleClose,
  } = props;

  const classes = useStyles();
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);
  const [isClientSaveLoading, setIsClientSaveLoading] = useState(false);

  const { control, watch, getValues, reset, handleSubmit, trigger, errors } = useForm<any>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      ...inputDescriptions
        .map((inputDescription) => ({
          [inputDescription.name]: inputDescription.defaultValue ?? null,
        }))
        .reduce((acc, next) => Object.assign(acc, next), {}),
    },
  });

  const [filterRows, setFilterRows] = useState<any>({});
  const [rows, setRows] = useState([...savedClients]);
  const tableRef = useRef<any>();

  const _clearInputRow = () => {
    setFilterRows({});
    reset();
  };

  useEffect(() => {
    const addRow = rows.find((row) => !row.id);
    _clearInputRow();

    setRows([addRow, ...savedClients]);
  }, [savedClients]);

  useEffect(() => {
    if (rows.filter((client) => !client.id).length === 0) {
      const inputRow = { tableData: { id: 0 } };
      inputDescriptions.forEach((element) => {
        inputRow[element.name] = element.defaultValue ?? null;
      });

      setRows([inputRow, ...rows]);
    }
  }, []);

  watch(['isCompany', 'hasCareOfName']);

  const columns: any[] = inputDescriptions.map((element) => ({
    title: element.label + (element?.required ? ' *' : ''),

    field: element.name,
    validate: (rowData) => validateInput(rowData, element),
    editComponent: ({ rowData, value, onChange, error, helperText }) => {
      return (
        enabledRules(rowData, element?.rules?.enable) && (
          <InputWrapper
            properties={element}
            value={value}
            onChange={onChange}
            disabled={element.isDisabled}
            error={error}
            displayLabel={false}
            helperText={helperText}
          />
        )
      );
    },
    render: (rowData) => {
      if (rowData.id) {
        return (
          <InputWrapper
            properties={element}
            value={rowData[element.name]}
            onChange={() => {}}
            displayMode="read"
            displayLabel={false}
          />
        );
      } else {
        return (
          enabledRules(getValues(), element?.rules?.enable) && (
            <Controller
              render={({ onChange, value }) => (
                <InputWrapper
                  properties={element}
                  displayLabel={false}
                  error={!!errors[element.name]?.message}
                  helperText={errors[element.name]?.message}
                  value={value}
                  disabled={element.isDisabled}
                  onChange={(value) => {
                    onChange(value);
                    trigger(element.name);
                  }}
                />
              )}
              control={control}
              name={element.name}
              rules={{
                required: { value: element?.required ?? false, message: 'Field is required' },
              }}
              defaultValue={element?.defaultValue ?? null}
            />
          )
        );
      }
    },
  }));

  const _filter = () => {
    const filters = {};
    const searchRow = getValues();
    Object.keys(searchRow).map((key: string) => {
      if (searchRow[key] !== null && searchRow[key] !== undefined && searchRow[key] !== '') {
        let filterValue;
        switch (typeof searchRow[key]) {
          case 'number':
            filterValue = !isNaN(searchRow[key]) ? searchRow[key]?.toString() : undefined;
            break;
          case 'object':
            filterValue = searchRow[key]?.join(',');
            break;
          default:
            filterValue = searchRow[key]?.toString();
        }

        filters[key] = filterValue;
      }
    });
    setFilterRows(filters);
    setIsSearchExpanded(true);
  };

  return (
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar variant="dense">
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.container}>
        <MaterialTable
          style={{ width: '100%' }}
          columns={columns}
          data={rows}
          title="Clients"
          tableRef={tableRef}
          editable={{
            isEditHidden: (rowData) => !rowData.id,
            isDeleteHidden: (rowData) => !rowData.id,
            onRowUpdate: (newData, oldData) =>
              new Promise<void>(async (resolve, reject) => {
                const updatedData = { ...oldData, ...newData };

                await handleUpdate(oldData.id, updatedData);

                resolve();
              }),
            onRowDelete: (oldData: any) =>
              new Promise<void>(async (resolve, reject) => {
                await handleRemove(oldData.id);
                resolve();
              }),
          }}
          actions={[
            (rowData) => ({
              icon: SearchIcon,
              tooltip: 'Search',
              onClick: (event, rowData) => {
                _filter();
                // todo: Search functionality
              },
              hidden: rowData.id,
            }),
            (rowData) => ({
              icon: CheckIcon,
              tooltip: 'Save',
              onClick: handleSubmit(async (data) => {
                setFilterRows({});

                try {
                  setIsClientSaveLoading(true);
                  await handleInsert(data);
                  _clearInputRow();
                } catch (err) {
                  console.log(err);
                } finally {
                  setIsClientSaveLoading(false);
                }
              }),
              hidden: rowData.id || isClientSaveLoading,
            }),
            (rowData) => ({
              icon: ClearIcon,
              tooltip: 'Clear',
              onClick: () => {
                _clearInputRow();
              },
              hidden: rowData.id,
            }),
          ]}
          options={{
            search: false,
            paging: true,
            pageSize: 5,
            paginationType: 'stepped',
            filtering: false,
            loadingType: 'overlay',
            draggable: false,
            showTitle: false,
            rowStyle: { fontFamily: 'Roboto', fontSize: 14 },
            actionsColumnIndex: -1,
            sorting: false,
          }}
        />

        <Accordion
          expanded={isSearchExpanded}
          onChange={(_, isExpanded) => setIsSearchExpanded(isExpanded)}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="search-clients">
            <Typography>Search existing clients</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ClientsSearch
              filterRows={filterRows}
              setFilterRows={setFilterRows}
              handleAdd={handleAdd}
              existedIds={savedClients.map((client) => client.id)}
            />
          </AccordionDetails>
        </Accordion>
      </div>
    </Dialog>
  );
};

export default ClientsDialog;

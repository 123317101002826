import { Close as CloseIcon } from '@material-ui/icons';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Grid,
  makeStyles,
  TextField,
  Button,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Autocomplete } from '@material-ui/lab';
import { useForm, Controller } from 'react-hook-form';
import { format } from 'date-fns';
import { useEffect } from 'react';
import CurrencyFormat from 'react-number-format';

import { USState } from '../../../../../enums/USState';
import InvoiceLinesTable from './invoice-lines-table/InvoiceLinesTable';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'fixed',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  bold: { fontWeight: 600 },
  paper: { minWidth: 1080 },
}));

const InvoiceFormModal = (props) => {
  const {
    invoice,
    title = 'Invoice #' + (invoice?.invoiceNumber ?? ''),
    open,
    handleClose,
  } = props;

  const { control, errors, reset } = useForm({
    defaultValues: {
      name: '',
      city: '',
      state: '',
      zip: '',
      country: '',
      addressLine1: '',
      addressLine2: '',
      phone: '',
      email: '',
      installment: '',
      issueDate: new Date(),
      dueDate: new Date(),
      billType: '',
    },
  });

  const classes = useStyles();

  useEffect(() => {
    reset({
      name: invoice?.name ?? '',
      city: invoice?.city ?? '',
      state: invoice?.state ?? '',
      zip: invoice?.zip ?? '',
      country: invoice?.country ?? '',
      addressLine1: invoice?.addressLine1 ?? '',
      addressLine2: invoice?.addressLine2 ?? '',
      phone: invoice?.phone ?? '',
      email: invoice?.email ?? '',
      issueDate: invoice?.issueDate ?? new Date(),
      dueDate: invoice?.dueDate ?? new Date(),
      billType: invoice?.billType ?? 'direct',
      installment: invoice?.installment ?? '',
    });
  }, [invoice]);

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={open}
      onClose={handleClose}
      disableBackdropClick
    >
      <DialogTitle>
        <Grid container>
          <Grid item>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item justify="center">
            <Typography variant="h6" className={classes.title}>
              {title}
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid container item spacing={2}>
            <Grid item xs>
              <Typography variant="h5">Policy Details</Typography>
            </Grid>
            <Grid container item direction="row">
              <Grid container item xs={12} md direction="column">
                <Grid item>
                  <Typography className={classes.bold}>Policy NO</Typography>
                </Grid>
                <Grid item>
                  <Typography>{invoice?.policy?.policyNumber ?? '-'}</Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12} md direction="column">
                <Grid item>
                  <Typography className={classes.bold}>Type</Typography>
                </Grid>
                <Grid item>
                  <Typography>{invoice?.billType ?? '-'}</Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12} md direction="column">
                <Grid item>
                  <Typography className={classes.bold}>Inception Date</Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    {invoice?.policy?.inceptionDate
                      ? format(new Date(invoice.policy.inceptionDate), 'MM-dd-yyyy')
                      : '-'}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item direction="row">
              <Grid container item xs={12} md direction="column">
                <Grid item>
                  <Typography className={classes.bold}>Recurring Payments</Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    {invoice?.policy?.recurringPayments ? 'Yes' : 'No' ?? '-'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12} md direction="column">
                <Grid item>
                  <Typography className={classes.bold}>Email</Typography>
                </Grid>
                <Grid item>
                  <Typography>{invoice?.policy?.invoicesByEmail ? 'Yes' : 'No' ?? '-'}</Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12} md direction="column">
                <Grid item>
                  <Typography className={classes.bold}>Mail</Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    <Typography>{invoice?.policy?.invoicesByPost ? 'Yes' : 'No' ?? '-'}</Typography>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item direction="row">
              <Grid container item xs={12} md direction="column">
                <Grid item>
                  <Typography className={classes.bold}>Insured Details</Typography>
                </Grid>
                <Grid item>
                  <Typography>{invoice?.name ?? '-'}</Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12} md direction="column">
                <Grid item>
                  <Typography className={classes.bold}>Agent Details</Typography>
                </Grid>
                <Grid item>
                  <Typography>{invoice?.policy?.agent?.name ?? '-'}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item style={{ marginTop: '1rem' }} spacing={2}>
            <Grid item xs>
              <Typography variant="h5">Invoice Details</Typography>
            </Grid>
            <Grid container item direction="row" spacing={2}>
              <Grid item xs={12} md>
                <Controller
                  render={({ onChange, value }) => (
                    <TextField
                      required
                      fullWidth
                      error={errors.billType !== undefined}
                      helperText={errors.billType?.message}
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="Bill Type"
                      value={value}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                    />
                  )}
                  control={control}
                  name="billType"
                  rules={{
                    required: {
                      value: true,
                      message: 'Bill type is required',
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} md>
                <Controller
                  render={({ onChange, value }) => (
                    <TextField
                      fullWidth
                      error={errors.installment !== undefined}
                      helperText={errors.installment?.message}
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="Installment"
                      value={value}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                    />
                  )}
                  control={control}
                  name="installment"
                />
              </Grid>
            </Grid>
            <Grid container item direction="row" spacing={2}>
              <Grid item xs={12} md>
                <Controller
                  render={({ onChange, value }) => (
                    <KeyboardDatePicker
                      required
                      fullWidth
                      label="Issue Date"
                      format="MM/dd/yyyy"
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      error={errors.issueDate !== undefined}
                      onChange={(data) => {
                        onChange(data);
                      }}
                      value={value}
                    />
                  )}
                  control={control}
                  name="issueDate"
                  rules={{
                    required: {
                      value: true,
                      message: 'Issue Date is required',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md>
                <Controller
                  render={({ onChange, value }) => (
                    <KeyboardDatePicker
                      required
                      fullWidth
                      label="Due Date"
                      format="MM/dd/yyyy"
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      error={errors.dueDate !== undefined}
                      onChange={(data) => {
                        onChange(data);
                      }}
                      value={value}
                    />
                  )}
                  control={control}
                  name="dueDate"
                  rules={{
                    required: {
                      value: true,
                      message: 'Due Date is required',
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container item style={{ marginTop: '1rem' }} spacing={2}>
            <Grid item xs>
              <Typography variant="h5">Customer Details</Typography>
            </Grid>
            <Grid container item direction="row" spacing={2}>
              <Grid item xs={12} md>
                <Controller
                  render={({ onChange, value }) => (
                    <TextField
                      required
                      fullWidth
                      error={errors.name !== undefined}
                      helperText={errors.name?.message}
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="Name"
                      value={value}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                    />
                  )}
                  control={control}
                  name="name"
                  rules={{
                    required: {
                      value: true,
                      message: 'Name is required',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md>
                <Controller
                  render={({ onChange, value }) => (
                    <TextField
                      fullWidth
                      required
                      error={errors.addressLine1 !== undefined}
                      helperText={errors.addressLine1?.message}
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="Address Line 1"
                      value={value}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                    />
                  )}
                  control={control}
                  name="addressLine1"
                  rules={{
                    required: {
                      value: true,
                      message: 'Address Line 1 is required',
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item direction="row" spacing={2}>
              <Grid item xs={12} md>
                <Controller
                  render={({ onChange, value }) => (
                    <TextField
                      fullWidth
                      error={errors.addressLine2 !== undefined}
                      helperText={errors.addressLine2?.message}
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="Address Line 2"
                      value={value}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                    />
                  )}
                  control={control}
                  name="addressLine2"
                />
              </Grid>
              <Grid item xs={12} md>
                <Controller
                  render={({ onChange, value }) => (
                    <TextField
                      fullWidth
                      required
                      error={errors.city !== undefined}
                      helperText={errors.city?.message}
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="City"
                      value={value}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                    />
                  )}
                  control={control}
                  name="city"
                  rules={{
                    required: {
                      value: true,
                      message: 'City is required',
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item direction="row" spacing={2}>
              <Grid item xs={12} md>
                <Controller
                  render={({ onChange, value }) => (
                    <Autocomplete
                      options={Object.keys(USState)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          error={errors.state !== undefined}
                          helperText={errors.state?.message}
                          InputLabelProps={{ shrink: true }}
                          label="State"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'disabled',
                          }}
                        />
                      )}
                      fullWidth
                      clearOnEscape
                      autoHighlight
                      onChange={(_, data) => {
                        onChange(data);
                      }}
                      value={value}
                    />
                  )}
                  control={control}
                  name="state"
                  rules={{
                    required: {
                      value: true,
                      message: 'State is required',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md>
                <Controller
                  render={({ onChange, value }) => (
                    <TextField
                      fullWidth
                      required
                      error={errors.zip !== undefined}
                      helperText={errors.zip?.message}
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="Zip"
                      value={value}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                    />
                  )}
                  control={control}
                  name="zip"
                  rules={{
                    required: {
                      value: true,
                      message: 'Zip is required',
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item direction="row" spacing={2}>
              <Grid item xs={12} md>
                <Controller
                  render={({ onChange, value }) => (
                    <TextField
                      fullWidth
                      required
                      error={errors.email !== undefined}
                      helperText={errors.email?.message}
                      InputLabelProps={{ shrink: true }}
                      type="email"
                      label="E-Mail"
                      value={value}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                    />
                  )}
                  control={control}
                  name="email"
                  rules={{
                    required: {
                      value: true,
                      message: 'Email is required',
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container item style={{ marginTop: '1rem' }} direction="column" spacing={2}>
            <Grid item xs>
              <Typography variant="h5">Invoice Lines</Typography>
            </Grid>
            <Grid item xs>
              <InvoiceLinesTable invoice={invoice} />
            </Grid>
            <Grid item xs>
              <Grid container item direction="row" spacing={1}>
                <Grid item>
                  <Typography className={classes.bold}>Total: </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    <CurrencyFormat
                      value={
                        invoice?.invoiceLines
                          ?.filter((invoiceLine) => invoiceLine.product.indexOf('Taxes') === -1)
                          ?.map((invoiceLine) => invoiceLine.amount)
                          ?.reduce((a, b) => a + b, 0) ?? 0
                      }
                      displayType="text"
                      thousandSeparator
                      prefix={'$ '}
                      fixedDecimalScale
                      decimalScale={2}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs>
              <Grid container item direction="row" spacing={1}>
                <Grid item>
                  <Typography className={classes.bold}> Total (Inc Tax): </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    <CurrencyFormat
                      value={
                        invoice?.invoiceLines
                          ?.map((invoiceLine) => invoiceLine.amount)
                          ?.reduce((a, b) => a + b, 0) ?? 0
                      }
                      displayType="text"
                      thousandSeparator
                      prefix={'$ '}
                      fixedDecimalScale
                      decimalScale={2}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item style={{ marginTop: '1rem' }} direction="column" spacing={2}>
          <Grid item xs>
            <Typography variant="h5">Splits & Commissions</Typography>
          </Grid>
          <Grid container item direction="row">
            <Grid container item xs={12} md={4} direction="column">
              <Grid item>
                <Typography className={classes.bold}>Bascule Commission Split (%)</Typography>
              </Grid>
              <Grid item>
                <Typography>{invoice?.policy?.mgaPctAfterNetdown ?? '0'}%</Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} md={4} direction="column">
              <Grid item>
                <Typography className={classes.bold}>Bascule Total</Typography>
              </Grid>
              <Grid item>
                <Typography>
                  <CurrencyFormat
                    value={invoice?.mgaCommission ?? 0}
                    displayType="text"
                    thousandSeparator
                    prefix={'$ '}
                    fixedDecimalScale
                    decimalScale={2}
                  />
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} md={4} direction="column">
              <Grid item>
                <Typography className={classes.bold}>Bascule Total (Inc Tax)</Typography>
              </Grid>
              <Grid item>
                <Typography>
                  <CurrencyFormat
                    value={(invoice?.mgaCommission ?? 0) + (invoice?.mgaTax ?? 0)}
                    displayType="text"
                    thousandSeparator
                    prefix={'$ '}
                    fixedDecimalScale
                    decimalScale={2}
                  />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item direction="row">
            <Grid container item xs={12} md={4} direction="column">
              <Grid item>
                <Typography className={classes.bold}>Agent Commission Split (%)</Typography>
              </Grid>
              <Grid item>
                <Typography>{invoice?.policy?.agentPctAfterNetdown ?? '0'}%</Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} md={4} direction="column">
              <Grid item>
                <Typography className={classes.bold}>Agent Total</Typography>
              </Grid>
              <Grid item>
                <Typography>
                  <CurrencyFormat
                    value={invoice?.agentCommission ?? 0}
                    displayType="text"
                    thousandSeparator
                    prefix={'$ '}
                    fixedDecimalScale
                    decimalScale={2}
                  />
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} md={4} direction="column">
              <Grid item>
                <Typography className={classes.bold}>Agent Total (Inc Tax)</Typography>
              </Grid>
              <Grid item>
                <Typography>
                  <CurrencyFormat
                    value={invoice?.agentCommission ?? 0}
                    displayType="text"
                    thousandSeparator
                    prefix={'$ '}
                    fixedDecimalScale
                    decimalScale={2}
                  />
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid container item direction="row">
            <Grid container item xs={12} md={4} direction="column">
              <Grid item>
                <Typography className={classes.bold}>Insurer Commission Split (%)</Typography>
              </Grid>
              <Grid item>
                <Typography>{invoice?.policy?.insurerPctAfterNetdown ?? '0'}%</Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} md={4} direction="column">
              <Grid item>
                <Typography className={classes.bold}>Insurer Total</Typography>
              </Grid>
              <Grid item>
                <Typography>
                  <CurrencyFormat
                    value={(invoice?.insurerCommission ?? 0) - (invoice?.insurerTax ?? 0)}
                    displayType="text"
                    thousandSeparator
                    prefix={'$ '}
                    fixedDecimalScale
                    decimalScale={2}
                  />
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} md={4} direction="column">
              <Grid item>
                <Typography className={classes.bold}>Insurer Total (Inc Tax)</Typography>
              </Grid>
              <Grid item>
                <Typography>
                  <CurrencyFormat
                    value={invoice?.insurerCommission ?? 0}
                    displayType="text"
                    thousandSeparator
                    prefix={'$ '}
                    fixedDecimalScale
                    decimalScale={2}
                  />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ marginRight: '2rem' }}>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button color="primary" variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InvoiceFormModal;

import { FormControlLabel, Switch } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

export interface QuestionProps {
  questionName: string;
  questionLabel: string;
  labelPlacement?: 'start' | 'end' | 'top' | 'bottom';
  value?: any;
  onChange?: any;
  disabled?: boolean;
}

const BooleanQuestion: React.FC<QuestionProps> = (props) => {
  const { questionName, questionLabel, labelPlacement, value, onChange, disabled } = props;

  return (
    <>
      <FormControlLabel
        labelPlacement={labelPlacement}
        control={
          <Switch
            name={questionName}
            checked={value}
            onChange={(e) => onChange(e.target.checked)}
            color="primary"
            disabled={disabled}
          />
        }
        label={questionLabel}
      />
    </>
  );
};

BooleanQuestion.defaultProps = {
  labelPlacement: 'start',
};

export default BooleanQuestion;

import { List, ListItem, ListItemText, ListItemIcon, IconButton } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';

import { useCallback, useEffect, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';

import { callWs } from '../../../api/websocket';
import { MESSAGE_TYPES } from '../../../api/messageTypes';
import * as customToast from '../../../shared/toast/customToast';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column' as 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

interface FileInputProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  name: string;
  label?: string;
  mode?: 'update' | 'append';
  policy?: any;
  formmethods: any;
  fetchPolicy: any;
}

const FilePicker: React.FC<FileInputProps> = (props) => {
  const {
    name,
    label = name,
    mode = 'update',
    policy,
    formmethods: { register, unregister, setValue, watch },
    fetchPolicy,
  } = props;

  const files: File[] = watch(name);

  const {
    mutateAsync: uploadFile,
    isLoading: isUploadFileLoading,
  } = useMutation(MESSAGE_TYPES.POLICY_UPLOAD_DOCUMENT, (data: any) =>
    callWs(MESSAGE_TYPES.POLICY_UPLOAD_DOCUMENT, data),
  );

  const handleFileChosen = async (file) => {
    return new Promise((resolve, reject) => {
      let fileReader = new FileReader();

      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = reject;
    });
  };

  const readAllFiles = async (AllFiles) => {
    const results = await Promise.all(
      AllFiles.map(async (file) => {
        const fileContents = await handleFileChosen(file);

        return { name: file.name, type: file.type, content: fileContents };
      }),
    );

    return results;
  };

  const onDrop = useCallback(
    async (droppedFiles) => {
      let newFiles = mode === 'update' ? droppedFiles : [...(files || []), ...droppedFiles];
      if (mode === 'append') {
        newFiles = newFiles.reduce((prev, file) => {
          const fo = Object.entries(file);
          if (
            prev.find((e: File) => {
              const eo = Object.entries(e);

              return eo.every(
                ([key, value], index) => key === fo[index][0] && value === fo[index][1],
              );
            })
          ) {
            return prev;
          } else {
            return [...prev, file];
          }
        }, []);
      }

      const readerResults = await readAllFiles(newFiles);
      setValue(name, newFiles, { shouldValidate: true });

      try {
        await uploadFile({ policy_id: policy.id, files: readerResults });
        customToast.success('File were uploaded successfully.', {
          id: 'files-upload-success',
        });
        fetchPolicy();
      } catch (err) {
        customToast.error(err.messageCode);
        console.log('error while uploading policy documents');
      }
    },
    [setValue, name, files, mode],
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    fileRejections,
  } = useDropzone({
    onDrop,
    accept: props.accept,
    maxSize: 2 * 1000 * 1000, // max 2MB
  });

  useEffect(() => {
    register(name);

    return () => {
      unregister(name);
    };
  }, [register, unregister, name]);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept],
  );

  const _onRemoveFile = (toBeRemovedFile) => {
    const newFiles = [...files];
    const indexToBeRemoved = newFiles.findIndex((file) => file.name === toBeRemovedFile.name);
    newFiles.splice(indexToBeRemoved, 1);

    setValue(name, newFiles, { shouldValidate: false });
  };

  useEffect(() => {
    if (fileRejections && fileRejections.length > 0) {
      fileRejections.forEach((fileRejected: any) => {
        const name = fileRejected.file.name;
        const error = fileRejected.errors?.[0];

        toast.error(`The file ${name} can not be attached: ${error.message}`, {
          toastId: `file-picker-error-${name}`,
        });
      });
    }
  }, [fileRejections]);

  return (
    <div className="form-group">
      <div {...getRootProps({ style })}>
        <input {...props} className="form-control" id={name} {...getInputProps()} />
        <div>
          <p className="text-center">Drop any files here...</p>
          <em>You can attach PDF, TXT, DOC, DOCX, PNG or JPG files.</em>
        </div>
      </div>
      <div>
        {!!files?.length && (
          <List component="ul" aria-label="">
            {files.map((file) => {
              return (
                <ListItem key={file.name}>
                  <ListItemText primary={file.name} />
                  <ListItemIcon>
                    <IconButton>
                      <DeleteIcon onClick={() => _onRemoveFile(file)} />
                    </IconButton>
                  </ListItemIcon>
                </ListItem>
              );
            })}
          </List>
        )}
      </div>
    </div>
  );
};

export default FilePicker;

import { TextField } from '@material-ui/core';
import CurrencyFormat from 'react-number-format';

import { InputWrapperProps } from '../InputWrapper';
import { handleKey } from '../../../../utils/formUtils';

export interface CurrencyInputProps extends InputWrapperProps {
  value: number | undefined;
  onChange: (value: any) => void;
}

const CurrencyInput: React.FC<CurrencyInputProps> = (props) => {
  const {
    properties,
    displayMode,
    value,
    onChange,
    evaluateRules,
    onKeyDown,
    displayLabel,
    error,
    helperText,
    afterOnChange,
  } = props;

  return displayMode === 'read' ? (
    <CurrencyFormat
      value={value != null ? value : ''}
      displayType="text"
      thousandSeparator
      prefix={'$ '}
      fixedDecimalScale={properties.type === 'float'}
      decimalScale={properties.type === 'float' ? 2 : 0}
    />
  ) : (
    <CurrencyFormat
      customInput={TextField}
      thousandSeparator
      prefix={'$ '}
      type="text"
      fixedDecimalScale={properties.type === 'float'}
      decimalScale={properties.type === 'float' ? 2 : 0}
      label={displayLabel ? properties.label : undefined}
      fullWidth
      variant="standard"
      margin="dense"
      InputLabelProps={{ shrink: true }}
      value={value != null ? value : ''}
      onValueChange={(values) => {
        onChange(values?.floatValue?.toString());
      }}
      onChange={() => {
        afterOnChange?.();
      }}
      onKeyDown={(e) => (onKeyDown ? onKeyDown(e) : handleKey(e))}
      onBlur={evaluateRules}
      required={properties?.validation?.front?.required}
      error={error}
      helperText={helperText}
    />
  );
};

export default CurrencyInput;

import { Box, Button, Grid, Paper } from '@material-ui/core';
import MaterialTable, { Icons, MTableAction } from '@material-table/core';
import SearchIcon from '@material-ui/icons/Search';

import { forwardRef, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';

import { MESSAGE_TYPES } from '../../../../api/messageTypes';
import { callWs } from '../../../../api/websocket';
import PaymentCreate from '../../payments/payment-create/PaymentCreate';
import PaymentDistribution from '../payment-distribution/PaymentDistribution';

const tableIcons: Icons = {
  Filter: forwardRef((props, ref) => <SearchIcon {...props} ref={ref} />),
};

export interface PaymentListProps {
  policy: any;
  referredOnly: any;
}

const PaymentList: React.FC<PaymentListProps> = (props) => {
  const { policy, referredOnly = false } = props;

  const history = useHistory();
  const tableRef: any = useRef();

  const [paymentEditing, setPaymentEditing] = useState(false);
  const [paymentDistributionDialog, setPaymentDistributionDialog] = useState(false);
  const [paymentDistributionDialogData, setPaymentDistributionDialogData] = useState(null);
  const [change, setChange] = useState(0);

  const _handleCreateClose = () => {
    setPaymentEditing(false);
    setChange(change + 1);
  };

  const _handleCreateCloseSilent = () => {
    setPaymentEditing(false);
  };

  const _handleDistributionClose = () => {
    setPaymentDistributionDialog(false);
    setChange(change + 1);
  };

  const _handleDistributionCloseSilent = () => {
    setPaymentDistributionDialog(false);
  };

  const methods = useForm({
    defaultValues: {
      policyId: policy.id,
      amount: null,
      referenceCode: null,
      insuredPerson: null,
      depositMethod: null,
      transactionDate: new Date(),
    },
    mode: 'all',
  });

  useEffect(() => {
    if (tableRef && tableRef.current) {
      tableRef.current.onQueryChange();
    }
  }, [referredOnly, change]);

  const columns: any[] = [
    {
      title: 'Payment Ref',
      field: 'referenceCode',
    },
    {
      title: 'Amount',
      field: 'amount',
    },
    {
      title: 'Deposit Type',
      field: 'depositMethod',
    },
    {
      title: 'Status',
      field: 'status',
    },
    {
      title: 'Date',
      field: 'transactionDate',
    },
  ];

  const _getSortFieldName = (fieldName?: string) => {
    if (!fieldName) return undefined;
    if (fieldName.includes('product')) {
      return 'product.name';
    } else {
      return fieldName;
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <Paper>
          <Box p="1rem">
            <Button
              onClick={() => {
                setPaymentEditing(true);
              }}
              color="primary"
              variant="contained"
            >
              Create Payment
            </Button>
          </Box>
          <Box p="1rem">
            <Grid container item xs={12}>
              <Grid item xs={12}>
                <MaterialTable
                  tableRef={tableRef}
                  data={(query) =>
                    new Promise(async (resolve, reject) => {
                      const response: any = await callWs(MESSAGE_TYPES.PAYMENTS_SEARCH, {
                        // policy_id: policy.id,
                        paginate: {
                          page: query.page + 1,
                          perPage: query.pageSize,
                        },
                        filter: {
                          ...query.filters
                            .map((filter: any) => {
                              return {
                                [filter.column.field]: filter.value,
                              };
                            })
                            .reduce((acc, next) => Object.assign(acc, next), {}),
                          policy_id: policy.id,
                        },
                        sort: query.orderBy
                          ? `${query.orderDirection === 'asc' ? '-' : ''}${_getSortFieldName(
                              query.orderBy.field?.toString(),
                            )}`
                          : undefined,
                      });
                      resolve({
                        data: response?.data ?? [],
                        page: response?.currentPage - 1 ?? 0,
                        totalCount: response?.total ?? 0,
                      });
                    })
                  }
                  columns={columns}
                  components={{
                    Action: (props) => {
                      const action = props.action?.action
                        ? props.action.action(props.data)
                        : props.action;

                      if (action.icon === 'edit' && props.data.status !== 'VOID') {
                        return (
                          <Button
                            onClick={(event) => action.onClick(event)}
                            color="primary"
                            variant="contained"
                            style={{ textTransform: 'none' }}
                            size="small"
                          >
                            Edit
                          </Button>
                        );
                      } else {
                        return <></>;
                      }
                    },
                  }}
                  actions={[
                    (rowData: any) => ({
                      icon: 'edit',
                      tooltip: 'Edit payment',
                      onClick: () => {
                        //this need to bring up the pop up or navigate on the edit page when that is finished
                        setPaymentDistributionDialogData(rowData);
                        setPaymentDistributionDialog(true);
                      },
                    }),
                  ]}
                  icons={tableIcons}
                  options={{
                    search: false,
                    paging: true,
                    filtering: true,
                    draggable: false,
                    sorting: false,
                    showTitle: false,
                    pageSize: 10,
                    pageSizeOptions: [10],
                    debounceInterval: 500,
                    actionsColumnIndex: -1,
                    rowStyle: (rowData) => {
                      if (rowData.status === 'ACTIVE') {
                        if (rowData.amount > 0) {
                          return {
                            fontFamily: 'Roboto',
                            fontSize: 14,
                            fontWeight: 900,
                            // E8EEE1 - e8eee170
                            color: '#099200',
                          };
                        } else {
                          return {
                            fontFamily: 'Roboto',
                            fontSize: 14,
                            fontWeight: 900,
                            // F6E8E6 - f2e0dd5e
                            color: '#FF4343',
                          };
                        }
                      } else {
                        return {
                          fontFamily: 'Roboto',
                          fontSize: 14,
                          fontWeight: 200,
                          color: '#808080',
                        };
                      }
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>
        <PaymentCreate
          open={!!paymentEditing}
          handleClose={() => _handleCreateClose()}
          handleCloseSilent={() => _handleCreateCloseSilent()}
          policy={policy}
        ></PaymentCreate>

        <PaymentDistribution
          open={!!paymentDistributionDialog}
          handleClose={() => _handleDistributionClose()}
          handleCloseSilent={() => _handleDistributionCloseSilent()}
          policy={policy}
          rowData={paymentDistributionDialogData}
        ></PaymentDistribution>
        <DevTool control={methods.control} />
      </FormProvider>
    </>
  );
};

export default PaymentList;

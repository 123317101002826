import { createContext, useState } from 'react';

export interface IAuthentication {
  isAuthenticated: boolean;
  token?: string;
  permissions?: any[];
}

type AuthContextType = {
  authenticationData: IAuthentication;
  setAuthenticationData: (value: IAuthentication) => void;
};

const defaultValues = {
  authenticationData: {
    isAuthenticated: !!localStorage.getItem('auth'),
    token: localStorage.getItem('auth')
      ? JSON.parse(localStorage.getItem('auth') as any)?.token
      : undefined,
    permissions: localStorage.getItem('auth')
      ? JSON.parse(localStorage.getItem('auth') as any)?.permissions
      : null,
  },
  setAuthenticationData: () => {},
};

export const AuthContext = createContext<AuthContextType>(defaultValues);

const AuthProvider = (props) => {
  const { children } = props;

  const [authenticationData, setAuthenticationData] = useState<IAuthentication>(
    defaultValues.authenticationData,
  );

  return (
    <AuthContext.Provider value={{ authenticationData, setAuthenticationData }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

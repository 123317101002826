import { Button, Chip, createStyles, Grid, makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useMutation } from 'react-query';

import { MESSAGE_TYPES } from '../../../../../../api/messageTypes';
import { callWs } from '../../../../../../api/websocket';
import { removeNullValues } from '../../../../../../utils/utils';
import ClientsDialog from '../../../clients-dialog/ClientsDialog';
import * as customToast from '../../../../../toast/customToast';
import InsuredPersonForm from '../insured-person-form/InsuredPersonForm';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      padding: '0.5rem',
      boxShadow: '0 0 3px grey',
      borderRadius: '10px',
      justifyContent: 'center',
    },
  }),
);
interface InsuredPersonsWrapperProps {
  policy: any;
  fetchPolicy: any;
  setIsPolicyChanged: any;
}

const InsuredPersonsWrapper: React.FC<InsuredPersonsWrapperProps> = (props) => {
  const { policy, fetchPolicy, setIsPolicyChanged } = props;
  const classes = useStyles();
  const { control, watch, setValue, getValues, errors, handleSubmit } = useFormContext();

  const [open, setOpen] = useState(false);
  const [insuredId, setInsuredId] = useState<string>('');

  const handleOpen = () => {
    setOpen(true);
  };

  const insuredDetails = watch('insuredDetails');

  useEffect(() => {
    setInsuredId(insuredDetails?.[0]?.id);
  }, [insuredDetails]);

  const {
    mutateAsync: createInsuredPerson,
    isLoading: isCreatePersonLoading,
  } = useMutation(MESSAGE_TYPES.PERSON_CREATE, (data: any) =>
    callWs(MESSAGE_TYPES.PERSON_CREATE, data),
  );

  const { mutateAsync: addInsuredPerson } = useMutation(
    MESSAGE_TYPES.ADD_INSURED_PERSON,
    (data: any) => callWs(MESSAGE_TYPES.ADD_INSURED_PERSON, data),
  );

  const {
    mutateAsync: updateInsuredPerson,
    isLoading: isUpdatePersonLoading,
  } = useMutation(MESSAGE_TYPES.PERSON_UPDATE, (data: any) =>
    callWs(MESSAGE_TYPES.PERSON_UPDATE, data),
  );

  const { mutateAsync: deleteInsuredPerson } = useMutation(
    MESSAGE_TYPES.DELETE_INSURED_PERSON,
    (data: any) => callWs(MESSAGE_TYPES.DELETE_INSURED_PERSON, data),
  );

  const _createInsuredPerson = async (data) => {
    try {
      const id: string = (await createInsuredPerson({
        ...removeNullValues({
          ...data,
          companyName: data.isCompany ? data.companyName : null,
        }),
        relation_id: policy.id,
        relation_type: 'policy',
      })) as string;
      setValue('insuredDetails', [{ id, ...data }, ...(insuredDetails || [])], {
        shouldValidate: true,
        shouldDirty: false,
      });
      setInsuredId(id);
      fetchPolicy();
      setIsPolicyChanged(false);

      return id;
    } catch (err) {
      customToast.error(err.messageCode);
      throw err;
    }
  };

  const _addInsuredPerson = async (data) => {
    try {
      await addInsuredPerson({
        policyId: policy.id,
        id: data.id,
      });
      setValue('insuredDetails', [...(insuredDetails || []), { ...data }], {
        shouldValidate: true,
        shouldDirty: false,
      });
      setInsuredId(data.id);
      fetchPolicy();
      setIsPolicyChanged(false);
    } catch (err) {
      customToast.error(err.messageCode);
      throw err;
    }
  };

  const _updateInsuredPerson = async (updatedId, newData) => {
    try {
      await updateInsuredPerson({
        id: updatedId,
        ...removeNullValues({
          ...newData,
          companyName: newData.companyName ? newData.companyName : '',
          firstName: newData.firstName ? newData.firstName : '',
          lastName: newData.lastName ? newData.lastName : '',
        }),
      });
      const updatedIndex = insuredDetails.findIndex((row: any) => row.id === updatedId);

      setValue(
        'insuredDetails',
        [
          ...insuredDetails.slice(0, updatedIndex),
          newData,
          ...insuredDetails.slice(updatedIndex + 1, insuredDetails.length),
        ],
        {
          shouldValidate: true,
          shouldDirty: false,
        },
      );
      fetchPolicy();
      setIsPolicyChanged(false);
    } catch (err) {
      customToast.error(err.messageCode);
      throw err;
    }
  };

  const _deleteInsuredPerson = async (removedId) => {
    try {
      await deleteInsuredPerson({
        id: removedId,
        policyId: policy.id,
      });

      setValue(
        'insuredDetails',
        insuredDetails.filter((details) => details.id !== removedId),
        { shouldValidate: true, shouldDirty: false },
      );
      fetchPolicy();
      setIsPolicyChanged(false);
    } catch (err) {
      customToast.error(err.messageCode);
      throw err;
    }
  };

  const _saveInsuredPerson = async (data: any, forceCreateNewClient?: boolean) => {
    if (!forceCreateNewClient && insuredId) {
      await _updateInsuredPerson(insuredId, data);
      customToast.success('Client updated successfully.');
      fetchPolicy();
      setIsPolicyChanged(false);

      return insuredId;
    } else {
      const id = await _createInsuredPerson(data);
      customToast.success('Client saved successfully.');
      fetchPolicy();
      setIsPolicyChanged(false);

      return id;
    }
  };

  if (policy.productDescription.jsonDescription.hasMultipleLossPayees) {
    return (
      <Grid container item xs={12} style={{ padding: '20px' }}>
        <Grid item xs={12} md style={{ padding: '2.5rem' }}>
          <Grid container className={classes.root}>
            <Grid item xs={12}>
              <Controller
                as={<></>}
                name="insuredDetails"
                control={control}
                defaultValue={[]}
                rules={{
                  validate: {
                    notEmpty: (value) => value.length > 0 || 'Insured Details is required',
                  },
                }}
              />
            </Grid>

            <Grid item container xs={12} spacing={1}>
              {insuredDetails.length === 0 && (
                <Chip
                  label="Add insured clients"
                  onClick={handleOpen}
                  style={{ opacity: ' 0.6' }}
                />
              )}
              {insuredDetails.length > 0 &&
                insuredDetails.map((details: any, index) => (
                  <Grid key={details.id} item xs>
                    <Chip
                      label={
                        details.isCompany
                          ? details.companyName
                          : `${details.firstName} ${details.lastName}`
                      }
                      onDelete={() => _deleteInsuredPerson(details.id)}
                    />
                  </Grid>
                ))}
            </Grid>
          </Grid>
          {errors.insuredDetails && (
            <div className="invalid-feedback">{errors.insuredDetails.message}</div>
          )}
        </Grid>
        <Grid item xs={3} md style={{ alignSelf: 'center' }}>
          <Button color="primary" fullWidth variant="contained" onClick={handleOpen}>
            Edit
          </Button>
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={2} />
        <Grid item xs={2} />
        <Grid item xs={2} />
        <ClientsDialog
          savedClients={insuredDetails}
          handleInsert={_createInsuredPerson}
          handleAdd={_addInsuredPerson}
          handleUpdate={_updateInsuredPerson}
          handleRemove={_deleteInsuredPerson}
          open={open}
          handleClose={() => setOpen(false)}
        />
      </Grid>
    );
  } else {
    return (
      <InsuredPersonForm
        saveInsuredPerson={_saveInsuredPerson}
        handleAdd={_addInsuredPerson}
        handleRemove={_deleteInsuredPerson}
        insuredPerson={insuredDetails?.[0]}
        isSaveLoading={isCreatePersonLoading || isUpdatePersonLoading}
        setIsPolicyChanged={setIsPolicyChanged}
      />
    );
  }
};

export default InsuredPersonsWrapper;

import { parse, format } from 'date-fns';
import ExpressionLanguage from '../shared/rule-parser/ExpressionLanguage';
export const formatValueToSave = (data, entity) => {
  if (data == null || data === [] || data === '') {
    return '';
  }

  if (entity.type === 'date') {
    if (entity.presentation === 'date') {
      return format(parse(data, 'MM/dd/yyyy', new Date()), 'yyyy-MM-dd');
    }
  }
  if (entity.type === 'reference') {
    return data[entity.referenceStore];
  }

  return data;
};

export const dateFormater = (dateToFormat) => {
  let formatedDate;
  if (dateToFormat !== undefined && dateToFormat !== null) {
    if (dateToFormat.includes('T')) {
      let date = new Date(dateToFormat);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let monthString: string = month.toString();
      let dt: string = date.getDate().toString();
      let hour = date.getHours();
      let min = date.getMinutes();
      let secs = date.getSeconds();
      if (parseInt(dt) < 10) {
        dt = '0' + dt;
      }
      if (parseInt(monthString) < 10) {
        monthString = '0' + monthString;
      }
      formatedDate = new Date(year, parseInt(monthString) - 1, parseInt(dt), hour, min, secs);
      formatedDate.setMinutes(formatedDate.getMinutes() + formatedDate.getTimezoneOffset());
    } else {
      const dateToFormatSplit = dateToFormat.split('-');
      formatedDate = new Date(dateToFormatSplit[0], dateToFormatSplit[1] - 1, dateToFormatSplit[2]);
    }
  }

  return formatedDate;
};
export const formatValueFrom = (data, entity, policy = {}) => {
  if (entity == null) return undefined;
  if (entity.type === 'date') {
    if (data) {
      if (entity.presentation === 'date') {
        return format(parse(data, 'yyyy-MM-dd', new Date()), 'MM/dd/yyyy');
      }

      if (entity.presentation === 'year') {
        return format(parse(data, 'yyyy-MM-dd', new Date()), 'yyyy');
      }
    }
  }

  if (entity.type === 'reference') {
    const expressionLanguage = new ExpressionLanguage();

    const expressionData: any = expressionLanguage.evaluate(entity.refersTo, { policy });

    return expressionData.find((item) => item[entity.referenceStore] === data);
  }

  return data;
};

import {
  Grid,
  Button,
  List,
  ListItem,
  Link,
  ListItemText,
  CircularProgress,
} from '@material-ui/core';
import { withStyles, Theme } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';

import { useState, memo, Fragment, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useMutation } from 'react-query';

import FilePicker from '../../../file-picker/FilePicker';
import { MESSAGE_TYPES } from '../../../../../api/messageTypes';
import { callWs } from '../../../../../api/websocket';
import EmailDialog from '../../../dialogs/email-dialog/EmailDialog';
import * as customToast from './../../../../toast/customToast';

const ColorButton = withStyles((theme: Theme) => ({
  root: {
    color: red[700],

    // backgroundColor: red[500],

    // '&:hover': {
    //   backgroundColor: red[700],
    // },
  },
}))(Button);

const DocumentsPanel = (props) => {
  const { policy, fetchPolicy } = props;

  const formMethods = useFormContext();
  const filesList = policy?.attachedDocuments ?? [];

  const [emailModalOptions, setEmailModalOptions] = useState<any>();
  const [fileIdBeingDeleted, setFileIdBeingDeleted] = useState('');

  const {
    mutateAsync: getDocument,
    isLoading: isDocumentLoading,
  } = useMutation(MESSAGE_TYPES.DOCUMENT_GET, (path: any) =>
    callWs(MESSAGE_TYPES.DOCUMENT_GET, { path }),
  );

  const {
    mutateAsync: deleteDocument,
    isLoading: isDeleteDocumentLoading,
  } = useMutation(MESSAGE_TYPES.DOCUMENT_DELETE, (id: any) =>
    callWs(MESSAGE_TYPES.DOCUMENT_DELETE, { id }),
  );

  const _fetchDocument = async (path) => {
    const urlResponse: any = await getDocument(path);
    window.open(urlResponse, '_blank');
  };

  const _deleteDocument = async (id) => {
    setFileIdBeingDeleted(id);
    try {
      const deleteResponse: any = await deleteDocument(id);
      customToast.success('File was successfully removed from the policy.', {
        id: 'file-deletion-success',
      });
      fetchPolicy();
    } catch (error) {
      customToast.error(error.messageCode);
      console.log('error while deleting file from policy');
    }
  };

  const _openEmailModal = (name, path) => {
    setEmailModalOptions({
      name,
      path,
    });
  };

  const _mappedEmails = () => {
    return policy?.insuredPersons?.map((person) => person.contactEmail).join(' ,') + ' ,' ?? '';
  };

  return (
    <Grid container>
      <Grid item>
        {filesList.map((childItem) => (
          <List dense key={childItem.name}>
            <ListItem>
              <ListItemText
                primary={
                  <Fragment>
                    <Link href="#" onClick={() => _fetchDocument(childItem.path)}>
                      {childItem.name}
                    </Link>
                    <span> &nbsp;-</span>
                    <Button
                      color="primary"
                      disabled={isDeleteDocumentLoading}
                      variant="text"
                      onClick={() => _openEmailModal(childItem.name, childItem.path)}
                    >
                      Email Document
                    </Button>
                    <span> &nbsp;-</span>
                    <ColorButton
                      disabled={isDeleteDocumentLoading}
                      onClick={() => _deleteDocument(childItem.id)}
                    >
                      {!isDeleteDocumentLoading ? (
                        'Delete File'
                      ) : fileIdBeingDeleted === childItem.id ? (
                        <CircularProgress size={24} />
                      ) : (
                        'Delete File'
                      )}
                    </ColorButton>
                  </Fragment>
                }
              />
            </ListItem>
          </List>
        ))}
      </Grid>
      <Grid item xs={12}>
        <FilePicker
          name="newDocuments"
          mode="append"
          policy={policy}
          formmethods={formMethods}
          fetchPolicy={fetchPolicy}
        />
      </Grid>
      {emailModalOptions && (
        <EmailDialog
          open={true}
          onClose={() => setEmailModalOptions(undefined)}
          onSubmit={() => {}}
          fileName={emailModalOptions?.name}
          path={emailModalOptions?.path}
          emails={_mappedEmails()}
        />
      )}
    </Grid>
  );
};

export default memo(DocumentsPanel);

import { createContext, useState } from 'react';

export interface ILayout {
  headerTitle: string;
  sidebarOpen: boolean;
}

type LayoutContextType = {
  layoutData: ILayout;
  setLayoutData: (value: ILayout) => void;
};

const defaultValues = {
  layoutData: {
    headerTitle: 'Bascule UW',
    sidebarOpen: false,
  },
  setLayoutData: () => {},
};

export const LayoutContext = createContext<LayoutContextType>(defaultValues);

const LayoutProvider = (props) => {
  const { children } = props;

  const [layoutData, setLayoutData] = useState<ILayout>(defaultValues.layoutData);

  return (
    <LayoutContext.Provider value={{ layoutData, setLayoutData }}>
      {children}
    </LayoutContext.Provider>
  );
};

export default LayoutProvider;

import { useState, forwardRef } from 'react';
import {
  Button,
  TextField,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Grid,
  Slide,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { makeStyles } from '@material-ui/core/styles';
import { Close as CloseIcon } from '@material-ui/icons';
import { KeyboardDatePicker } from '@material-ui/pickers';

import EndorsementsTable from './EndorsementsTable';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'fixed',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  container: {
    padding: '3rem 3rem',
  },
}));

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EndorsementsDialog = (props) => {
  const { open, handleClose } = props;

  const classes = useStyles();

  const [endorsmentNo, setEndorsmentNo] = useState('');
  const endorsmentNoChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setEndorsmentNo(event.target.value as string);
  };
  const [effectiveDateOfEndorsment, setEffectiveDateOfEndorsment] = useState('');
  const effectiveDateOfEndorsmentChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setEffectiveDateOfEndorsment(event.target.value as string);
  };

  const [effectiveDate, setEffectiveDate] = useState<Date | null>(new Date());
  const handleEffectiveDateChange = (date: Date | null) => {
    setEffectiveDate(date);
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar variant="dense">
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Endorsements
          </Typography>
          <Button autoFocus color="inherit" onClick={handleClose}>
            save
          </Button>
        </Toolbar>
      </AppBar>
      <Grid container className={classes.container}>
        <Grid container item xs={12}>
          <Grid item sm={12} md={3}>
            <TextField
              margin="dense"
              id="endorsment-number"
              value={endorsmentNo}
              onChange={endorsmentNoChange}
              label="Endorsment Number"
            ></TextField>
          </Grid>
          <Grid item xs={12} sm style={{ alignSelf: 'center' }}>
            <Button color="primary" variant="outlined">
              Quote
            </Button>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item sm={12} md={3}>
            <KeyboardDatePicker
              variant="inline"
              margin="dense"
              id="effective-date-of-endorsmente"
              label="Effective Date"
              format="MM/dd/yyyy"
              value={effectiveDate}
              onChange={handleEffectiveDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>
          <Grid item xs={12} sm style={{ alignSelf: 'center' }}>
            <Button color="primary" variant="outlined">
              Bind
            </Button>
          </Grid>
        </Grid>
        <Grid item xs>
          <h3>Endorsements</h3>
          <EndorsementsTable />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default EndorsementsDialog;

import MaterialTable from '@material-table/core';
import {
  ChevronRight as ChevronRightIcon,
  Clear as ClearIcon,
  Check as CheckIcon,
  Search as SearchIcon,
} from '@material-ui/icons';

const InvoiceLinesTable = (props) => {
  const { invoice } = props;

  return (
    <div>
      <MaterialTable
        style={{ width: '100%' }}
        data={invoice?.invoiceLines}
        columns={[
          {
            title: 'Product/Service',
            field: 'product',
            type: 'string',
          },
          {
            title: 'Amount',
            field: 'amount',
            type: 'numeric',
          },
          {
            title: 'Details',
            field: 'details',
            type: 'string',
          },
          {
            title: 'Installment',
            field: 'installment',
            type: 'string',
          },
        ]}
        editable={{
          onRowDelete: (data: any) =>
            new Promise<void>(async (resolve, reject) => {
              try {
                resolve();
              } catch (err) {
                reject();
              }
            }),

          onRowUpdate: (newData: any, oldData: any) =>
            new Promise<void>(async (resolve, reject) => {
              try {
                resolve();
              } catch (err) {
                reject();
              }
            }),
        }}
        options={{
          search: false,
          paging: true,
          filtering: false,
          sorting: false,
          draggable: false,
          showTitle: false,
          pageSize: 5,
          paginationType: 'stepped',
          rowStyle: { fontFamily: 'Roboto', fontSize: 14 },
          actionsColumnIndex: -1,
        }}
      />
    </div>
  );
};

export default InvoiceLinesTable;

import React from 'react';
import { MenuItem, InputLabel, Select, FormControl, FormHelperText } from '@material-ui/core';

import { InputWrapperProps } from '../InputWrapper';
import AutocompleteInput from '../autocomplete-input/AutocompleteInput';
import { handleKey } from '../../../../utils/formUtils';
import ExpressionLanguage from '../../../rule-parser/ExpressionLanguage';

export interface ReferenceInputProps extends InputWrapperProps {
  value: any;
  onChange: (value: any) => void;
}

const expressionLanguage = new ExpressionLanguage();

const ReferenceInput: React.FC<ReferenceInputProps> = (props) => {
  const {
    properties,
    displayMode,
    value,
    onChange,
    afterOnChange,
    evaluateRules,
    onKeyDown,
    displayLabel,
    error,
    helperText,
    disabled,
    referencedData,
  } = props;

  const referencedProperties: any = {
    ...properties,
    values: properties.refersTo
      ? expressionLanguage.evaluate(properties.refersTo, referencedData)
      : [],
  };

  const _formatOptionLabel = (item) => {
    if (item == null || item.length === 0) {
      return '';
    }

    return (
      properties?.referenceLabel
        ?.map((label) => {
          return expressionLanguage.evaluate(label, { item });
        })
        ?.join(' - ') ?? ''
    );
  };

  switch (properties.presentation) {
    case 'multiautocomplete':
    case 'autocomplete':
      return (
        <AutocompleteInput
          properties={referencedProperties}
          getOptionLabel={_formatOptionLabel}
          value={value}
          onChange={onChange}
          afterOnChange={afterOnChange}
          evaluateRules={evaluateRules}
          onKeyDown={onKeyDown}
          disabled={disabled}
          displayMode={displayMode}
          displayLabel={displayLabel}
          multiple={properties.presentation === 'multiautocomplete'}
          error={error}
          helperText={helperText}
        />
      );
    default:
      return <></>;
  }
};

export default ReferenceInput;

import { Close as CloseIcon } from '@material-ui/icons';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Grid,
  makeStyles,
  TextField,
  Button,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Autocomplete } from '@material-ui/lab';
import { useForm, Controller } from 'react-hook-form';
import { format } from 'date-fns';
import { useEffect } from 'react';
import CurrencyFormat from 'react-number-format';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'fixed',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },

  paper: { minWidth: 800 },
  bold: { fontWeight: 600 },
}));

const InvoiceDetailsModal = (props) => {
  const {
    invoice,
    title = 'Invoice #' + (invoice?.invoiceNumber ?? ''),
    open,
    handleClose,
  } = props;

  const classes = useStyles();

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={open}
      onClose={handleClose}
      disableBackdropClick
    >
      <DialogTitle>
        <Grid container>
          <Grid item>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item justify="center">
            <Typography variant="h6" className={classes.title}>
              {title}
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid container item spacing={2}>
            <Grid item xs>
              <Typography variant="h5">Communication Settings</Typography>
            </Grid>
            <Grid container item direction="row">
              <Grid container item xs={12} md direction="column">
                <Grid item>
                  <Typography className={classes.bold}>Recurring payments</Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    {invoice?.policy?.recurringPayments ? 'Yes' : 'No' ?? 'No'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12} md direction="column">
                <Grid item>
                  <Typography className={classes.bold}>Email Reminders</Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    {invoice?.policy?.invoicesReminders ? 'Yes' : 'No' ?? 'No'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12} md direction="column">
                <Grid item>
                  <Typography className={classes.bold}>Email</Typography>
                </Grid>
                <Grid item>
                  <Typography>{invoice?.policy?.invoicesByEmail ? 'Yes' : 'No' ?? 'No'}</Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12} md direction="column">
                <Grid item>
                  <Typography className={classes.bold}>Mail</Typography>
                </Grid>
                <Grid item>
                  <Typography>{invoice?.policy?.invoicesByPost ? 'Yes' : 'No' ?? 'No'}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container item style={{ marginTop: '1rem' }} direction="column" spacing={2}>
            <Grid item xs>
              <Typography variant="h5">Commissions Splits</Typography>
            </Grid>
            <Grid container item direction="row">
              <Grid container item xs={12} md={6} direction="column">
                <Grid item>
                  <Typography className={classes.bold}>Bascule Total</Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    <CurrencyFormat
                      value={invoice?.mgaCommission ?? 0}
                      displayType="text"
                      thousandSeparator
                      prefix={'$ '}
                      fixedDecimalScale
                      decimalScale={2}
                    />
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12} md={6} direction="column">
                <Grid item>
                  <Typography className={classes.bold}>Bascule Total (Inc Tax)</Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    <CurrencyFormat
                      value={(invoice?.mgaCommission ?? 0) + (invoice?.tax ?? 0)}
                      displayType="text"
                      thousandSeparator
                      prefix={'$ '}
                      fixedDecimalScale
                      decimalScale={2}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item direction="row">
              <Grid container item xs={12} md={6} direction="column">
                <Grid item>
                  <Typography className={classes.bold}>Agent Total</Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    <CurrencyFormat
                      value={invoice?.agentCommission ?? 0}
                      displayType="text"
                      thousandSeparator
                      prefix={'$ '}
                      fixedDecimalScale
                      decimalScale={2}
                    />
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12} md={6} direction="column">
                <Grid item>
                  <Typography className={classes.bold}>Agent Total (Inc Tax)</Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    <CurrencyFormat
                      value={invoice?.agentCommission ?? 0}
                      displayType="text"
                      thousandSeparator
                      prefix={'$ '}
                      fixedDecimalScale
                      decimalScale={2}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid container item direction="row">
              <Grid container item xs={12} md={6} direction="column">
                <Grid item>
                  <Typography className={classes.bold}>Insurer Total</Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    <CurrencyFormat
                      value={(invoice?.insurerCommission ?? 0) - (invoice?.insurerTax ?? 0)}
                      displayType="text"
                      thousandSeparator
                      prefix={'$ '}
                      fixedDecimalScale
                      decimalScale={2}
                    />
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12} md={6} direction="column">
                <Grid item>
                  <Typography className={classes.bold}>Insurer Total (Inc Tax)</Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    <CurrencyFormat
                      value={invoice?.insurerCommission ?? 0}
                      displayType="text"
                      thousandSeparator
                      prefix={'$ '}
                      fixedDecimalScale
                      decimalScale={2}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item direction="row">
              <Grid container item xs={12} md={6} direction="column">
                <Grid item>
                  <Typography className={classes.bold}>Total Commission (Excl Taxes)</Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    <CurrencyFormat
                      value={invoice?.totalExcludingTax ?? 0}
                      displayType="text"
                      thousandSeparator
                      prefix={'$ '}
                      fixedDecimalScale
                      decimalScale={2}
                    />
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12} md={6} direction="column">
                <Grid item>
                  <Typography className={classes.bold}>Total Commission (Inc Taxes)</Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    <CurrencyFormat
                      value={invoice?.total ?? 0}
                      displayType="text"
                      thousandSeparator
                      prefix={'$ '}
                      fixedDecimalScale
                      decimalScale={2}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item direction="row">
              <Grid container item xs={12} md={6} direction="column">
                <Grid item>
                  <Typography className={classes.bold}>Total Gross Premium (Excl Taxes)</Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    <CurrencyFormat
                      value={invoice?.totalExcludingTax ?? 0}
                      displayType="text"
                      thousandSeparator
                      prefix={'$ '}
                      fixedDecimalScale
                      decimalScale={2}
                    />
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12} md={6} direction="column">
                <Grid item>
                  <Typography className={classes.bold}>Total Gross Premium (Inc Taxes)</Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    <CurrencyFormat
                      value={invoice?.total ?? 0}
                      displayType="text"
                      thousandSeparator
                      prefix={'$ '}
                      fixedDecimalScale
                      decimalScale={2}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item direction="row">
              <Grid container item xs={12} md={6} direction="column">
                <Grid item>
                  <Typography className={classes.bold}>Total Taxes</Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    <CurrencyFormat
                      value={invoice?.tax ?? 0}
                      displayType="text"
                      thousandSeparator
                      prefix={'$ '}
                      fixedDecimalScale
                      decimalScale={2}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ marginRight: '2rem' }}>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InvoiceDetailsModal;

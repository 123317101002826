import { useContext } from 'react';
import {
  Paper,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  ButtonBase,
  Container,
} from '@material-ui/core';
import MtaContext from './MtaContext';

interface IMtaCardProps {
  mtaNum: number;
  mtaName: string;
  mtaDate: string;
  mtaTotal: number;
}

const MtaCard: React.FunctionComponent<IMtaCardProps> = (props) => {
  const { selectedCard, setSelectedCard } = useContext(MtaContext);
  const { mtaNum, mtaName, mtaDate, mtaTotal } = props;

  const selectedStyle = { width: '100%', background: '#3F51B5', color: 'white' };
  const nonselected = { width: '100%' };

  return (
    <div>
      <Container style={{ width: '100%' }}>
        <ButtonBase style={{ width: '100%' }} onClick={() => setSelectedCard(mtaNum)}>
          <Card style={selectedCard === mtaNum ? selectedStyle : nonselected}>
            <CardHeader
              titleTypographyProps={{ variant: 'h6' }}
              title={mtaName}
              subheader={
                <p style={selectedCard === mtaNum ? selectedStyle : nonselected}>{mtaDate}</p>
              }
            ></CardHeader>
            <CardContent>Total:{' ' + mtaTotal}$</CardContent>
          </Card>
        </ButtonBase>
      </Container>
    </div>
  );
};

export default MtaCard;

import { useState, useReducer, useEffect, memo } from 'react';
import { Button, Grid, Box, CircularProgress } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import InputWrapper from '../../../../../../inputs/InputWrapper';
import { MESSAGE_TYPES } from '../../../../../../../../api/messageTypes';
import { callWs } from '../../../../../../../../api/websocket';
import * as customToast from '../../../../../../../toast/customToast';
import { formatValueFrom, formatValueToSave } from '../../../../../../../../utils/valueFormatter';
import {
  handleSubmitKeyBinding,
  rulesFromDescription,
} from '../../../../../../../../utils/formUtils';
import RulesParser from '../../../../../../../rule-parser/RulesParser';
import { hasPopupVisualRule } from '../../../../../../../../utils/utils';

const ExpandedForm = (props) => {
  const {
    expandedInputFields,
    policyId,
    name,
    insuredEntityDescription,
    onPopup,
    setRowData,
    insuredEntities,
    setInsuredEntities,
    rowIndex,
    rowData,
  } = props;
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const {
    mutateAsync: updateInsuredEntity,
    isLoading: isInsuredEntityUpdateLoading,
  } = useMutation(MESSAGE_TYPES.INSURED_ENTITY_UPDATE, (data: any) =>
    callWs(MESSAGE_TYPES.INSURED_ENTITY_UPDATE, data),
  );

  const [defaultValues] = useState(rowData);

  const [insuredEntityData, setInsuredEntityData] = useState([...expandedInputFields]);

  const { control, handleSubmit, errors, getValues } = useForm({ defaultValues });
  const _onSubmit = async (data) => {
    try {
      const newRowData = { ...rowData, ...data };
      const properties = {};

      insuredEntityDescription.data
        .filter((entity) => entity.showOnExpand)
        .filter((entity) => !entity.hidden)
        .forEach((entity) => {
          properties[entity.name] = formatValueToSave(data[entity.name], entity);
        });

      const updated: any = await updateInsuredEntity({
        id: newRowData.id,
        policy_id: policyId,
        entity_type: name,
        properties: {
          ...properties,
        },
      });
      const updatedProperties = {};
      insuredEntityData.forEach((entity) => {
        updatedProperties[entity.name] = formatValueFrom(updated.properties[entity.name], entity);
      });
      setRowData({ ...newRowData, ...updatedProperties });
      setInsuredEntities({
        ...insuredEntities,
        [name]: [
          ...insuredEntities[name]?.slice(0, rowIndex),
          { ...newRowData },
          ...insuredEntities[name]?.slice(rowIndex + 1, insuredEntities[name].length),
        ],
      });
    } catch (err) {
      customToast.error(
        err.messageCode,
        {
          id: 'insured-entity-update-error',
        },
        err.messages,
      );
    }
  };

  const _evaluateRules = (rowData, popup = true) => {
    try {
      const changedInsuredEntityData = insuredEntityData.map((entity) => {
        if (entity.visualRules && entity.visualRules.length > 0) {
          const popupRule = entity?.visualRules?.find(
            (visualRule) => visualRule.actionTrue === 'popup' || visualRule.actionFalse === 'popup',
          );
          new RulesParser(
            entity.visualRules,
            {
              entity: rowData,
              field: entity,
            },
            popup
              ? {
                  handler: onPopup,
                  elements: insuredEntityDescription.data,
                  key: 'name',
                  currentValue: popupRule
                    ? rowData[popupRule.popUpTargetProperty.split('.')[1]]
                    : undefined,
                  submit: async (value) => {
                    const popupTargetProperty = popupRule.popUpTargetProperty.split('.')[1];
                    try {
                      if (popupRule) {
                        const popupEntity = insuredEntityDescription.data.find(
                          (data) => data.name === popupTargetProperty,
                        );
                        const updatedProperties = {
                          [popupTargetProperty]: formatValueToSave(value, popupEntity),
                        };
                        await updateInsuredEntity({
                          id: rowData.id,
                          policy_id: policyId,
                          entity_type: name,
                          properties: updatedProperties,
                        });

                        setRowData({ ...rowData, ...updatedProperties });
                        setInsuredEntities({
                          ...insuredEntities,
                          [name]: [
                            ...insuredEntities[name]?.slice(0, rowIndex),
                            { ...rowData, ...updatedProperties },
                            ...insuredEntities[name]?.slice(
                              rowIndex + 1,
                              insuredEntities[name].length,
                            ),
                          ],
                        });
                        forceUpdate();
                      }
                    } catch (err) {
                      customToast.error(
                        err.messageCode,
                        {
                          id: 'insured-entity-update-error',
                        },
                        err.messages,
                      );
                    }
                  },
                }
              : undefined,
          ).evaluateRules();
        }

        return entity;
      });

      setInsuredEntityData(changedInsuredEntityData);
    } catch (err) {
      console.error('Evaluate rules error', err);
    }
  };

  useEffect(() => {
    _evaluateRules({ ...rowData, ...getValues() }, false);
  }, [expandedInputFields]);

  return (
    <Box p={2}>
      <form onKeyDown={(e) => handleSubmitKeyBinding(e, handleSubmit(_onSubmit))}>
        <Grid container item spacing={2} direction="column">
          <Grid container item spacing={6}>
            {insuredEntityData.map((element) => {
              return (
                !element.hidden && (
                  <Grid key={element.name} item xs={12} md={2}>
                    <Controller
                      control={control}
                      name={element.name}
                      render={({ onChange, value }) => (
                        <InputWrapper
                          properties={element}
                          value={value}
                          onChange={onChange}
                          evaluateRules={() => {
                            _evaluateRules(
                              { ...rowData, ...getValues() },
                              hasPopupVisualRule(element.visualRules),
                            );
                          }}
                          error={!!errors?.[element.name]?.message}
                          helperText={errors?.[element.name]?.message}
                        />
                      )}
                      rules={rulesFromDescription(element)}
                    />
                  </Grid>
                )
              );
            })}
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="text"
              onClick={handleSubmit(_onSubmit)}
              disabled={isInsuredEntityUpdateLoading}
            >
              {isInsuredEntityUpdateLoading ? <CircularProgress size={24} /> : 'Save'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default memo(ExpandedForm);

import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';

export interface QuestionOption {
  label: string;
  value: any;
}

export interface OptionQuestionProps {
  questionName: string;
  questionLabel: string;
  questionOptions: QuestionOption[];
  onChange?: any;
  value?: any;
}

const OptionQuestion: React.FC<OptionQuestionProps> = (props) => {
  const { questionName, questionLabel, questionOptions, onChange, value } = props;

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{questionLabel}</FormLabel>
      <RadioGroup
        row
        aria-label="question"
        name={questionName}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      >
        {questionOptions.map((questionOption: QuestionOption) => (
          <FormControlLabel
            key={questionOption.label}
            label={questionOption.label}
            value={questionOption.value}
            control={<Radio color="primary" />}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default OptionQuestion;

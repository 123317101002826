import { useEffect, useState } from 'react';

import {
  FormControlLabel,
  Switch,
  TextField,
  Grid,
  Button,
  Typography,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { Autocomplete } from '@material-ui/lab';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { useConfirm } from 'material-ui-confirm';

import { USState } from '../../../../../enums/USState';
import ClientsSearch from '../../../clients-dialog/search/ClientsSearch';
import { handleSubmitKeyBinding } from '../../../../../../utils/formUtils';

export interface InsuredPersonFormProps {
  saveInsuredPerson: (data: any, forceCreateNewClient?: boolean) => Promise<string>;
  handleAdd: (data: any) => Promise<void>;
  handleRemove: (id: string) => Promise<void>;
  insuredPerson?: any;
  isSaveLoading: boolean;
  setIsPolicyChanged: any;
}

const InsuredPersonForm: React.FC<InsuredPersonFormProps> = (props) => {
  const {
    saveInsuredPerson,
    insuredPerson,
    handleAdd,
    handleRemove,
    isSaveLoading,
    setIsPolicyChanged,
  } = props;
  const { control, watch, reset, getValues, errors, handleSubmit, formState } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      isCompany: false,
      companyName: null,
      hasCareOfName: false,
      firstName: null,
      lastName: null,
      contactEmail: null,
      payersEmail: null,
      primaryPhone: null,
      addressLine1: null,
      addressLine2: null,
      country: 'US',
      state: null,
      city: null,
      zip: null,
    },
  });

  const [filterRows, setFilterRows] = useState<any>({});
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);

  const [existedPersonId, setExistedPersonId] = useState<string | undefined>();
  const [isCreatingNewClient, setIsCreatingNewClient] = useState(false);
  const confirm = useConfirm();

  const _resetDefaultValues = () => {
    reset({
      isCompany: insuredPerson?.isCompany ?? false,
      companyName: insuredPerson?.companyName ?? null,
      hasCareOfName: insuredPerson?.hasCareOfName ?? false,
      firstName: insuredPerson?.firstName ?? null,
      lastName: insuredPerson?.lastName ?? null,
      contactEmail: insuredPerson?.contactEmail ?? null,
      payersEmail: insuredPerson?.payersEmail ?? null,
      primaryPhone: insuredPerson?.primaryPhone ?? null,
      addressLine1: insuredPerson?.addressLine1 ?? null,
      addressLine2: insuredPerson?.addressLine2 ?? null,
      country: insuredPerson?.country ?? 'US',
      state: insuredPerson?.state ?? null,
      city: insuredPerson?.city ?? null,
      zip: insuredPerson?.zip ?? null,
    });
  };

  useEffect(() => {
    if (insuredPerson) {
      _resetDefaultValues();
      setExistedPersonId(insuredPerson.id);
    }
  }, [insuredPerson, reset]);

  useEffect(() => {
    setIsPolicyChanged(formState.isDirty);
  }, [formState.isDirty]);

  const { isCompany, hasCareOfName } = watch(['isCompany', 'hasCareOfName']);

  const _searchClients = () => {
    setFilterRows({
      firstName: getValues('firstName') ?? '',
      lastName: getValues('lastName') ?? '',
      companyName: getValues('companyName') ?? '',
    });
    setIsSearchExpanded(true);
  };
  const _addExistedInsuredPerson = async (data) => {
    await handleAdd(data);
    if (existedPersonId) {
      await handleRemove(existedPersonId);
    }
    reset({
      ...data,
    });
    setFilterRows({});
    setExistedPersonId(data.id);
  };

  const _saveInsuredPerson = async (data) => {
    if (existedPersonId && !isCreatingNewClient) {
      await confirm({
        confirmationText: 'Update Client',
        description:
          'You are going to update the details of this client. If the client exists in other policies their details will change as well. If you want to create a new client, select the "Save as new client" option.',
      });
    }

    if (existedPersonId && isCreatingNewClient) {
      await handleRemove(existedPersonId);
    }

    const id = await saveInsuredPerson(data, isCreatingNewClient);
    setExistedPersonId(id);
    setFilterRows({});
    setIsCreatingNewClient(false);
  };

  return (
    <>
      <Grid container spacing={5} style={{ margin: 0 }} direction="column">
        <form
          style={{ padding: '1.25rem' }}
          onKeyDown={(e) =>
            handleSubmitKeyBinding(e, !isSaveLoading ? handleSubmit(_saveInsuredPerson) : () => {})
          }
        >
          <Grid container item xs={12} spacing={6}>
            <Grid item xs={6} md={2} style={{ alignSelf: 'center' }}>
              <Controller
                render={({ onChange, value }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        onChange={(e) => onChange(e.target.checked)}
                        checked={value}
                      />
                    }
                    label="Is company?"
                  />
                )}
                control={control}
                name="isCompany"
                defaultValue={false}
              />
            </Grid>
            {isCompany && (
              <Grid item xs={6} md={4}>
                <Controller
                  render={({ onChange, value }) => (
                    <TextField
                      required={isCompany}
                      fullWidth
                      error={errors.companyName !== undefined}
                      helperText={errors.companyName?.message}
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="Company Name"
                      value={value}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                      onBlur={() => {
                        _searchClients();
                      }}
                    />
                  )}
                  control={control}
                  name="companyName"
                  rules={{
                    required: {
                      value: isCompany,
                      message: 'Company Name is required',
                    },
                  }}
                />
              </Grid>
            )}
          </Grid>
          <Grid container item xs={12} spacing={6}>
            {isCompany && (
              <Grid item xs={12} md={2} style={{ alignSelf: 'center' }}>
                <Controller
                  render={({ onChange, value }) => (
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          onChange={(e) => onChange(e.target.checked)}
                          checked={value}
                        />
                      }
                      label="Does this policy have a care of?"
                    />
                  )}
                  control={control}
                  name="hasCareOfName"
                />
              </Grid>
            )}
            {((isCompany && hasCareOfName) || !isCompany) && (
              <>
                <Grid item xs={12} md={2}>
                  <Controller
                    render={({ onChange, value }) => (
                      <TextField
                        required={(isCompany && hasCareOfName) || !isCompany}
                        fullWidth
                        error={errors.firstName !== undefined}
                        helperText={errors.firstName?.message}
                        InputLabelProps={{ shrink: true }}
                        type="text"
                        label="First Name"
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                        onBlur={() => {
                          if (getValues('lastName')) {
                            _searchClients();
                          }
                        }}
                      />
                    )}
                    control={control}
                    name="firstName"
                    rules={{
                      required: {
                        value: (isCompany && hasCareOfName) || !isCompany,
                        message: 'First Name is required',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Controller
                    render={({ onChange, value }) => (
                      <TextField
                        required={(isCompany && hasCareOfName) || !isCompany}
                        fullWidth
                        error={errors.lastName !== undefined}
                        helperText={errors.lastName?.message}
                        InputLabelProps={{ shrink: true }}
                        type="text"
                        label="Last Name"
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                        onBlur={() => {
                          if (getValues('firstName')) {
                            _searchClients();
                          }
                        }}
                      />
                    )}
                    control={control}
                    name="lastName"
                    rules={{
                      required: {
                        value: (isCompany && hasCareOfName) || !isCompany,
                        message: 'Last Name is required',
                      },
                    }}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} md={2}>
              <Controller
                as={
                  <TextField
                    required
                    fullWidth
                    error={errors.primaryPhone !== undefined}
                    helperText={errors.primaryPhone?.message}
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label="Primary Phone"
                  />
                }
                control={control}
                name="primaryPhone"
                rules={{
                  required: {
                    value: true,
                    message: 'Primary Phone is required',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Controller
                as={
                  <TextField
                    required
                    fullWidth
                    error={errors.contactEmail !== undefined}
                    helperText={errors.contactEmail?.message}
                    InputLabelProps={{ shrink: true }}
                    type="email"
                    label="Contact E-mail"
                  />
                }
                control={control}
                name="contactEmail"
                rules={{
                  required: {
                    value: true,
                    message: 'Contact E-mail is required',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Controller
                as={
                  <TextField
                    fullWidth
                    error={errors.payersEmail !== undefined}
                    helperText={errors.payersEmail?.message}
                    InputLabelProps={{ shrink: true }}
                    type="email"
                    label="Payer's E-mail"
                  />
                }
                control={control}
                name="payersEmail"
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={6}>
            <Grid item xs={6} md>
              <Controller
                as={
                  <TextField
                    required
                    fullWidth
                    disabled
                    error={errors.country !== undefined}
                    helperText={errors.country?.message}
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label="Country"
                  />
                }
                control={control}
                name="country"
                rules={{
                  required: {
                    value: true,
                    message: 'Country is required',
                  },
                }}
              />
            </Grid>
            <Grid item xs={6} md>
              <Controller
                as={
                  <TextField
                    required
                    fullWidth
                    error={errors.addressLine1 !== undefined}
                    helperText={errors.addressLine1?.message}
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label="Address Line 1"
                  />
                }
                control={control}
                name="addressLine1"
                rules={{
                  required: {
                    value: true,
                    message: 'Address Line 1 is required',
                  },
                }}
              />
            </Grid>
            <Grid item xs={6} md>
              <Controller
                as={
                  <TextField
                    fullWidth
                    error={errors.addressLine2 !== undefined}
                    helperText={errors.addressLine2?.message}
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label="Address Line 2"
                  />
                }
                control={control}
                name="addressLine2"
              />
            </Grid>
            <Grid item xs={6} md>
              <Controller
                as={
                  <TextField
                    required
                    fullWidth
                    error={errors.city !== undefined}
                    helperText={errors.city?.message}
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label="Town/City"
                  />
                }
                control={control}
                name="city"
                rules={{
                  required: {
                    value: true,
                    message: 'Town/City is required',
                  },
                }}
              />
            </Grid>
            <Grid item xs={6} md>
              <Controller
                render={({ onChange, value }) => (
                  <Autocomplete
                    options={Object.keys(USState)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        error={errors.state !== undefined}
                        helperText={errors.state?.message}
                        InputLabelProps={{ shrink: true }}
                        label="State"
                        margin="dense"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'disabled',
                        }}
                      />
                    )}
                    fullWidth
                    clearOnEscape
                    autoHighlight
                    onChange={(_, data) => {
                      onChange(data);
                    }}
                    value={value}
                  />
                )}
                control={control}
                name="state"
                rules={{
                  required: {
                    value: true,
                    message: 'State is required',
                  },
                }}
              />
            </Grid>
            <Grid item xs={6} md>
              <Controller
                as={
                  <TextField
                    required
                    fullWidth
                    error={errors.zip !== undefined}
                    helperText={errors.zip?.message}
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label="ZIP Code"
                  />
                }
                control={control}
                name="zip"
                rules={{
                  required: {
                    value: true,
                    message: 'ZIP Code is required',
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={6}>
            {!existedPersonId && (
              <Grid item xs={4} md>
                <Button
                  color="primary"
                  onClick={!isSaveLoading ? handleSubmit(_saveInsuredPerson) : undefined}
                  disabled={isSaveLoading}
                >
                  {isSaveLoading ? <CircularProgress size={24} /> : 'Save Client'}
                </Button>
              </Grid>
            )}
            {existedPersonId && (
              <>
                <Grid item xs={12} md={2}>
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        onChange={(e) => setIsCreatingNewClient(e.target.checked)}
                        checked={isCreatingNewClient}
                      />
                    }
                    labelPlacement="start"
                    label="Save as new client"
                  />
                </Grid>
                <Grid item xs={12} md>
                  <Button
                    color="primary"
                    onClick={!isSaveLoading ? handleSubmit(_saveInsuredPerson) : undefined}
                    disabled={isSaveLoading}
                  >
                    {isSaveLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      `${isCreatingNewClient ? 'Save' : 'Update'} Client`
                    )}
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </form>
        <Grid item style={{ width: '97%' }}>
          <Accordion
            expanded={isSearchExpanded}
            onChange={(_, isExpanded) => setIsSearchExpanded(isExpanded)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="search-clients">
              <Typography>Search existing clients</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ClientsSearch
                filterRows={filterRows}
                setFilterRows={setFilterRows}
                handleAdd={_addExistedInsuredPerson}
                existedIds={existedPersonId ? [existedPersonId] : []}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </>
  );
};

export default InsuredPersonForm;

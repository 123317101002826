import { useLayoutEffect, useContext } from 'react';

import { LayoutContext } from '../providers/LayoutProvider';

export const usePageTitle = (headerTitle: string) => {
  const { layoutData, setLayoutData } = useContext(LayoutContext);

  useLayoutEffect(() => {
    setLayoutData({ ...layoutData, headerTitle });
  }, []);
};

import { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import InputWrapper from '../../../inputs/InputWrapper';
import { MESSAGE_TYPES } from '../../../../../api/messageTypes';
import { callWs } from '../../../../../api/websocket';
import * as customToast from '../../../../toast/customToast';
import { groupBy, hasPopupVisualRule } from '../../../../../utils/utils';
import RulesParser from '../../../../rule-parser/RulesParser';

export interface PolicyQuestionsProps {
  questionDescriptions: any;
  questionAnswers: any;
  policyData: any;
  onPopup: any;
  fetchPolicy: any;
}

const PolicyQuestions: React.FC<PolicyQuestionsProps> = (props) => {
  const {
    questionDescriptions: questionDescriptionsProps,
    questionAnswers,
    policyData,
    onPopup,
    fetchPolicy,
  } = props;

  const [questionDescriptions, setQuestionDescriptions] = useState([...questionDescriptionsProps]);

  const { control, reset, errors, getValues } = useForm({
    mode: 'onChange',
    defaultValues: {
      ...(questionAnswers
        ?.map((questionAnswer: any) => ({
          [questionAnswer.questionId]: questionAnswer.value,
        }))
        ?.reduce((a, b) => Object.assign(a, b), {}) ?? {}),
    },
  });

  const { mutateAsync: updateQuestionAnswer } = useMutation(
    MESSAGE_TYPES.QUESTION_ANSWERS_UPDATE,
    (data: any) => callWs(MESSAGE_TYPES.QUESTION_ANSWERS_UPDATE, data),
  );

  useEffect(() => {
    const answers = {
      ...(questionAnswers
        ?.map((questionAnswer: any) => ({
          [questionAnswer.questionId]: questionAnswer.value,
        }))
        ?.reduce((a, b) => Object.assign(a, b), {}) ?? {}),
    };
    reset(answers);

    _evaluateRules(answers, false);
  }, []);

  const _evaluateRules = (mappedQuestionAnswers, popupShow = true) => {
    const changedQuestionDescriptions = questionDescriptions.map((questionDescription) => {
      if (questionDescription.visualRules && questionDescription.visualRules.length > 0) {
        const popupRule = questionDescription?.visualRules?.find(
          (visualRule) => visualRule.actionTrue === 'popup' || visualRule.actionFalse === 'popup',
        );
        const ruleResult = new RulesParser(
          questionDescription.visualRules,
          {
            policyData: { ...policyData, uWQuestions: mappedQuestionAnswers },
            field: questionDescription,
          },
          popupShow
            ? {
                handler: onPopup,
                elements: questionDescriptionsProps,
                key: 'id',
                currentValue: popupRule
                  ? getValues()[popupRule.popUpTargetProperty.split('.')[1]]
                  : undefined,
                submit: async (value) => {
                  try {
                    if (popupRule) {
                      const questionAnswerId = questionAnswers.find(
                        (questionAnswer) =>
                          questionAnswer.questionId === popupRule.popUpTargetProperty.split('.')[1],
                      ).id;

                      await updateQuestionAnswer({
                        id: questionAnswerId,
                        value: value,
                      });

                      const answers = {
                        ...([
                          ...questionAnswers.filter(
                            (questionAnswer) => questionAnswer.id !== questionAnswerId,
                          ),
                          {
                            id: questionAnswerId,
                            questionId: popupRule?.popUpTargetProperty,
                            value: value,
                          },
                        ]
                          ?.map((questionAnswer: any) => ({
                            [questionAnswer.questionId]: questionAnswer.value,
                          }))
                          ?.reduce((a, b) => Object.assign(a, b), {}) ?? {}),
                      };

                      reset(answers);
                    }
                  } catch (err) {
                    customToast.error(err.messageCode);
                  }
                },
              }
            : undefined,
        ).evaluateRules();
      }

      return questionDescription;
    });

    setQuestionDescriptions(changedQuestionDescriptions);
  };

  const _renderQuestion = (questionDescription) =>
    !questionDescription.hidden && (
      <Controller
        key={questionDescription.id}
        render={({ onChange, value }) => {
          return (
            <InputWrapper
              properties={{
                name: questionDescription.id,
                label: questionDescription.question,
                type: questionDescription.data.type,
                presentation: questionDescription.presentation,
                validation: {
                  front: {
                    required: questionDescription?.data?.validation?.front?.required,
                    min: questionDescription?.data?.validation?.front?.min,
                    max: questionDescription?.data?.validation?.front?.max,
                  },
                },

                values: questionDescription.data.values,
                defaultValue: questionDescription.data.default,
              }}
              value={value}
              evaluateRules={() => {
                _evaluateRules(getValues(), hasPopupVisualRule(questionDescription.visualRules));
              }}
              onChange={async (newValue) => {
                try {
                  onChange(newValue);

                  await updateQuestionAnswer({
                    id: questionAnswers.find(
                      (questionAnswer) => questionAnswer.questionId === questionDescription.id,
                    ).id,
                    value: newValue,
                  });
                  fetchPolicy();
                } catch (err) {
                  onChange(value);
                  customToast.error(err.messageCode);
                }
              }}
              error={!!errors?.[questionDescription.id]?.message}
              helperText={errors?.[questionDescription.id]?.message}
            />
          );
        }}
        control={control}
        name={`${questionDescription.id}`}
        defaultValue={null}
        rules={{
          required: {
            value: questionDescription?.data?.validation?.front?.required ?? false,
            message: 'Question is required',
          },
          max: {
            value: questionDescription?.data?.validation?.front?.max,
            message: 'Value must not be greater than 100',
          },
          min: {
            value: questionDescription?.data?.validation?.front?.min,
            message: 'Value must not be less than 0',
          },
        }}
      />
    );

  const _renderQuestions = () => {
    const grouppedQuestionDescriptions = groupBy(
      questionDescriptions.filter((questionDescription) => questionDescription.groupId),
      'groupId',
    );

    return (
      <>
        {Object.keys(grouppedQuestionDescriptions).map((groupId) => (
          <Grid container item xs={12} spacing={4} key={groupId}>
            {grouppedQuestionDescriptions[groupId].map((questionDescription) => (
              <Grid item xs={2} key={questionDescription.id}>
                {_renderQuestion(questionDescription)}
              </Grid>
            ))}
          </Grid>
        ))}
        {questionDescriptions
          .filter((questionDescription) => !questionDescription.groupId)
          .map((questionDescription) => (
            <Grid item xs={12} key={questionDescription.id}>
              {_renderQuestion(questionDescription)}
            </Grid>
          ))}
      </>
    );
  };

  return (
    <>
      <Grid container spacing={5} style={{ margin: 0 }}>
        <Grid container item xs spacing={2}>
          {_renderQuestions()}
        </Grid>
      </Grid>
    </>
  );
};

export default PolicyQuestions;

import { useContext } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import PolicyCreatePage from '../../pages/policy/create/PolicyCreatePage';
import PolicyEditPage from '../../pages/policy/edit/PolicyEditPage';
import PolicyListPage from '../../pages/policy/list/PolicyListPage';
import DashboardPage from '../../pages/dashboard/DashboardPage';
import LoginPage from '../../pages/login/LoginPage';
import { AuthContext } from '../../shared/providers/AuthProvider';
import Sidebar from '../sidebar/Sidebar';
import InvoicesList from '../../pages/invoicesList/InvoicesList';

function AppRouter() {
  const {
    authenticationData: { isAuthenticated },
  } = useContext(AuthContext);

  return (
    <Router>
      <Sidebar />

      <Switch>
        {!isAuthenticated && (
          <Switch>
            <Route path="/login">
              <LoginPage />
            </Route>
            <Redirect from="/" to="/login" />
          </Switch>
        )}
        {isAuthenticated && (
          <Switch>
            <Route exact path="/dashboard">
              <DashboardPage />
            </Route>
            <Route exact path="/invoicesList">
              <InvoicesList />
            </Route>
            <Route exact path="/policy/list">
              <PolicyListPage />
            </Route>
            <Route exact path="/policy/create">
              <PolicyCreatePage />
            </Route>
            <Route exact path="/policy/edit/:id">
              <PolicyEditPage />
            </Route>
            <Redirect from="/" to="/dashboard" />
          </Switch>
        )}
      </Switch>
    </Router>
  );
}

export default AppRouter;

import { Drawer, makeStyles, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Dashboard as DashboardIcon, Policy as PolicyIcon, ListAlt } from '@material-ui/icons';
import { Link, useLocation } from 'react-router-dom';

import { useSidebar } from '../../shared/hooks/use-sidebar';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

const Sidebar = () => {
  const classes = useStyles();

  const [isOpened, toggleSidebar] = useSidebar();

  const { pathname: currentPathName } = useLocation();

  const isSelected = (path) => {
    return currentPathName.includes(path);
  };

  return (
    <Drawer anchor="left" open={isOpened} onClose={toggleSidebar(false)}>
      <div
        className={classes.list}
        role="presentation"
        onClick={toggleSidebar(false)}
        onKeyDown={toggleSidebar(false)}
      >
        <List>
          <ListItem button component={Link} to={'/dashboard'} selected={isSelected('dashboard')}>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
          <ListItem button component={Link} to={'/policy/list'} selected={isSelected('policy')}>
            <ListItemIcon>
              <PolicyIcon />
            </ListItemIcon>
            <ListItemText primary="Policy List" />
          </ListItem>
          <ListItem button component={Link} to={'/invoicesList'} selected={isSelected('invoice')}>
            <ListItemIcon>
              <ListAlt />
            </ListItemIcon>
            <ListItemText primary="Post Invoices List" />
          </ListItem>
        </List>
      </div>
    </Drawer>
  );
};

export default Sidebar;

import React, { memo, useEffect, useState } from 'react';
import MaterialTable from '@material-table/core';
import { USState } from '../../../../enums/USState';
import { validateInput } from '../../../../../utils/validationUtils';
import InputWrapper from '../../../inputs/InputWrapper';
import { Controller, useForm } from 'react-hook-form';
import { rulesFromDescription } from '../../../../../utils/formUtils';
import {
  ChevronRight as ChevronRightIcon,
  Clear as ClearIcon,
  Check as CheckIcon,
  Search as SearchIcon,
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons';
import { useMutation } from 'react-query';
import { MESSAGE_TYPES } from '../../../../../api/messageTypes';
import { callWs } from '../../../../../api/websocket';
import * as customToast from '../../../../../shared/toast/customToast';
import { useConfirm } from 'material-ui-confirm';

const inputDescriptions = [
  {
    name: 'addressLine1',
    label: 'Address Line 1',
    type: 'text',
    presentation: 'text',
    validation: {
      front: { required: true },
    },
  },
  {
    name: 'addressLine2',
    label: 'Address Line 2',
    type: 'text',
    presentation: 'text',
    validation: {
      front: { required: false },
    },
  },
  {
    name: 'city',
    label: 'City',
    type: 'text',
    presentation: 'text',
    validation: {
      front: { required: true },
    },
  },
  {
    name: 'state',
    label: 'State',
    type: 'enum',
    presentation: 'autocomplete',
    validation: {
      front: { required: true },
    },
    values: Object.keys(USState),
  },
  {
    name: 'zip',
    label: 'Zip Code',
    type: 'text',
    presentation: 'text',
    validation: {
      front: { required: true },
    },
  },
  {
    name: 'acres',
    label: 'Acres',
    type: 'float',
    presentation: 'number',
    validation: {
      front: { required: true, min: 0 },
    },
  },
  {
    name: 'isMain',
    label: 'Main',
    type: 'boolean',
    presentation: 'checkbox',
    validation: {
      front: { required: false },
    },
  },
];

const PolicyAddresses: React.FC<any> = (props) => {
  const { policy, fetchPolicy } = props;
  const confirm = useConfirm();

  const [isPolicyAddressSaveLoading, setIsPolicyAddressSaveLoading] = useState(false);
  // useEffect(() => {
  //   fetchPolicy();
  // }, []);
  const [rows, setRows] = useState([...policy.addresses]);

  useEffect(() => {
    if (policy.addresses.length > 0) {
      const addRow = rows.find((row) => !row.id);
      _clearInputRow();

      setRows([addRow, ...policy.addresses]);
    } else {
      const addRow = rows.find((row) => !row.id);
      _clearInputRow();

      setRows([addRow, ...policy.addresses]);
    }
  }, [policy.addresses]);

  useEffect(() => {
    if (rows.filter((address) => !address.id).length === 0) {
      const inputRow = { tableData: { id: 0 } };
      inputDescriptions.forEach((element) => {
        inputRow[element.name] = element.type === 'boolean' ? false : null;
      });

      setRows([inputRow, ...rows]);
    }
  }, []);

  const { control, watch, getValues, reset, handleSubmit, trigger, errors } = useForm<any>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      ...inputDescriptions
        .map((inputDescription) => ({
          [inputDescription.name]: inputDescription.type === 'boolean' ? false : null,
        }))
        .reduce((acc, next) => Object.assign(acc, next), {}),
    },
  });

  const { mutateAsync: createPolicyAddress } = useMutation<any, any>(
    MESSAGE_TYPES.POLICY_ADDRESS_CREATE,
    (data: any) => callWs(MESSAGE_TYPES.POLICY_ADDRESS_CREATE, data),
  );

  const { mutateAsync: updatePolicyAddress } = useMutation<any, any>(
    MESSAGE_TYPES.POLICY_ADDRESS_UPDATE,
    (data: any) => callWs(MESSAGE_TYPES.POLICY_ADDRESS_UPDATE, data),
  );

  const { mutateAsync: deletePolicyAddress } = useMutation<any, any>(
    MESSAGE_TYPES.POLICY_ADDRESS_DELETE,
    (data: any) => callWs(MESSAGE_TYPES.POLICY_ADDRESS_DELETE, data),
  );

  const _clearInputRow = () => {
    reset();
  };

  const _handleInsert = async (data) => {
    try {
      const newlyCreatedRow: any = await createPolicyAddress({ policyId: policy.id, ...data });

      // setRows([...rows, newlyCreatedRow]);
      fetchPolicy();
      _clearInputRow();
    } catch (error) {
      customToast.error(error.messageCode, {
        toastId: `create-policy-address-failed`,
      });
    }
  };

  const _handleUpdate = async (data) => {
    try {
      const newlyUpdatedRow: any = await updatePolicyAddress({ ...data });
      const updatedIndex = rows.findIndex((row: any) => row.id === data.id);
      setRows([
        ...rows.slice(0, updatedIndex),
        newlyUpdatedRow,
        ...rows.slice(updatedIndex + 1, rows.length),
      ]);
      fetchPolicy();
    } catch (error) {
      customToast.error(error.messageCode, {
        toastId: `update-policy-address-failed`,
      });
    }
  };

  const _handleDelete = async (data) => {
    await confirm({
      confirmationText: 'Delete',
      title: 'Delete Insured Location',
      description:
        'Deletion of an insured location may cause errors if it is assigned to an insured entity. Please make sure that the location you are trying to delete is not associated with any insured entities.',
      // allowClose: false,
    })
      .then(() => {})
      .catch(() => {
        throw new Error('Cancelled');
      });
    try {
      await deletePolicyAddress({ id: data.id } as any);
      // const newRows = [...rows.filter((row) => row.id !== data.id)];
      // const rowsGreaterThanPosition = newRows;
      //   .filter((row) => row.id && row.position > data.position)
      //   .map((row) => ({
      //     ...row,
      //     position: row.position - 1,
      //   }));

      // setRows([
      //   newRows.find((row) => !row.id),
      //   ...rowsGreaterThanPosition,
      //   ...newRows.filter((row) => row.id && row.position < data.position),
      // ]);
      fetchPolicy();
      _clearInputRow();

      // setRows([newRows.find((row) => !row.id), ...newRows.filter((row) => row.id)]);
    } catch (err) {
      console.log(err);
      customToast.error(err.messageCode, {
        toastId: `delete-policy-address-failed`,
      });
    }
  };

  const columns: any[] = [
    { title: '', field: 'id', hidden: true },
    { title: 'Position', field: 'position' },
    ...inputDescriptions.map((element) => ({
      title: element.label + (element?.validation?.front?.required ? ' *' : ''),
      field: element.name,
      validate: (rowData) => validateInput(rowData, element),
      editComponent: ({ value, onChange, error, helperText }) => {
        return (
          <InputWrapper
            properties={element}
            value={value}
            onChange={onChange}
            error={error}
            displayLabel={false}
            helperText={helperText}
          />
        );
      },
      render: (rowData) => {
        if (rowData.id) {
          return (
            <InputWrapper
              properties={element}
              value={rowData[element.name]}
              onChange={() => {}}
              displayMode="read"
              displayLabel={false}
            />
          );
        } else {
          return (
            <Controller
              render={({ onChange, value }) => (
                <InputWrapper
                  properties={element}
                  displayLabel={false}
                  error={!!errors[element.name]?.message}
                  helperText={errors[element.name]?.message}
                  value={value}
                  onChange={(value) => {
                    onChange(value);
                    trigger(element.name);
                  }}
                />
              )}
              control={control}
              name={element.name}
              rules={rulesFromDescription(element)}
            />
          );
        }
      },
    })),
  ];

  return (
    <>
      <MaterialTable
        style={{ width: '100%' }}
        columns={columns}
        data={rows}
        title="Policy Addresses"
        actions={[
          (rowData) => ({
            icon: CheckIcon,
            tooltip: 'Save',
            onClick: handleSubmit(async (data) => {
              try {
                setIsPolicyAddressSaveLoading(true);
                await _handleInsert(data);
              } catch (err) {
                console.log(err);
              } finally {
                setIsPolicyAddressSaveLoading(false);
              }
            }),
            hidden: rowData.id || isPolicyAddressSaveLoading,
          }),
          (rowData) => ({
            icon: ClearIcon,
            tooltip: 'Clear',
            onClick: () => {
              _clearInputRow();
            },
            hidden: rowData.id,
          }),
        ]}
        editable={{
          isDeleteHidden: (rowData) => !rowData.id,
          isEditHidden: (rowData) => !rowData.id,
          onRowDelete: (data: any) =>
            new Promise<void>(async (resolve, reject) => {
              try {
                await _handleDelete({ id: data.id, position: data.position });

                resolve();
              } catch (err) {
                resolve();
              }
            }),

          onRowUpdate: (newData: any, oldData: any) =>
            new Promise<void>(async (resolve, reject) => {
              try {
                await _handleUpdate({
                  ...oldData,
                  ...newData,
                });

                resolve();
              } catch (err) {
                console.error(err);

                reject();
              }
            }),
        }}
        options={{
          search: false,
          paging: true,
          pageSize: 10,
          paginationType: 'stepped',
          filtering: false,
          loadingType: 'overlay',
          draggable: false,
          showTitle: false,
          rowStyle: { fontFamily: 'Roboto', fontSize: 14 },
          actionsColumnIndex: -1,
          sorting: false,
        }}
      />
    </>
  );
};

export default memo(PolicyAddresses);

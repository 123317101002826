export const BackendErrors = {
  '-1': 'Server error. Please contact with support.',
  '00000': 'Server error. Please contact with support.',
  '00001': 'Validation error. Please check your input.',
  '00002': 'Validation error. Please check your input.',
  '00003': 'The resource not found.',
  '00007': 'Extra message errors.',
  '00008': 'Cancellation system is currently unavailable.',
  '10001': 'User not found.',
  '10002': 'User authentication failed.',
  '10003': 'Change password failed.',
  '10004': 'Change password validation failed.',
  '10005': 'Forgot password validation failed.',
  '10006': 'Email verification failed.',
  '10007': 'User is not confirmed.',
  '20001': 'Role not found',
  '30001': 'Permission not found.',
  '30002': 'Permission not assigned.',
  '30003': 'Permission attach validation failed.',
  '30004': 'Permission detach validation failed.',
  '40001': 'Product description already exists in editing status.',
  '40002': 'Product description can not be altered. Not in editing status.',
  '50001': 'Product description not found.',
  '50002': 'Product description not in current status',
  '50003': 'Policy can not be altered.',
  '50004': 'Policy not found.',
  '50005': 'Policy cannot be deleted.',
  '50015': 'Policy address can not be deleted because it is in use.',
  '60001': 'Question can not be altered.',
  '60002': 'Question validation failed.',
  '60003': 'Question description not found.',
  '70001': 'Insured entity not found.',
  '80001': 'Insured property validation failed. Please check your input.',
  '90001': 'Insured property cover validation failed. Please check your input.',
  '100001': 'Client with this contact email already exists',
  '100002': 'Client with this primary phone already exists',
  '110001': 'There was an error while sending email.',
  '120001': 'Yoy can only have one main address. Please update your input.',
  '130001': 'Allocated amount cannot exceed the total amount remaining.',
  '140003': 'Invoice cannot be voided due to the payment status.',
  '150001': 'Action permitted as mta is already sent.',
  '150002': 'There is an already open mta so a new one cannot be created.',
  '150003': 'Mta cannot be deleted at this point.',
  '14000': 'No Error',
  '14001': 'We could not find recipient emails',
  '14002': 'Email could not be sent',
  '14003': 'Error while rendering invoice for customer',
  '14004': 'Server error, please contact support',
} as const;

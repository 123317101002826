import { useContext } from 'react';
import { Button, TextField, Grid, Container, Paper, CircularProgress } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';

import useStyles from './LoginPage.styles';
import { callWs } from '../../api/websocket';
import { MESSAGE_TYPES } from '../../api/messageTypes';
import { AuthContext } from '../../shared/providers/AuthProvider';
import { usePageTitle } from '../../shared/hooks/use-page-title';
import * as customToast from '../../shared/toast/customToast';

function LoginPage() {
  const { setAuthenticationData } = useContext(AuthContext);

  usePageTitle('Login');

  const { handleSubmit, control } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const history = useHistory();

  const classes = useStyles();

  const { mutateAsync: sendLogin, isLoading: isLoginLoading } = useMutation('data', (data: any) =>
    callWs(MESSAGE_TYPES.AUTHENTICATE_USER, data),
  );

  const onSubmit = async (data) => {
    try {
      const response: any = await sendLogin({
        username: data.email,
        password: data.password,
      });
      const auth = {
        token: response.access_token,
        permissions: ['ALL'],
      };
      localStorage.setItem('auth', JSON.stringify(auth));
      setAuthenticationData({
        isAuthenticated: true,
        token: response.data,
      });
    } catch (err) {
      customToast.error(err.messageCode, {
        toastId: `login-authentication-failed`,
      });
    }
  };

  return (
    <Paper>
      <Container className={classes.container} maxWidth="xs">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    as={<TextField fullWidth label="Email" size="small" type="email" required />}
                    control={control}
                    rules={{ required: true }}
                    name="email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    as={
                      <TextField fullWidth label="Password" size="small" type="password" required />
                    }
                    control={control}
                    rules={{ required: true }}
                    name="password"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Button
                color="primary"
                fullWidth
                type="submit"
                variant="contained"
                disabled={isLoginLoading}
              >
                {!isLoginLoading ? 'Log in' : <CircularProgress size={24} />}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Paper>
  );
}

export default LoginPage;

import {
  Grid,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  CircularProgress,
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { format } from 'date-fns';
import { KeyboardDatePicker } from '@material-ui/pickers';

import { callWs } from '../../../api/websocket';
import { MESSAGE_TYPES } from '../../../api/messageTypes';
import * as customToast from '../../toast/customToast';

const NonPaymentDialog = (props) => {
  const { open, handleClose, policy, fetchPolicy } = props;

  const { control, getValues, errors } = useForm<any>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      cancelFlat: false,
      cancellationDate: new Date(),
      reason: 17000,
    },
  });

  const {
    mutateAsync: nonPaymentProcess,
    isLoading: isNonPaymentProcessLoading,
  } = useMutation(MESSAGE_TYPES.POLICY_NON_PAYMENT_PROCESS, (data: any) =>
    callWs(MESSAGE_TYPES.POLICY_NON_PAYMENT_PROCESS, data),
  );

  const _handlePolicyCancellation = async (data) => {
    try {
      data.policyId = policy.id;
      data.cancellationDate = format(data.cancellationDate, 'yyyy-MM-dd');
      await nonPaymentProcess(data);
      customToast.success('Policy marked successfully as non pay pending');
      fetchPolicy();
      handleClose();
    } catch (err) {
      customToast.error(err.messageCode, {
        id: 'cancellation-error',
      });
    }
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Send out NOC Document</DialogTitle>
        <DialogContent style={{ overflow: 'hidden' }}>
          <Grid container item spacing={4}>
            <Grid item xs={6} md={6} style={{ display: 'flex', justifyContent: 'center' }}>
              <Controller
                render={({ value, onChange }) => (
                  <KeyboardDatePicker
                    required
                    margin="dense"
                    fullWidth
                    label="Pending Cancellation Date"
                    format="MM/dd/yyyy"
                    name="cancellationDate"
                    autoOk
                    onChange={(data) => {
                      onChange(data);
                    }}
                    value={value}
                  />
                )}
                error={errors.cancellationDate !== undefined}
                name="cancellationDate"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Cancellation Date is required',
                  },
                }}
              />
            </Grid>

            <Grid item md={6} style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                color="primary"
                fullWidth
                variant="contained"
                size="small"
                onClick={() => {
                  const data = getValues();
                  _handlePolicyCancellation(data);
                }}
              >
                {isNonPaymentProcessLoading ? (
                  <CircularProgress size={24} />
                ) : (
                  'Document was sent out'
                )}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NonPaymentDialog;

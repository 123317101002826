import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    width: 250,
    height: 80,
  },
  title: {
    fontSize: 10,
  },
  pos: {
    marginBottom: 12,
  },
  cardAction: {
    display: 'block',
    textAlign: 'initial',
  },
}));

import { toast } from 'react-toastify';
import { BackendErrors } from '../enums/BackendErrors';

const success = (message, options = {}) => {
  toast.success(message, { ...options });
};

const warning = (message, options = {}) => {
  toast.warning(message, { ...options });
};

const info = (message, options = {}) => {
  toast.info(message, { ...options });
};

const error = (messageCode, options = {}, extraErrors = []) => {
  let message = BackendErrors[messageCode];
  if (messageCode === '00007') {
    message = extraErrors.join('\n\n');
  }
  toast.error(message || 'Unexpected error. Please contact with support.', {
    ...options,
  });
};

export { success, warning, info, error };

import { TextField } from '@material-ui/core';
import CurrencyInput from '../currency-input/CurrencyInput';
import { InputWrapperProps } from '../InputWrapper';
import { handleKey } from '../../../../utils/formUtils';
import { decimalCount } from '../../../../utils/utils';

export interface NumberInputProps extends InputWrapperProps {
  value: number;
  onChange: (value: any) => void;
}

const NumberInput: React.FC<NumberInputProps> = (props) => {
  const {
    properties,
    displayMode,
    value,
    onChange,
    afterOnChange,
    evaluateRules,
    onKeyDown,
    displayLabel,
    error,
    helperText,
    disabled,
  } = props;

  if (properties.presentation === 'currency') {
    return (
      <CurrencyInput
        properties={properties}
        value={value}
        onChange={onChange}
        afterOnChange={afterOnChange}
        evaluateRules={evaluateRules}
        onKeyDown={onKeyDown}
        displayMode={displayMode}
        displayLabel={displayLabel}
        error={error}
        helperText={helperText}
        disabled={disabled}
      />
    );
  }

  return displayMode === 'read' ? (
    <span>{value}</span>
  ) : (
    <TextField
      label={displayLabel ? properties.label : undefined}
      fullWidth
      variant="standard"
      type="number"
      margin="dense"
      InputProps={{
        inputProps: {
          min: properties?.validation?.front?.min,
          step: properties?.presentation === 'percentage' ? 0.1 : undefined,
        },
      }}
      InputLabelProps={{ shrink: true }}
      value={value != null ? value : ''}
      onChange={(e) => {
        if (properties?.presentation === 'percentage' && decimalCount(e.target.value) > 1) {
          onChange(parseFloat(e.target.value).toFixed(1));
        } else {
          onChange(e.target.value);
        }
        afterOnChange?.();
      }}
      onBlur={evaluateRules}
      onKeyDown={(e) => (onKeyDown ? onKeyDown(e) : handleKey(e))}
      multiline={properties.presentation === 'textarea'}
      rows={properties.presentation === 'textarea' ? 4 : undefined}
      required={properties?.validation?.front?.required}
      error={error}
      helperText={helperText}
      disabled={disabled}
    />
  );
};

export default NumberInput;

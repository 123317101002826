import {
  Grid,
  Button,
  List,
  Link,
  ListItem,
  ListItemText,
  CircularProgress,
  IconButton,
  ListItemAvatar,
  Avatar,
  ListItemSecondaryAction,
} from '@material-ui/core';
import { Delete as DeleteIcon, Visibility } from '@material-ui/icons';
import { useState, Fragment } from 'react';
import { useMutation } from 'react-query';

import FilePicker from '../../../file-picker/FilePicker';
import { MESSAGE_TYPES } from '../../../../../api/messageTypes';
import { callWs } from '../../../../../api/websocket';
import EmailDialog from '../../../dialogs/email-dialog/EmailDialog';
import * as customToast from './../../../../toast/customToast';

const DocumentsList = (props) => {
  //const files = ['one', 'two', 'three'];
  const [dense, setDense] = useState(false);
  const [secondary, setSecondary] = useState(false);
  const { policy, fetchPolicy } = props;
  const files = policy?.documents;
  const {
    mutateAsync: getDocument,
    isLoading: isDocumentLoading,
  } = useMutation(MESSAGE_TYPES.DOCUMENT_GET, (path: any) =>
    callWs(MESSAGE_TYPES.DOCUMENT_GET, { path }),
  );
  const _fetchDocument = async (path) => {
    try {
      const urlResponse: any = await getDocument(path);
      window.open(urlResponse, '_blank');
    } catch (e) {}
  };

  return (
    <Grid container md={12}>
      <Grid item md={12}>
        {files?.map((childItem) => (
          <List dense key={childItem.name}>
            <ListItem>
              <ListItemText
                primary={childItem.name}
                secondary={secondary ? 'Secondary text' : null}
              />
              <IconButton onClick={() => _fetchDocument(childItem.path)}>
                <Visibility />
              </IconButton>
            </ListItem>
          </List>
        ))}
      </Grid>
    </Grid>
  );
};
export default DocumentsList;

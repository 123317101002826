import { Grid, makeStyles, Paper, Button, CircularProgress, Box } from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { useMutation } from 'react-query';
import { addYears } from 'date-fns';
import { useConfirm } from 'material-ui-confirm';

import { groupBy } from '../../../utils/utils';
import { callWs } from '../../../api/websocket';
import { MESSAGE_TYPES } from '../../../api/messageTypes';
import { dateFormater, formatValueFrom } from '../../../utils/valueFormatter';

import PolicyButtons from '../../components/policy-buttons/PolicyButtons';
import * as customToast from '../../toast/customToast';

import PopupDialog, { PopupDialogProps } from '../dialogs/popup-dialog/PopupDialog';
import PaymentList from '../payments/payment-list/PaymentList';

import PolicyTabPanel from './tab-panel/PolicyTabPanel';
import ActionButtons from './actions-buttons/ActionButtons';
import PremiumPanel from './tabs/premium-panel/PremiumPanel';
import DocumentsList from './tabs/documents-list/DocumentsList';
import MidtermAdjustments from './tabs/mtas/MidtermAdjustments';
import DocumentsPanel from './tabs/documents-panel/DocumentsPanel';
import PolicyInvoices from './tabs/policy-invoices/PolicyInvoices';
import PolicyQuestions from './tabs/policy-questions/PolicyQuestions';
import MainPolicyDetails from './tabs/main-policy-details/MainPolicyDetails';
import PolicyAddressesPanel from './tabs/policy-addresses-panel/PolicyAddressesPanel';
import SectionExtraFieldsWrapper from './section-extra-fields-wrapper/SectionExtraFieldsWrapper';
import InsuredPersonsWrapper from './tabs/insured-persons/insured-persons-wrapper/InsuredPersonsWrapper';
import InsuredEntitiesWrapper from './tabs/insured-entities/insured-entities-wrapper/InsuredEntitiesWrapper';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export interface PolicyFormProps {
  policy?: any;
  fetchPolicy?: any;
}

const PolicyForm: React.FC<PolicyFormProps> = (props) => {
  const { policy, fetchPolicy } = props;
  const classes = useStyles();
  const [showActionsButtons, setShowActionsButtons] = useState(false);
  const [showAccordions, setShowAccordions] = useState(false);
  const [isPolicyChanged, setIsPolicyChanged] = useState(false);
  const [currentTab, setCurrentTab] = useState(10);

  const [popupOptions, setPopupOptions] = useState<PopupDialogProps | undefined>();
  const [insuredEntities, setInsuredEntities] = useState({});

  const confirm = useConfirm();

  const methods = useForm({
    defaultValues: {
      policyId: null,
      policyType: '',
      inceptionDate: new Date(),
      expirationDate: addYears(new Date(), 1),
      cancellationDate: new Date(),
      policyNumber: null,
      quoteNumber: null,
      policyStatus: 'New',
      descriptiveStatus: 'None',
      agent: null,
      adjuster: null,
      insurer: null,
      reinsurer: null,
      insuredDetails: [],
      agentCommission: null,
      agentNettingDown: false,
      agentNettingDownPercent: '',
      surplusLinesTaxPct: null,
      surplusLinesTaxFee: null,
      policySurcharge: null,
      paymentQuestions: {
        billingMethod: 'directBill',
        numberOfInstallments: '4',
        splitOption: 'weighted',
        dueDate: '1',
        invoicesReminders: false,
        invoicesEmail: false,
        invoicesPostMail: false,
        recurringPayments: false,
      },
      insuredEntities: {},
      invoices: [],
      documents: [],
      newDocuments: [],
    },
    mode: 'all',
  });

  const {
    mutateAsync: bindPolicy,
    isLoading: isPolicyBindLoading,
  } = useMutation(MESSAGE_TYPES.POLICY_BIND, (data: any) =>
    callWs(MESSAGE_TYPES.POLICY_BIND, data),
  );

  useEffect(() => {
    if (policy) {
      const _mapInsuredEntities = (insuredEntities = []) => {
        if (insuredEntities.length === 0) return {};
        const insuredEntitiesByType = groupBy(insuredEntities, 'entityType');

        const mappedInsuredEntities = {};

        Object.keys(insuredEntitiesByType).forEach((entityType) => {
          mappedInsuredEntities[entityType] = insuredEntitiesByType[entityType].map(
            (insuredEntity) => {
              return {
                id: insuredEntity.id,
                position: insuredEntity.position,
                lossPayees: insuredEntity.lossPayees,
                ...Object.keys(insuredEntity.properties)
                  .map((key) => ({
                    [key]: formatValueFrom(
                      insuredEntity.properties[key],
                      policy.productDescription.jsonDescription.insuredEntitiesDescriptions[
                        entityType
                      ].data.find((entityDescription) => entityDescription.name === key),
                      policy,
                    ),
                  }))
                  .reduce((a, b) => Object.assign(a, b), {}),
                availableCovers:
                  insuredEntity.covers.map((cover) => {
                    return {
                      id: cover.id,
                      ...Object.keys(cover.properties)
                        .map((key) => ({
                          [key]: formatValueFrom(
                            cover.properties[key],
                            policy.productDescription.jsonDescription.coverProperties.find(
                              (coverProperty) => coverProperty.name === key,
                            ),
                            policy,
                          ),
                        }))
                        .reduce((a, b) => Object.assign(a, b), {}),
                      name: policy.productDescription.jsonDescription.insuredEntitiesDescriptions[
                        entityType
                      ].availableCovers.find(
                        (availableCover) => availableCover.name === cover.coverName,
                      )?.label,
                    };
                  }) ?? [],
              };
            },
          );
        });

        return mappedInsuredEntities;
      };
      // setShowActionsButtons(true);
      setShowAccordions(true);
      //const inceptionDateSpit = policy.inceptionDate.split('-');
      //const expirationDateSpit = policy.expirationDate.split('-');

      methods.reset({
        policyId: policy.id,
        policyType: policy.productDescription.product.id,
        inceptionDate: dateFormater(policy.inceptionDate),
        expirationDate: dateFormater(policy.expirationDate),
        cancellationDate: dateFormater(policy.cancellationDate),
        policyNumber: policy.policyNumber,
        quoteNumber: policy.quoteNumber,
        policyStatus: policy.status,
        descriptiveStatus: policy?.descriptiveStatus,
        agent: policy.agent,
        adjuster: policy.adjuster,
        insurer: policy.insurer,
        reinsurer: policy.reinsurer,
        insuredDetails: policy.insuredPersons,
        agentCommission: policy.agentCommission,
        agentNettingDown: policy.agentNettingDown,
        agentNettingDownPercent: policy.agentNettingDown ? policy.netDownToPct : null,
        surplusLinesTaxPct: policy.taxPct,
        surplusLinesTaxFee: policy.taxFee,
        policySurcharge: policy.surcharge,
        paymentQuestions: {
          billingMethod: 'directBill',
          numberOfInstallments: '4',
          splitOption: 'weighted',
          dueDate: '1',
          invoicesReminders: false,
          invoicesEmail: false,
          invoicesPostMail: false,
          recurringPayments: false,
        },
        invoices: [],
        documents: [],
        newDocuments: [],
      });
      setInsuredEntities(_mapInsuredEntities(policy.insuredEntities));
    }
  }, [policy]);

  const _onBindPolicy = async () => {
    try {
      if (!isPolicyBindLoading) {
        await bindPolicy({ policyId: policy.id });
        fetchPolicy();
        customToast.success('Policy bound successfully');
      }
    } catch (err) {
      customToast.error(err.messageCode, {
        id: 'policy-bind-error',
      });
    }
  };

  const _onPopup = useCallback((popupOptions) => {
    setPopupOptions(popupOptions);
  }, []);

  const _getQuestionsFromJson = (uWQuestionsIds) => {
    return uWQuestionsIds?.map((element) =>
      policy?.productDescription?.jsonDescription?.questions.find(
        (question) => question.id === element,
      ),
    );
  };

  const tabs = {
    MainPolicyDetails: {
      component: MainPolicyDetails,
      properties: {
        isPolicyCreated: !!policy,
        fetchPolicy,
        setIsPolicyChanged,
      },
    },
    InsuredPersons: {
      component: InsuredPersonsWrapper,
      properties: {
        policy,
        fetchPolicy,
        setIsPolicyChanged,
      },
    },
    InsuredEntities: {
      component: InsuredEntitiesWrapper,
      properties: {
        policy,
        insuredEntities,
        setInsuredEntities,
        onPopup: _onPopup,
      },
    },
    PolicyQuestions: {
      component: PolicyQuestions,
      properties: {
        questionDescriptions: _getQuestionsFromJson(
          policy?.productDescription?.jsonDescription?.uWQuestions,
        ),
        questionAnswers: policy?.questionAnswers,
        policyData: policy,
        onPopup: _onPopup,
        fetchPolicy,
      },
    },
    PremiumPanel: { component: PremiumPanel, properties: { policy, fetchPolicy } },
    PolicyInvoices: { component: PolicyInvoices, properties: { policy, fetchPolicy } },
    PolicyDocuments: { component: DocumentsList, properties: { policy, fetchPolicy } },
    DocumentsUpload: { component: DocumentsPanel, properties: { policy } },
    PolicyAddresses: {
      component: PolicyAddressesPanel,
      properties: {
        policy,
        fetchPolicy,
      },
    },
    Payments: { component: PaymentList, properties: { policy } },
    MidtermAdjustments: { component: MidtermAdjustments, properties: { policy, fetchPolicy } },
  };

  const _renderTabView = () => {
    const tab =
      tabs[
        policy?.productDescription?.jsonDescription?.sections?.find(
          (section) => section.displayOrder === currentTab,
        )?.name ?? 'MainPolicyDetails'
      ];

    const Component = tab.component;

    return <Component {...tab.properties} />;
  };

  const _renderExtraSectionFieldsWrapper = () => {
    const currenctSection = policy?.productDescription?.jsonDescription?.sections?.find(
      (section) => section.displayOrder === currentTab,
    );

    return currenctSection?.extraFields ? (
      <SectionExtraFieldsWrapper
        sectionName={currenctSection.name}
        extraFields={currenctSection.extraFields}
        productDescription={policy.productDescription.jsonDescription}
        policy={policy}
        setIsPolicyChanged={setIsPolicyChanged}
        fetchPolicy={fetchPolicy}
      />
    ) : null;
  };

  const _checkHasBlockingFields = () => {
    const currentSection = policy?.productDescription?.jsonDescription?.sections?.find(
      (section) => section.displayOrder === currentTab,
    );

    const extraFields = currentSection?.extraFields;

    let hasBlockingPolicyFields = false;
    let hasBlockingReferenceFields = false;

    if (extraFields) {
      const policyDataFields = extraFields.policyData;
      const referenceFields = extraFields.references;

      hasBlockingPolicyFields = policyDataFields
        .filter((field) => field.blocksProgress)
        .some((field) => {
          const fieldName = field.name;

          return policy?.extraFields[fieldName] == null;
        });

      hasBlockingReferenceFields = referenceFields
        .filter((field) => field.blocksProgress)
        .some((field) => {
          switch (field.refersTo) {
            case 'questions':
              const questionAnswer = policy?.questionAnswers?.find(
                (questionAnswer) => questionAnswer.questionId === field.key,
              );

              return questionAnswer?.value == null;
            default:
              return false;
          }
        });
    }

    return hasBlockingPolicyFields || hasBlockingReferenceFields;
  };

  const _handleChangeTab = async (value) => {
    if (_checkHasBlockingFields()) {
      customToast.warning('Please fill the additional policy fields to continue');

      return;
    }
    if (isPolicyChanged) {
      await confirm({
        confirmationText: 'Discard',
        title: 'Discard Changes',
        description:
          'You are going to discard your changes. If you want to save your changes please go back and press the update button.',
      });
      setCurrentTab(value);
      setIsPolicyChanged(false);
    } else {
      setCurrentTab(value);
      setIsPolicyChanged(false);
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <ActionButtons display={showActionsButtons} />
        {policy && (
          <PolicyTabPanel
            policy={policy}
            sections={policy.productDescription.jsonDescription.sections}
            currentTab={currentTab}
            setCurrentTab={_handleChangeTab}
          />
        )}

        <Box mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper>{_renderTabView()}</Paper>
            </Grid>
          </Grid>
        </Box>

        <Box mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {_renderExtraSectionFieldsWrapper()}
            </Grid>
          </Grid>
        </Box>

        {/* {policy && (
            <Grid item xs={12}>
              <Paper>
                <InsuredPersonsWrapper policy={policy} />
              </Paper>
            </Grid>
          )}

          {showAccordions && (
            <>
              <Grid item xs={12}>
                <Paper>
                  <InsuredEntitiesWrapper
                    policy={policy}
                    insuredEntities={insuredEntities}
                    setInsuredEntities={setInsuredEntities}
                    onPopup={_onPopup}
                  />
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="policy-questions"
                  >
                    <Typography className={classes.heading}>Policy Questions</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <PolicyQuestions
                      questionDescriptions={policy.productDescription.jsonDescription.uWQuestions}
                      questionAnswers={policy.questionAnswers}
                      policyData={policy}
                      onPopup={_onPopup}
                    />
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={12}>
                <PremiumPanel />
              </Grid>
              {policy.status != 'CREATED' && (
                <Grid item xs={12}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="policy-invoices"
                    >
                      <Typography className={classes.heading}>Policy Invoices</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <PolicyInvoices />
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              )}
              {policy.status != 'CREATED' && (
                <Grid item xs={12} style={{ padding: '24px 20px' }}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="pay-plan"
                      id="payPlan"
                    >
                      <Typography className={classes.heading}>Policy Documents</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <DocumentsPanel />
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              )}


            </>
          )}
        </Grid> */}
        {policy && (
          <Grid
            item
            container
            xs={12}
            style={{ padding: '24px 20px' }}
            spacing={3}
            justify="center"
          >
            <PolicyButtons fetchPolicy={fetchPolicy} policy={policy} />
          </Grid>
        )}
        <DevTool control={methods.control} />
      </FormProvider>
      {popupOptions && (
        <PopupDialog
          open={true}
          onClose={() => setPopupOptions(undefined)}
          onSubmit={popupOptions.onSubmit}
          message={popupOptions.message}
          popupInputData={popupOptions.popupInputData}
          currentValue={popupOptions.currentValue}
        />
      )}
    </>
  );
};

export default PolicyForm;

import { AppBar, Tabs, Tab } from '@material-ui/core';
import { useEffect, useState } from 'react';

interface TabSection {
  name: string;
  label: string;
  displayOrder: number;
}

export interface PolicyTabPanelProps {
  sections: TabSection[];
  currentTab: number;
  setCurrentTab: (newValue: number) => void;
}

function a11yProps(index: any) {
  return {
    id: `policy-tab-${index}`,
    'aria-controls': `policy-tab-${index}`,
  };
}

const PolicyTabPanel = (props) => {
  const { sections, currentTab, setCurrentTab, policy } = props;
  const [tabColor, setTabColor] = useState<any>('primary');

  const handleChange = (_, newValue: number) => {
    setCurrentTab(newValue);
  };

  return (
    <AppBar
      position="static"
      style={
        policy.status === 'CREATED_FOR_MTA' ? { background: '#1A9C82' } : { background: '#3f51b5' }
      }
    >
      <Tabs
        value={currentTab}
        onChange={handleChange}
        aria-label="Policy Tabs"
        indicatorColor="secondary"
        variant="scrollable"
        scrollButtons="auto"
      >
        {sections
          .sort((a, b) => a.displayOrder - b.displayOrder)
          .map((section, index) => (
            <Tab
              key={section.name}
              label={section.label}
              value={section.displayOrder}
              {...a11yProps({ index })}
            />
          ))}
      </Tabs>
    </AppBar>
  );
};

export default PolicyTabPanel;

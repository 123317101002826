import BooleanInput from './boolean-input/BooleanInput';
import NumberInput from './number-input/NumberInput';
import TextInput from './text-input/TextInput';
import EnumInput from './enum-input/EnumInput';
import DateInput from './date-input/DateInput';
import ReferenceInput from './reference-input/ReferenceInput';

export interface InputProperty {
  /**
   * The input name, should be unique
   */
  name: string;
  /**
   * The input label
   */
  label: string;
  /**
   * The input type, can be float, text, enum, etc
   */
  type: 'float' | 'text' | 'boolean' | 'enum' | 'multi-enum' | 'reference' | any;
  /**
   * The input presentation, can be currency, switch, autocomplete, etc
   */
  presentation: 'currency' | 'switch' | 'autocomplete' | 'multiselect' | any;
  /**
   * The input validation rules
   */
  validation?: { front?: { required?: boolean; min?: any; max?: any }; back?: string };
  /**
   * The input possible values
   */
  values?: any[];
  /**
   * The input default value
   */
  defaultValue?: any;
  /**
   * The input visual rules for the RuleParser
   */
  visualRules?: any[];

  /**
   * If type is reference, data to display will be referenced by this field
   */
  refersTo?: string;

  /**
   * If type is reference, the data to be stored will be referenced by this field
   */
  referenceStore?: string;

  /**
   * If type is reference, this will be the label for the displayed data
   */
  referenceLabel?: string[];
}

export interface InputWrapperProps {
  /**
   * The input properties as defined in the product description
   */
  properties: InputProperty;
  /**
   * The input current value
   */
  value: any;
  /**
   * The input onChange handler, executes when a change happens to the input
   */
  onChange: (value: any) => void;
  /**
   * The input afterOnChange handler, executes after onChange handler
   */
  afterOnChange?: () => void;
  /**
   * The input evaluateRules handler, executes after onChange handler
   */
  evaluateRules?: (value?: any) => void;
  /**
   * The input onKeyDown handler, executes when a specific key is pressed down
   */
  onKeyDown?: (e: any) => void;
  /**
   * The input display mode option, edit: Renders as input, read: Renders as read only text or checkbox etc
   */
  displayMode?: 'edit' | 'read';
  /**
   * The input display label option
   */
  displayLabel?: boolean;
  /**
   * The input disabled option
   */
  disabled?: boolean;
  /**
   * The input error option
   */
  error?: boolean;
  /**
   * The input helper text
   */
  helperText?: string;
  /**
   * Any referenceData needed
   */
  referencedData?: any;
}

/**
 * The Input Wrapper component is encapsulating the functionality of rendering a specific input based on a description of the field
 */
const InputWrapper: React.FC<InputWrapperProps> = (props) => {
  const {
    properties,
    value,
    onChange,
    afterOnChange,
    evaluateRules,
    onKeyDown,
    displayMode = 'edit',
    displayLabel = true,
    disabled = false,
    error,
    helperText,
    referencedData,
  } = props;

  switch (properties.type) {
    case 'int':
    case 'float':
      return (
        <NumberInput
          properties={properties}
          value={value}
          onChange={onChange}
          afterOnChange={afterOnChange}
          evaluateRules={evaluateRules}
          onKeyDown={onKeyDown}
          displayMode={displayMode}
          displayLabel={displayLabel}
          error={error}
          helperText={helperText}
          disabled={disabled}
        />
      );
    case 'text':
    case 'longText':
      return (
        <TextInput
          properties={properties}
          value={value}
          onChange={onChange}
          afterOnChange={afterOnChange}
          evaluateRules={evaluateRules}
          onKeyDown={onKeyDown}
          displayMode={displayMode}
          displayLabel={displayLabel}
          error={error}
          helperText={helperText}
          disabled={disabled}
        />
      );
    case 'boolean':
      return (
        <BooleanInput
          properties={properties}
          value={value}
          onChange={onChange}
          afterOnChange={afterOnChange}
          evaluateRules={evaluateRules}
          onKeyDown={onKeyDown}
          displayMode={displayMode}
          displayLabel={displayLabel}
          error={error}
          helperText={helperText}
          disabled={disabled}
        />
      );
    case 'date':
      return (
        <DateInput
          properties={properties}
          value={value}
          onChange={onChange}
          afterOnChange={afterOnChange}
          evaluateRules={evaluateRules}
          onKeyDown={onKeyDown}
          displayMode={displayMode}
          displayLabel={displayLabel}
          error={error}
          helperText={helperText}
          disabled={disabled}
        />
      );
    case 'enum':
    case 'multi-enum':
      return (
        <EnumInput
          properties={properties}
          value={value}
          onChange={onChange}
          afterOnChange={afterOnChange}
          evaluateRules={evaluateRules}
          onKeyDown={onKeyDown}
          displayMode={displayMode}
          displayLabel={displayLabel}
          error={error}
          helperText={helperText}
          disabled={disabled}
        />
      );

    case 'reference':
      return (
        <ReferenceInput
          properties={properties}
          value={value}
          onChange={onChange}
          afterOnChange={afterOnChange}
          evaluateRules={evaluateRules}
          onKeyDown={onKeyDown}
          displayMode={displayMode}
          displayLabel={displayLabel}
          error={error}
          helperText={helperText}
          disabled={disabled}
          referencedData={referencedData}
        />
      );
    default:
      return <span>¯\_(ツ)_/¯</span>;
  }
};

export default InputWrapper;

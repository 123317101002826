import { TextField } from '@material-ui/core';
import { InputWrapperProps } from '../InputWrapper';
import { handleKey } from '../../../../utils/formUtils';

export interface TextInputProps extends InputWrapperProps {
  value: string | undefined;
  onChange: (value: string) => void;
}

const TextInput: React.FC<TextInputProps> = (props) => {
  const {
    properties,
    displayMode,
    value,
    onChange,
    afterOnChange,
    evaluateRules,
    onKeyDown,
    displayLabel,
    error,
    helperText,
    disabled,
  } = props;

  return displayMode === 'read' ? (
    <span>{value}</span>
  ) : (
    <TextField
      label={displayLabel ? properties.label : undefined}
      fullWidth
      variant="standard"
      type={properties.presentation || 'text'}
      margin="dense"
      InputLabelProps={{ shrink: true }}
      value={value != null ? value : ''}
      onChange={(e) => {
        onChange(e.target.value);
        afterOnChange?.();
      }}
      onBlur={evaluateRules}
      onKeyDown={(e) => (onKeyDown ? onKeyDown(e) : handleKey(e))}
      multiline={properties.presentation === 'textarea'}
      rows={properties.presentation === 'textarea' ? 4 : undefined}
      required={properties?.validation?.front?.required}
      error={error}
      helperText={helperText}
      disabled={disabled}
    />
  );
};

export default TextInput;

import { CssBaseline, makeStyles, ThemeProvider } from '@material-ui/core';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.min.css';

import AuthProvider from '../shared/providers/AuthProvider';
import LayoutProvider from '../shared/providers/LayoutProvider';

import HeaderBar from './header-bar/HeaderBar';
import AppRouter from './router/AppRouter';
import { theme } from './theme';

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },

  main: {
    padding: '5rem 2.5rem',
  },
}));

const AppLayout = () => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <LayoutProvider>
          <HeaderBar />
          <main className={classes.main}>
            <AppRouter />
          </main>
          <ToastContainer
            autoClose={5000}
            position="bottom-right"
            closeOnClick
            pauseOnFocusLoss
            draggable
            newestOnTop={false}
            pauseOnHover
            limit={3}
          />
        </LayoutProvider>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default AppLayout;

import ExpressionLanguage from './ExpressionLanguage';

export interface RuleData {
  field: any;
  policyData?: any;
  entity?: any;
}

class RulesParser {
  private rules: any[];
  private data: RuleData;
  private popupOptions: any;

  private expressionLanguage: ExpressionLanguage;

  constructor(rules: any[], data: RuleData, popupOptions: any) {
    this.rules = rules;
    this.data = data;
    this.popupOptions = popupOptions;

    this.expressionLanguage = new ExpressionLanguage();
  }

  public evaluateRules() {
    try {
      this.rules.forEach((rule) => {
        const condition = this._constructRuleCondition(rule);

        const evaluatedCondition = this._evaluateRuleCondition(condition);

        this._handleRuleAction(rule, evaluatedCondition);
      });

      return this.data;
    } catch (err) {
      console.error(err);
    }
  }

  private _constructRuleCondition(rule): string {
    let condition = '';
    rule.dataItems.forEach((dataItem, dataItemIndex) => {
      condition += dataItem + ' ';
      condition += rule.checkTypes[dataItemIndex] + ' ';
      condition += rule.checkValues[dataItemIndex] + ' ';
      if (rule.join && rule.join[dataItemIndex]) {
        condition += rule.join[dataItemIndex] + ' ';
      }
    });

    return condition;
  }

  private _evaluateRuleCondition(condition): boolean {
    try {
      return (this.expressionLanguage.evaluate(condition, {
        policyData: this.data.policyData,
        entity: this.data.entity,
      }) as unknown) as boolean;
    } catch (err) {
      console.log('Rule condition expression error', condition);
      console.error(err);

      return false;
    }
  }

  private _handleRuleAction(rule: any, evaluatedCondition: boolean) {
    const actionToExecute: string = evaluatedCondition ? rule.actionTrue : rule.actionFalse;

    if (actionToExecute) {
      this[actionToExecute](rule);
    }
  }

  private show() {
    this.data.field.hidden = false;
  }

  private hide() {
    this.data.field.hidden = true;
  }

  private popup(rule) {
    if (this.popupOptions) {
      const popUpTargetProperty = rule.popUpTargetProperty.split('.')[1];
      const field = this.popupOptions.elements.find(
        (element) => element[this.popupOptions.key] === popUpTargetProperty,
      );

      this.popupOptions.handler({
        message: rule.popUpMessage,
        popupInputData:
          this.popupOptions.key === 'id'
            ? {
                ...field.data,

                label: field.question,
              }
            : field,
        onSubmit: this.popupOptions.submit,
        currentValue: this.popupOptions.currentValue,
      });
    }
  }
}

export default RulesParser;

import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, Button } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { CircularProgress } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { format } from 'date-fns';
import { useMutation } from 'react-query';

import { MESSAGE_TYPES } from '../../../../../../api/messageTypes';
import { callWs } from '../../../../../../api/websocket';
import * as customToast from '../../../../../toast/customToast';
import { dateFormater } from '../../../../../../utils/valueFormatter';

const InvoiceUnVoidModal: React.FC<any> = (props) => {
  const { invoiceIdThatIsBeingAccessed, open, handleClose } = props;

  const { control, getValues, errors } = useForm<any>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const {
    mutateAsync: unVoidInvoice,
    isLoading: isUnVoidInvoiceLoading,
  } = useMutation(MESSAGE_TYPES.POLICY_INVOICE_UNVOID, (data: any) =>
    callWs(MESSAGE_TYPES.POLICY_INVOICE_UNVOID, data),
  );

  const _handleUnVoidInvoiceCall = async (data) => {
    try {
      await unVoidInvoice({
        id: invoiceIdThatIsBeingAccessed,
        date: format(data.dueDate, 'yyyy-MM-dd'),
      });
      customToast.success('Invoice was restored successfully');
    } catch (err) {
      customToast.error(err.messageCode);
    }
    handleClose();
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'sm'}>
        <DialogTitle>Provide a new due date for this invoice</DialogTitle>
        <DialogContent style={{ overflow: 'hidden' }}>
          <Grid container item spacing={4}>
            <Grid item xs={6} md={12} style={{ display: 'flex', justifyContent: 'center' }}>
              <Controller
                render={({ onChange, value }) => (
                  <KeyboardDatePicker
                    required
                    fullWidth
                    label="New Due Date"
                    format="MM/dd/yyyy"
                    minDate={new Date()}
                    minDateMessage={`Date should not be earlier than ${format(
                      new Date(),
                      'MM-dd-yyyy',
                    )}`}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    error={errors.dueDate !== undefined}
                    onChange={(data) => {
                      onChange(data);
                    }}
                    value={value}
                  />
                )}
                defaultValue={new Date()}
                control={control}
                name="dueDate"
                rules={{
                  required: {
                    value: true,
                    message: 'Due Date is required',
                  },
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => _handleUnVoidInvoiceCall(getValues())}
            disabled={isUnVoidInvoiceLoading}
          >
            {!isUnVoidInvoiceLoading ? 'Submit' : <CircularProgress size={24} />}
          </Button>
          <Button onClick={() => handleClose()} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InvoiceUnVoidModal;

import {
  Typography,
  Grid,
  Box,
  Button,
  CircularProgress,
  List,
  Card,
  Paper,
  Container,
} from '@material-ui/core';
import { styled } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { memo, useEffect, useState, useRef } from 'react';
import { useMutation } from 'react-query';
import BooleanQuestion from '../../../questions/boolean-question';
import OptionQuestion from '../../../questions/option-question';
import useStyles from './PremiumPanel.styles';
import { MESSAGE_TYPES } from '../../../../../api/messageTypes';
import { callWs } from '../../../../../api/websocket';
import * as customToast from '../../../../toast/customToast';
import { groupBy, roundToTwo } from '../../../../../utils/utils';
import { useConfirm } from 'material-ui-confirm';
import { useContext } from 'react';
import MtaContext from './MtaContext';
import SelectedCard from './MtaContext';
import InfoPanel from './InfoPanel';
import MtaCard from './MtaCard';

import PolicyInvoices from '../policy-invoices/PolicyInvoices';

const PremiumPanel = (props) => {
  const { policy, fetchPolicy } = props;
  const [data, setData] = useState(null);
  const [response, setResponse] = useState(0);
  const [difference, setDifference] = useState(0);
  const { control, reset } = useForm({
    defaultValues: {
      billingMethod: policy.billingMethod,
      installments: policy.installments,
      splitOption: policy.splitOption,
      dueDateOption: policy.dueDateOption,
      invoicesByEmail: policy.invoicesByEmail,
      invoicesByPost: policy.invoicesByPost,
      invoicesReminders: policy.invoicesReminders,
      recurringPayments: policy.recurringPayments,
      invoicesToAgent: policy.invoicesToAgent,
      invoicesToBascule: policy.invoicesToBascule,
    },
  });
  function scrollDown() {
    var objDiv = document.getElementById('list');
    objDiv!.scrollTop = objDiv!.scrollHeight;
  }
  const [amounts, setAmounts] = useState<any>([]);
  useEffect(() => {
    _fetchAmounts();
    fetchPolicy();
    getAnalysisAndSaveResponse();
  }, []);
  const confirm = useConfirm();
  const [selectedCard, setSelectedCard] = useState(0);

  const _fetchAmounts = async () => {
    await fetchAmounts({ policy_id: policy.id }).then((data: any) => {
      setAmounts(data);
      setSelectedCard(data.length - 1);
    });
  };

  useEffect(() => {
    reset({
      billingMethod: policy.billingMethod,
      installments: policy.installments,
      splitOption: policy.splitOption,
      dueDateOption: policy.dueDateOption,
      invoicesByEmail: policy.invoicesByEmail,
      invoicesByPost: policy.invoicesByPost,
      invoicesReminders: policy.invoicesReminders,
      recurringPayments: policy.recurringPayments,
      invoicesToAgent: policy.invoicesToAgent,
      invoicesToBascule: policy.invoicesToBascule,
    });
    scrollDown();
  }, [policy]);

  const classes = useStyles();

  const {
    mutateAsync: mtaAnalysis,
    isLoading: isMtaInvoiceLoading,
  } = useMutation(MESSAGE_TYPES.MTA_INVOICE_ANALYSIS, (data: any) =>
    callWs(MESSAGE_TYPES.MTA_INVOICE_ANALYSIS, data),
  );
  const {
    mutateAsync: fetchAmounts,
    isLoading: isFetchAmountsLoading,
  } = useMutation(MESSAGE_TYPES.FETCH_AMOUNTS, (data: any) =>
    callWs(MESSAGE_TYPES.FETCH_AMOUNTS, data),
  );

  const {
    mutateAsync: updatePolicyData,
    isLoading: isPolicyUpdateLoading,
  } = useMutation(MESSAGE_TYPES.POLICY_UPDATE, (data: any) =>
    callWs(MESSAGE_TYPES.POLICY_UPDATE, data),
  );

  const {
    mutateAsync: generatePaymentPlan,
    isLoading: isGeneratePaymentPlanLoading,
  } = useMutation(MESSAGE_TYPES.GENERATE_PAYMENT_PLAN, (data: any) =>
    callWs(MESSAGE_TYPES.GENERATE_PAYMENT_PLAN, data),
  );
  const getAnalysisAndSaveResponse = async () => {
    try {
      const analysisResponse: any = await mtaAnalysis({ id: policy.id });
      if (analysisResponse !== null || undefined) {
        const amount = analysisResponse?.amounts?.totalWithTaxesAndSurcharge;
        setData(analysisResponse);
        setResponse(amount);
        setDifference(analysisResponse?.amounts?.totalDiff);
      }
    } catch (err) {
      customToast.error(err.messageCode, {
        toastId: `mta-analysis-failed`,
      });
    }
  };
  const _handleGeneratePaymentPlan = async () => {
    let forceInvoiceGeneration = false;
    if (policy.invoices.length > 0) {
      await confirm({
        confirmationText: 'Regenerate',
        title: 'Regenerate Created Invoices',
        description:
          'Some invoices/payments were found associated with this policy. By regenerating the payment plan all the previous information regarding invoices and payments on this policy wil be lost.',
      }).then(() => {
        // setForceInvoiceGeneration(true);
        forceInvoiceGeneration = true;
      });
    }
    try {
      if (!isGeneratePaymentPlanLoading) {
        await generatePaymentPlan({
          policyId: policy.id,
          forceInvoiceGeneration: forceInvoiceGeneration,
        });
        fetchPolicy();
        customToast.success('Payment plan generated successfully');
      }
    } catch (err: any) {
      customToast.error(err.messageCode, {
        id: 'policy-generate-plan-error',
      });
    }
  };

  let mgaTotalTaxAndFees = 0;
  let insurerTotalTaxAndFees = 0;
  if (policy.productDescription.jsonDescription.isSurplusLines) {
    mgaTotalTaxAndFees = policy.totalMgaTaxAmount + policy.taxFeeAmount + policy.surcharge;
  } else {
    insurerTotalTaxAndFees = policy.totalInsurerTaxAmount + policy.taxFeeAmount;
    mgaTotalTaxAndFees = policy.surcharge;
  }
  const premiums = [
    {
      label: 'Insurer Premium & PCT',
      value: `${policy.totalInsurerPremiumAmount.toFixed(2)} (${policy.insurerPctAfterNetdown}%)`,
      group: '1',
    },
    {
      label: 'Agent Premium & PCT',
      value: `${policy.totalAgentPremiumAmount.toFixed(2)} (${policy.agentPctAfterNetdown}%)`,
      group: '1',
    },
    {
      label: 'MGA Premium & PCT',
      value: `${policy.totalMgaPremiumAmount.toFixed(2)} (${policy.mgaPctAfterNetdown}%)`,
      group: '1',
    },
    {
      label: 'Total Amount Excl. Taxes',
      value: policy.totalPremiumAmount.toFixed(2),
      group: '1',
    },
    { label: 'Insurer Taxes & Fees', value: insurerTotalTaxAndFees.toFixed(2), group: '2' },
    { label: 'Agent Taxes & Fees', value: policy.totalAgentTaxAmount.toFixed(2), group: '2' },
    { label: 'Bascule Taxes & Fees', value: mgaTotalTaxAndFees.toFixed(2), group: '2' },
    { label: 'Total Taxes & Fees', value: policy.totalTaxAmount.toFixed(2), group: '2' },
    {
      label: 'Insurer Total Incl. Taxes',
      value: (policy.totalInsurerPremiumAmount + insurerTotalTaxAndFees).toFixed(2),
      group: '3',
    },
    {
      label: 'Agent Total Incl. Taxes',
      value: policy.totalAgentPremiumWithTaxes.toFixed(2),
      group: '3',
    },
    {
      label: 'MGA Total Incl. Taxes',
      value: (policy.totalMgaPremiumAmount + mgaTotalTaxAndFees).toFixed(2),
      group: '3',
    },
    {
      label: 'Total Incl. Taxes & Fees',
      value: policy.totalPremiumWithTaxes.toFixed(2),
      group: '3',
    },
    { label: 'Surplus Lines Stamping Fee', value: policy.taxFeeAmount.toFixed(2), group: '3' },
    { label: 'Surplus Lines Tax', value: policy.taxAmount.toFixed(2), group: '3' },
    { label: 'Policy Fee', value: policy.surcharge.toFixed(2), group: '4' },
  ];

  const _updatePolicyData = async (onChange, key, newValue, oldValue) => {
    let forceInvoiceGeneration = false;
    if (policy.invoices.length > 0) {
      await confirm({
        confirmationText: 'Regenerate',
        title: 'Regenerate Created Invoices',
        description:
          'Some invoices/payments were found associated with this policy. By regenerating the payment plan all the previous information regarding invoices and payments on this policy wil be lost.',
      }).then(() => {
        // setForceInvoiceGeneration(true);
        forceInvoiceGeneration = true;
      });
      try {
        onChange(newValue);
        await updatePolicyData({
          id: policy.id,
          [key]: newValue,
        });
      } catch (err: any) {
        customToast.error(err.messageCode);
        onChange(oldValue);
      }
      try {
        if (!isGeneratePaymentPlanLoading) {
          await generatePaymentPlan({
            policyId: policy.id,
            forceInvoiceGeneration: forceInvoiceGeneration,
          });
          fetchPolicy();
          customToast.success('Payment plan generated successfully');
        }
      } catch (err: any) {
        customToast.error(err.messageCode, {
          id: 'policy-generate-plan-error',
        });
      }
    }
    try {
      onChange(newValue);
      await updatePolicyData({
        id: policy.id,
        [key]: newValue,
      });
    } catch (err: any) {
      customToast.error(err.messageCode);
      onChange(oldValue);
    }
  };

  const groupedPremiums = groupBy(
    premiums.filter((premium) => premium.group),
    'group',
  );

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fffff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  return (
    <>
      <Grid container xl={12} lg={12} md={12} style={{ maxHeight: '60%' }}>
        <MtaContext.Provider value={{ selectedCard, setSelectedCard }}>
          <Grid container xl={2} lg={2} md={2}>
            {/*map here to create Mta cards*/}
            <List
              id="list"
              style={{
                overflow: 'auto',
                width: '100%',
                height: '50%',
              }}
            >
              {amounts.map((amount, index) => (
                <Grid item xl={12} lg={12} md={12} style={{ margin: '5px' }}>
                  <MtaCard
                    mtaNum={index}
                    mtaName={amount.key}
                    mtaDate={amount.effectiveDate}
                    mtaTotal={amount.total}
                  />
                </Grid>
              ))}
            </List>
          </Grid>
          <Grid container xl={10} lg={10} md={10}>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              style={{ padding: '5px', margin: '5px', height: '20%' }}
            >
              <InfoPanel policy={policy} fetchPolicy={fetchPolicy} />
            </Grid>
          </Grid>
        </MtaContext.Provider>
      </Grid>
      {data !== null || undefined ? (
        <Container maxWidth="xl">
          <Grid container item spacing={2} xl={12} lg={12} md={12} xs={12}>
            <Grid item xs={12}>
              <Typography component="h3">
                <Box fontWeight="fontWeightBold">MTA Difference</Box>
              </Typography>
            </Grid>

            <Grid
              item
              xl={12}
              lg={12}
              xs={12}
              md={12}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <Grid
                item
                xs={6}
                md={6}
                lg={6}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <div>
                  The difference from the previous total is:
                  <br />
                  <h2 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {difference} $
                  </h2>
                </div>
              </Grid>

              <Grid item xs={6} md={6} lg={6} style={{ display: 'flex', justifyContent: 'center' }}>
                <div>
                  The new total is:
                  <br />
                  <h2 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {response} $
                  </h2>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      ) : (
        <Container maxWidth="xl" className="pt-5">
          <Grid container item spacing={4}>
            <Grid item xs={12}>
              <Typography component="h3">
                <Box fontWeight="fontWeightBold">Payment Plan Details</Box>
              </Typography>
            </Grid>
            <Grid container md={12}>
              <Grid
                item
                xl={3}
                lg={3}
                md={3}
                xs={12}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Controller
                  render={({ onChange, value }) => (
                    <OptionQuestion
                      questionName="billingMethod"
                      questionLabel="What is the billing method?"
                      questionOptions={[
                        {
                          value: 'agency',
                          label: 'Agency Bill',
                        },
                        {
                          value: 'direct',
                          label: 'Direct Bill',
                        },
                      ]}
                      onChange={async (newValue) => {
                        _updatePolicyData(onChange, 'billingMethod', newValue, value);
                      }}
                      value={value}
                    />
                  )}
                  control={control}
                  name="billingMethod"
                />
              </Grid>
              <Grid
                item
                xl={3}
                lg={3}
                xs={12}
                md={3}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Controller
                  render={({ onChange, value }) => (
                    <OptionQuestion
                      questionName="installments"
                      questionLabel="Number of installments"
                      questionOptions={[
                        {
                          value: 1,
                          label: '1',
                        },
                        {
                          value: 2,
                          label: '2',
                        },
                        {
                          value: 4,
                          label: '4',
                        },
                        {
                          value: 9,
                          label: '9',
                        },
                      ]}
                      onChange={async (newValue) => {
                        _updatePolicyData(onChange, 'installments', parseInt(newValue), value);
                      }}
                      value={value}
                    />
                  )}
                  control={control}
                  name="installments"
                />
              </Grid>
              <Grid
                item
                xl={3}
                lg={3}
                xs={12}
                md={3}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Controller
                  render={({ onChange, value }) => (
                    <OptionQuestion
                      questionName="splitOption"
                      questionLabel="Split option"
                      questionOptions={[
                        {
                          value: 'equal',
                          label: 'Equal',
                        },
                        {
                          value: 'weighted',
                          label: 'Weighted',
                        },
                      ]}
                      onChange={async (newValue) => {
                        _updatePolicyData(onChange, 'splitOption', newValue, value);
                      }}
                      value={value}
                    />
                  )}
                  control={control}
                  name="splitOption"
                />
              </Grid>
              <Grid
                item
                xl={3}
                lg={3}
                xs={12}
                md={3}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Controller
                  render={({ onChange, value }) => (
                    <OptionQuestion
                      questionName="dueDateOption"
                      questionLabel="Due date option"
                      questionOptions={[
                        {
                          value: 1,
                          label: '1',
                        },
                        {
                          value: 2,
                          label: '2',
                        },
                        {
                          value: 3,
                          label: '3',
                        },
                      ]}
                      onChange={async (newValue) => {
                        _updatePolicyData(onChange, 'dueDateOption', parseInt(newValue), value);
                      }}
                      value={value}
                    />
                  )}
                  control={control}
                  name="dueDateOption"
                  rules={{ required: true }}
                />
              </Grid>
            </Grid>
            <Grid container item>
              <Grid item xl={4} lg={4} md={4} xs={12}></Grid>
            </Grid>
          </Grid>
        </Container>
      )}
      <Container maxWidth="xl" style={{ marginBottom: '30px' }}>
        <Grid container item spacing={4} xl={12} lg={12} md={12} xs={12}>
          <Grid item xs={12}>
            <Typography component="h3">
              <Box fontWeight="fontWeightBold">Payment Options</Box>
            </Typography>
          </Grid>
          <Grid
            item
            xl={4}
            lg={4}
            xs={6}
            md={4}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Controller
              render={({ onChange, value }) => (
                <BooleanQuestion
                  questionName="invoicesByEmail"
                  questionLabel="Receiving Invoices by email?"
                  labelPlacement="top"
                  onChange={async (newValue) => {
                    _updatePolicyData(onChange, 'invoicesByEmail', newValue, value);
                  }}
                  value={value}
                />
              )}
              control={control}
              name="invoicesByEmail"
            />
          </Grid>
          <Grid
            item
            xl={4}
            lg={4}
            xs={6}
            md={4}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Controller
              render={({ onChange, value }) => (
                <BooleanQuestion
                  questionName="sendToBascule"
                  questionLabel="Send to Bascule?"
                  labelPlacement="top"
                  onChange={async (newValue) => {
                    _updatePolicyData(onChange, 'invoicesToBascule', newValue, value);
                  }}
                />
              )}
              name="sentToBascule"
            />
          </Grid>
          <Grid
            item
            xl={4}
            lg={4}
            xs={6}
            md={4}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Controller
              render={({ onChange, value }) => (
                <BooleanQuestion
                  questionName="sendToAgent"
                  questionLabel="Send to Agent?"
                  labelPlacement="top"
                  onChange={async (newValue) => {
                    _updatePolicyData(onChange, 'invoicesToAgent', newValue, value);
                  }}
                />
              )}
              name="sendToAgent"
            />
          </Grid>
          <Grid
            item
            xl={4}
            lg={4}
            xs={6}
            md={4}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Controller
              render={({ onChange, value }) => (
                <BooleanQuestion
                  questionName="invoicesByPost"
                  questionLabel="Receiving Invoices by post mail?"
                  labelPlacement="top"
                  onChange={async (newValue) => {
                    _updatePolicyData(onChange, 'invoicesByPost', newValue, value);
                  }}
                />
              )}
              control={control}
              name="invoicesByPost"
            />
          </Grid>
          <Grid
            item
            xl={4}
            lg={4}
            xs={6}
            md={4}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Controller
              render={({ onChange, value }) => (
                <BooleanQuestion
                  questionName="invoicesReminders"
                  questionLabel="Receiving Invoices reminders?"
                  labelPlacement="top"
                  onChange={async (newValue) => {
                    _updatePolicyData(onChange, 'invoicesReminders', newValue, value);
                  }}
                  value={value}
                />
              )}
              control={control}
              name="invoicesReminders"
            />
          </Grid>
          <Grid
            item
            xl={4}
            lg={4}
            xs={6}
            md={4}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Controller
              render={({ onChange, value }) => (
                <BooleanQuestion
                  questionName="recurringPayments"
                  questionLabel="Recurring Payments?"
                  labelPlacement="top"
                  onChange={async (newValue) => {
                    _updatePolicyData(onChange, 'recurringPayments', newValue, value);
                  }}
                  value={value}
                />
              )}
              control={control}
              name="recurringPayments"
            />
          </Grid>
        </Grid>
        <Grid
          item
          xl={12}
          lg={12}
          xs={6}
          md={12}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <Grid item>
            <Button
              color="primary"
              fullWidth
              variant="contained"
              onClick={_handleGeneratePaymentPlan}
            >
              {isGeneratePaymentPlanLoading ? (
                <CircularProgress color="secondary" size={24} />
              ) : (
                'Generate Payment Plan'
              )}
            </Button>
          </Grid>
        </Grid>
      </Container>
      <PolicyInvoices policy={policy} fetchPolicy={fetchPolicy} />
    </>
  );
};

export default memo(PremiumPanel);

import {
  Grid,
  Paper,
  TextField,
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  InputAdornment,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Autocomplete } from '@material-ui/lab';
import { withStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import SearchIcon from '@material-ui/icons/Search';

import MaterialTable, { Icons, MTableAction } from '@material-table/core';

import { format } from 'date-fns';
import { useMutation } from 'react-query';
import { forwardRef, useEffect, useRef, useState } from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import * as customToast from '../../../toast/customToast';
import { handleSubmitKeyBinding } from '../../../../utils/formUtils';
import { roundToTwo } from '../../../../utils/utils';
import { MESSAGE_TYPES } from '../../../../api/messageTypes';
import { callWs } from '../../../../api/websocket';

const tableIcons: Icons = {
  Filter: forwardRef((props, ref) => <SearchIcon {...props} ref={ref} />),
};
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'fixed',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  bold: { fontWeight: 600 },
  paper: { minWidth: 1080 },
}));

const ColorButton = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
}))(Button);

export interface PaymentDistributionProps {
  policy: any;
  rowData: any;
  open: any;
  handleClose: any;
  handleCloseSilent: any;
  referredOnly?: any;
}

const PaymentDistribution: React.FC<PaymentDistributionProps> = (props) => {
  const { policy, rowData, open, handleClose, handleCloseSilent, referredOnly = false } = props;
  const { control, watch, setValue, getValues, errors, handleSubmit, reset } = useFormContext();

  const [allocatedAmount, setAllocatedAmount] = useState<any>(0);
  const [remainingAmount, setRemainingAmount] = useState<any>(0);
  const [distributionData, setDistributionData] = useState<any>([]);
  const [invoices, setInvoices] = useState<any>([]);

  const classes = useStyles();
  const tableRef: any = useRef();

  const personData = policy?.insuredPersons?.map((item) => ({
    id: item.id,
    name: item.firstName + ' ' + item.lastName,
  }));

  const {
    mutateAsync: paymentDistribute,
    isLoading: isPaymentDistributeLoading,
  } = useMutation(MESSAGE_TYPES.PAYMENT_DISTRIBUTION_CREATE, (data: any) =>
    callWs(MESSAGE_TYPES.PAYMENT_DISTRIBUTION_CREATE, data),
  );

  const {
    mutateAsync: paymentDistributionList,
    isLoading: ispaymentDistributionListLoading,
  } = useMutation(MESSAGE_TYPES.PAYMENT_DISTRIBUTION_LIST, (data: any) =>
    callWs(MESSAGE_TYPES.PAYMENT_DISTRIBUTION_LIST, data),
  );

  const {
    mutateAsync: paymentVoid,
    isLoading: isPaymentVoidLoading,
  } = useMutation(MESSAGE_TYPES.PAYMENTS_VOID, (data: any) =>
    callWs(MESSAGE_TYPES.PAYMENTS_VOID, data),
  );

  const _submitPaymentDistribution = async (data) => {
    const response = await paymentDistribute(data);
    customToast.success('Payment distributed successfully', {
      id: 'payment-distribute-success',
    });

    return response;
  };

  const _distributionsList = async () => {
    try {
      const response: any = await paymentDistributionList({ payment_id: rowData?.id });

      let totalAmount: number = 0;
      response.forEach((distribution) => {
        totalAmount += distribution.amount;
      });

      setAllocatedAmount(roundToTwo(totalAmount));

      setDistributionData([
        ...response?.map((dataItem) => ({
          amount: dataItem.amount,
          status: dataItem.status,
          invoice_id: dataItem.invoiceId,
          invoice_name: invoices.find(function (element) {
            return element.id === dataItem.invoiceId;
          })?.label,
          transactionDate: format(new Date(dataItem.createdAt), 'yyyy-MM-dd'),
        })),
      ]);
    } catch (err) {
      customToast.error(err.messageCode);
      console.log('error while fetching payment');
    }
  };

  const _voidPayment = async () => {
    try {
      const response = await paymentVoid({ payment_id: rowData?.id });
      customToast.success('Payment Voided successfully', {
        id: 'payment-voided-success',
      });
      handleClose();
    } catch (err) {
      customToast.error(err.messageCode);
      console.log('error while voiding payment');
    }
  };

  if (rowData?.insuredPerson) {
    setValue('insuredPerson', rowData?.insuredPersonId);
  }

  const defaultPerson = personData.find(function (element) {
    return element.id === rowData?.insuredPersonId;
  });

  const columns: any[] = [
    {
      title: 'Invoice',
      field: 'invoice_id',
      editable: 'onAdd',
      editComponent: (props) => (
        <TextField
          required
          fullWidth
          select
          label="Invoice"
          margin="dense"
          value={props.value}
          onChange={(e) => {
            var newRowData = { ...props.rowData };
            newRowData.invoice_id = e.target.value;
            // TODO: check the available amount before assigning the new value
            newRowData.amount = invoices.find(
              (element) => element.id === e.target.value,
            )?.remaining;
            let moneyAvailable = remainingAmount;
            if (newRowData.amount > moneyAvailable) {
              newRowData.amount = moneyAvailable;
              customToast.warning(
                'This distribution item has been prefilled with $' +
                  moneyAvailable +
                  ' which is the remaining amount for this payment.',
              );
            }
            newRowData.transactionDate = format(new Date(), 'yyyy-MM-dd');

            props.onRowDataChange(newRowData);
          }}
          InputLabelProps={{ shrink: true }}
        >
          {invoices.map((invoice) => (
            <MenuItem key={invoice.id} value={invoice.id}>
              {invoice.label}
            </MenuItem>
          ))}
        </TextField>
      ),
      render: (rowData) => {
        return (
          <TextField
            required
            fullWidth
            margin="dense"
            value={rowData.invoice_name}
            InputProps={{ readOnly: true, disableUnderline: true }}
          />
        );
      },
    },
    {
      title: 'Amount Allocated',
      field: 'amount',
      editable: 'onAdd',
      editComponent: (props) => (
        <TextField
          required
          fullWidth
          type="number"
          margin="dense"
          label="Amount"
          InputLabelProps={{ shrink: true }}
          value={props.value}
          onChange={(e) => {
            if (e.target.value > rowData?.amount) {
              props.onChange(rowData?.amount);
            } else {
              props.onChange(e.target.value);
            }
          }}
        />
      ),
    },
    {
      title: 'Status',
      field: 'status',
      type: 'number',
      editable: 'never',
    },
    {
      title: 'Date',
      field: 'transactionDate',
      editable: 'onAdd',
      editComponent: (props) => (
        <KeyboardDatePicker
          required
          margin="dense"
          fullWidth
          label="Transaction Date"
          format="MM/dd/yyyy"
          autoOk
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          onChange={(data) => {
            props.onChange(data);
          }}
          value={props.value}
        />
      ),
    },
  ];

  useEffect(() => {
    setRemainingAmount(roundToTwo(rowData?.amount - allocatedAmount));
  }, [allocatedAmount]);

  useEffect(() => {
    setRemainingAmount(roundToTwo(rowData?.amount));

    if (tableRef && tableRef.current) {
      tableRef.current.onQueryChange();
    }

    if (rowData?.id) {
      _distributionsList();
    }

    setInvoices(
      policy?.invoices.map((invoice) => {
        return {
          id: invoice.id,
          label: 'Invoice -  ' + invoice.invoiceNumber,
          amount: invoice.totalIncludingTax,
          remaining: invoice.totalIncludingTax - invoice.amountPaid,
        };
      }),
    );
    reset({
      policyId: policy.id,
      amount: rowData?.amount,
      referenceCode: rowData?.referenceCode,
      depositMethod: rowData?.depositMethod,
      transactionDate: new Date(rowData?.transactionDate),
    });
  }, [referredOnly, open]);

  return (
    <>
      <Dialog
        classes={{ paper: classes.paper }}
        open={open}
        onClose={handleClose}
        disableBackdropClick
      >
        <DialogTitle>
          <Grid container>
            <Grid item>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid item justify="center">
              <Typography variant="h6" className={classes.title}>
                Payment Distribution Form
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <form
            onKeyDown={(e) => handleSubmitKeyBinding(e, handleSubmit(_submitPaymentDistribution))}
          >
            <Paper style={{ marginBottom: '1rem' }}>
              <Box p="1rem">
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Controller
                      render={({ onChange, value }) => (
                        <TextField
                          required
                          fullWidth
                          disabled
                          variant="standard"
                          type="text"
                          margin="dense"
                          label="Reference Code"
                          value={value}
                          onChange={(e) => {
                            onChange(e.target.value);
                          }}
                          InputLabelProps={{ shrink: true }}
                          error={errors.referenceCode !== undefined}
                          helperText={errors.referenceCode?.message}
                        />
                      )}
                      control={control}
                      name="referenceCode"
                      rules={{
                        required: {
                          value: true,
                          message: 'Reference Code Type is required',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      render={({ onChange, value }) => (
                        <TextField
                          id="amount"
                          required
                          fullWidth
                          disabled
                          type="number"
                          margin="dense"
                          label="Amount"
                          InputLabelProps={{ shrink: true }}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }}
                          value={value}
                          onChange={(e) => {
                            onChange(e.target.value);
                          }}
                          error={errors.amount !== undefined}
                          helperText={errors.amount?.message}
                        />
                      )}
                      control={control}
                      name="amount"
                      rules={{
                        required: {
                          value: true,
                          message: 'Amount is required',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      render={({ onChange, value }) => (
                        <KeyboardDatePicker
                          required
                          margin="dense"
                          fullWidth
                          error={errors.transactionDate !== undefined}
                          disabled
                          label="Transaction Date"
                          format="MM/dd/yyyy"
                          autoOk
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          onChange={(data) => {
                            onChange(data);
                          }}
                          value={value}
                        />
                      )}
                      control={control}
                      name="transactionDate"
                      rules={{
                        required: {
                          value: true,
                          message: 'Transaction Date is required',
                        },
                      }}
                    />
                    {errors.transactionDate && (
                      <div className="invalid-feedback">{errors.transactionDate.message}</div>
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={4}>
                    <Controller
                      render={({ onChange, value }) => (
                        // todo: change this to be simple select
                        <Autocomplete
                          options={personData || []}
                          disabled
                          getOptionLabel={(option: any) => option.name}
                          defaultValue={defaultPerson}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              InputLabelProps={{ shrink: true }}
                              label="Insured Person"
                              margin="dense"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: 'disabled',
                              }}
                            />
                          )}
                          fullWidth
                          disableClearable
                          autoHighlight
                          onChange={(_, data) => {
                            onChange(data);
                          }}
                          value={value}
                        />
                      )}
                      control={control}
                      name="insuredPerson"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      render={({ value, onChange }) => (
                        <TextField
                          required
                          fullWidth
                          disabled
                          select
                          label="Deposit method"
                          margin="dense"
                          value={value}
                          InputLabelProps={{ shrink: true }}
                          error={errors.depositMethod !== undefined}
                          helperText={errors.depositMethod?.message}
                          onChange={(e) => {
                            onChange(e.target.value);
                          }}
                        >
                          <MenuItem key={value} value={value}>
                            {value}
                          </MenuItem>
                        </TextField>
                      )}
                      control={control}
                      name="depositMethod"
                      rules={{
                        required: {
                          value: true,
                          message: 'Deposit Method is required',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="select-insured"
                      fullWidth
                      disabled
                      margin="dense"
                      defaultValue="Signature Bank"
                      label="Deposit account"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Paper>

            <Paper style={{ marginBottom: '1rem' }}>
              <Box p="1rem">
                Total Amount: {remainingAmount} || Amount allocated: {allocatedAmount}
              </Box>
            </Paper>
            <MaterialTable
              title="Distribution Section"
              data={distributionData}
              columns={columns}
              components={{
                Action: (props) => {
                  return <MTableAction {...props} />;
                },
              }}
              editable={{
                isEditHidden: () => true,
                onRowAdd: (newData) =>
                  new Promise(async (resolve, reject) => {
                    try {
                      newData['amount'] = parseFloat(newData['amount']);

                      if (remainingAmount - newData['amount'] < 0) {
                        throw new Error('130001');
                      }

                      const updated: any = await _submitPaymentDistribution({
                        ...newData,
                        user_id: 'dd8cdc76-23c3-405b-b2f8-b7effa76ff3a',
                        payment_id: rowData?.id,
                      });
                      if (updated.status === 'failure') {
                        throw new Error(updated.messageCode);
                      }
                    } catch (err) {
                      customToast.error('Error while distributing money.');
                      reject();
                      throw new Error('Error while distributing money');
                    }

                    setAllocatedAmount(roundToTwo(allocatedAmount + newData['amount']));

                    newData['invoice_name'] = invoices.find(function (element) {
                      return element.id === newData['invoice_id'];
                    })?.label;

                    setDistributionData([...distributionData, newData]);

                    resolve(distributionData);
                  }),
              }}
              icons={tableIcons}
              options={{
                addRowPosition: 'first',
                search: false,
                paging: true,
                filtering: false,
                draggable: false,
                sorting: false,
                showTitle: true,
                pageSize: 10,
                pageSizeOptions: [10],
                debounceInterval: 500,
                actionsColumnIndex: -1,
                rowStyle: (rowData) => {
                  if (rowData.status === 11) {
                    return { fontFamily: 'Roboto', fontSize: 14, opacity: 0.5 };
                  } else {
                    return {
                      fontFamily: 'Roboto',
                      fontSize: 14,
                    };
                  }
                },
              }}
            />
          </form>
        </DialogContent>
        <DialogActions style={{ marginLeft: '2rem', marginRight: '2rem' }}>
          <ColorButton onClick={() => _voidPayment()} variant="contained">
            Void Payment
          </ColorButton>
          <div style={{ flex: '1 0 0' }} />

          {/* <Button
            color="primary"
            onClick={
              !isPaymentDistributeLoading ? handleSubmit(_submitPaymentDistribution) : undefined
            }
            variant="contained"
          >
            {isPaymentDistributeLoading ? <CircularProgress size={24} /> : 'Distribute'}
          </Button> */}
          <Button onClick={handleCloseSilent} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PaymentDistribution;

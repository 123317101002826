import { useReducer, useEffect, useState, useRef } from 'react';
import MaterialTable from '@material-table/core';
import {
  ChevronRight as ChevronRightIcon,
  Clear as ClearIcon,
  Check as CheckIcon,
  Search as SearchIcon,
} from '@material-ui/icons';
import { useConfirm } from 'material-ui-confirm';

import DetailPanel from './details-panel/DetailPanel';
import { useMutation } from 'react-query';

import InputWrapper from '../../../../inputs/InputWrapper';
import { validateInput } from '../../../../../../utils/validationUtils';
import { MESSAGE_TYPES } from '../../../../../../api/messageTypes';
import { callWs } from '../../../../../../api/websocket';
import * as customToast from '../../../../../toast/customToast';
import { formatValueToSave } from '../../../../../../utils/valueFormatter';
import { formatValueFrom } from '../../../../../../utils/valueFormatter';
import { handleSubmitKeyBinding } from '../../../../../../utils/formUtils';
import RulesParser from '../../../../../rule-parser/RulesParser';
import { hasPopupVisualRule } from '../../../../../../utils/utils';

export interface InsuredEntitiesProps {
  name: string;
  insuredEntity: any;
  formMethods: any;
  coverProperties: any;
  onPopup: any;
  insuredEntities: any;
  setInsuredEntities: any;
  policyId: string;
  policyTax: number;
  policySurcharge: number;
  policy: any;
}

const InsuredEntitiesForm: React.FC<InsuredEntitiesProps> = (props) => {
  const {
    name,
    insuredEntity,
    coverProperties,
    onPopup,
    insuredEntities,
    setInsuredEntities,
    policyId,
    policyTax,
    policySurcharge,
    policy,
  } = props;
  const confirm = useConfirm();

  const insuredEntityFormData = insuredEntities[name] || [];
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const popUpChangedData = useRef({});

  const {
    mutateAsync: createInsuredEntity,
    isLoading: isInsuredEntityCreateLoading,
  } = useMutation(MESSAGE_TYPES.INSURED_ENTITY_CREATE, (data: any) =>
    callWs(MESSAGE_TYPES.INSURED_ENTITY_CREATE, data),
  );

  const {
    mutateAsync: duplicateInsuredEntity,
  } = useMutation(MESSAGE_TYPES.INSURED_ENTITY_DUPLICATE, (data: any) =>
    callWs(MESSAGE_TYPES.INSURED_ENTITY_DUPLICATE, data),
  );

  const { mutateAsync: updateInsuredEntity } = useMutation(
    MESSAGE_TYPES.INSURED_ENTITY_UPDATE,
    (data: any) => callWs(MESSAGE_TYPES.INSURED_ENTITY_UPDATE, data),
  );

  const { mutateAsync: deleteInsuredEntity } = useMutation(
    MESSAGE_TYPES.INSURED_ENTITY_DELETE,
    (data: any) => callWs(MESSAGE_TYPES.INSURED_ENTITY_DELETE, data),
  );

  const _createInsuredEntity = (data) => {
    const properties = {};
    insuredEntity.data.forEach((entity) => {
      properties[entity.name] = formatValueToSave(data[entity.name], entity);
    });

    return createInsuredEntity({
      policy_id: policyId,
      entity_type: name,
      properties: {
        ...properties,
      },
    });
  };

  const _updateInsuredEntity = (data) => {
    const properties = {};

    insuredEntity.data.forEach((entity) => {
      properties[entity.name] = formatValueToSave(data[entity.name], entity);
    });

    return updateInsuredEntity({
      id: data.id,
      policy_id: policyId,
      entity_type: name,
      properties: {
        ...properties,
      },
    });
  };

  const _duplicateEntry = async (data) => {
    try {
      let newData: any = await duplicateInsuredEntity({ id: data.id });

      let test = {
        id: newData.id,
        position: newData.position,
        lossPayees: [],
        ...Object.keys(newData.properties)
          .map((key) => ({
            [key]: formatValueFrom(
              newData.properties[key],
              insuredEntity.data.find((entityDescription) => entityDescription.name === key),
              policy,
            ),
          }))
          .reduce((a, b) => Object.assign(a, b), {}),
        availableCovers:
          newData.covers.map((cover) => {
            return {
              id: cover.id,
              ...Object.keys(cover.properties)
                .map((key) => ({
                  [key]: formatValueFrom(
                    cover.properties[key],
                    coverProperties.find((coverProperty) => coverProperty.name === key),
                    policy,
                  ),
                }))
                .reduce((a, b) => Object.assign(a, b), {}),
              name:
                insuredEntity.availableCovers.find(
                  (availableCover) => availableCover.name === cover.coverName,
                )?.label ?? '',
            };
          }) ?? [],
      };

      setInsuredEntities({
        ...insuredEntities,
        [name]: [
          ...insuredEntityFormData.filter((insuredEntity) => !insuredEntity.id),
          test,
          ...insuredEntityFormData.filter((insuredEntity) => insuredEntity.id),
        ],
      });
    } catch (err) {
      console.log('Error on duplicate insured entity');
    }
  };

  const _clearInputRow = () => {
    setValidationResultCreate({});
    setFilterRows({});

    const inputRow = insuredEntityFormData.find((insuredEntity) => !insuredEntity.id);

    if (inputRow) {
      for (const [key] of Object.entries(inputRow)) {
        if (key !== 'tableData') {
          inputRow[key] = null;

          // Default fields
          if (key === 'tax') {
            inputRow[key] = policyTax;
          }

          if (key === 'farmAopDeductible') {
            inputRow[key] = policy.extraFields.policy_deductible;
          }

          if (key === 'farmOptionalWindHailDeductible') {
            inputRow[key] = policy.extraFields.special_case_deductible_1;
          }

          if (key === 'surcharge') {
            inputRow[key] = policySurcharge;
          }
        }
      }

      setInsuredEntities({
        ...insuredEntities,
        [name]: [
          {
            ...inputRow,
          },
          ...insuredEntityFormData.filter((insuredEntity) => insuredEntity.id),
        ],
      });
    }
  };

  const [validationResultCreate, setValidationResultCreate] = useState<any>({});
  const [filterRows, setFilterRows] = useState<any>({});

  const tableRef = useRef<any>();

  useEffect(() => {
    if (name) {
      if (insuredEntityFormData.filter((insuredEntity) => !insuredEntity.id).length === 0) {
        const addInsuredEntityRow = { tableData: { id: 0 } };
        insuredEntity.data
          .filter((element) => !element.showOnExpand)
          .forEach((element) => {
            addInsuredEntityRow[element.name] = null;
            // Set default fields - also need to be reset on _clearInputRow function
            if (element.name === 'tax') {
              addInsuredEntityRow[element.name] = policyTax;
            }
            if (element.name === 'liabilitySumInsured') {
              addInsuredEntityRow[element.name] = 1;
            }

            if (element.name === 'farmAopDeductible') {
              addInsuredEntityRow[element.name] = policy.extraFields.policy_deductible;
            }

            if (element.name === 'farmOptionalWindHailDeductible') {
              addInsuredEntityRow[element.name] = policy.extraFields.special_case_deductible_1;
            }

            if (element.name === 'surcharge') {
              addInsuredEntityRow[element.name] = policySurcharge;
            }
          });

        setInsuredEntities({
          ...insuredEntities,
          [name]: [addInsuredEntityRow, ...insuredEntityFormData],
        });
      }
      // _evaluateRules({ ...rowData, ...getValues() }, false);

      _clearInputRow();
    }
  }, [name]);

  const _onCreateInsuredEntity = async () => {
    try {
      setFilterRows({});
      const addRow = {
        ...insuredEntities[name].find((insuredEntity) => !insuredEntity.id),
      };

      setValidationResultCreate({});
      const validationResults: any = {};

      let isValid = true;

      Object.keys(addRow).forEach((key) => {
        const element = insuredEntity.data.find((entity) => entity.name === key);
        if (element) {
          const validationResult = validateInput(addRow, element);
          validationResults[key] = validationResult;
          if (!validationResult.isValid) {
            isValid = false;
          }
        }
      });
      setValidationResultCreate({ ...validationResults });

      if (!isValid) {
        return;
      }

      const savedData: any = await _createInsuredEntity(addRow);
      const properties = {};

      insuredEntity.data.forEach((entity) => {
        properties[entity.name] = formatValueFrom(
          savedData.properties[entity.name],
          entity,
          policy,
        );
      });

      const newData = {
        id: savedData.id,
        position: savedData.position,
        ...properties,
        lossPayees: [],
        availableCovers: [],
      };

      for (const [key] of Object.entries(addRow)) {
        if (key !== 'tableData') {
          addRow[key] = null;
        }
      }

      setInsuredEntities({
        ...insuredEntities,
        [name]: [
          {
            ...addRow,
          },
          newData,
          ...insuredEntityFormData.filter((insuredEntity) => insuredEntity.id),
        ],
      });

      // setTimeout(() => {
      //   tableRef.current.onToggleDetailPanel(
      //     [tableRef.current.dataManager.sortedData.findIndex((item) => item.id === newData.id)],
      //     tableRef.current.props.detailPanel[0](newData).render,
      //   );
      // }, 100);

      setValidationResultCreate({});
    } catch (err) {
      console.log('error', err);
      customToast.error(
        err.messageCode,
        {
          id: 'insured-entity-create-error',
        },
        err.messages,
      );
    }
  };

  const insuredEntitiesColumns: any = [
    {
      title: '',
      field: 'id',
      hidden: true,
    },
    {
      title: name === 'horses' ? ' Horse number' : 'Position',
      field: 'position',
      editComponent: ({ value }) => <span>{value}</span>,
      filtering: false,
      width: 3,
    },
  ];

  const _filter = (tableRef: React.MutableRefObject<any>) => {
    const filters = {};
    tableRef?.current?.state.columns.map((column: any) => {
      const searchRow = tableRef.current.state.data.find((data) => !data.id);

      if (
        searchRow[column.field] !== null &&
        searchRow[column.field] !== undefined &&
        searchRow[column.field] !== ''
      ) {
        let filterValue;
        switch (typeof searchRow[column.field]) {
          case 'number':
            filterValue = !isNaN(searchRow[column.field])
              ? searchRow[column.field]?.toString()
              : undefined;
            break;
          case 'object':
            filterValue = searchRow[column.field]?.join(',');
            break;
          default:
            filterValue = searchRow[column.field]?.toString();
        }

        filters[column.field] = filterValue;
      }
    });
    setFilterRows(filters);
  };

  insuredEntity.data
    .filter((element) => !element.showOnExpand)
    .forEach((element, index) => {
      insuredEntitiesColumns.push({
        title: element.label + (element?.validation?.front?.required ? ' *' : ''),
        field: element.name,
        defaultFilter: filterRows[element.name] ?? '',
        customFilterAndSearch: (value, rowData) => {
          if (filterRows[element.name]) {
            if (element.type === 'multi-enum') {
              const valueArr = value.split(',');
              const containsValue = rowData[element.name].some(
                (data) => valueArr.indexOf(data) > -1,
              );

              return !rowData.id || containsValue;
            } else {
              return !rowData.id || rowData[element.name]?.toString()?.indexOf(value) > -1;
            }
          }

          return !rowData.id;
        },
        validate: (rowData) => validateInput(rowData, element),
        editComponent: ({ value, onChange, error, helperText, rowData }) => {
          return (
            !element.hidden && (
              <InputWrapper
                properties={element}
                value={value}
                onChange={onChange}
                error={error}
                helperText={helperText}
                displayLabel={false}
                evaluateRules={(newValue) => {
                  _evaluateRules(
                    {
                      ...rowData,
                      [element.name]: newValue,
                    },
                    hasPopupVisualRule(element.visualRules),
                  );
                }}
                referencedData={{ policy }}
              />
            )
          );
        },
        render: (rowData) => {
          if (rowData.id) {
            return (
              <InputWrapper
                properties={element}
                value={rowData[element.name]}
                onChange={() => {}}
                displayMode="read"
                displayLabel={false}
                referencedData={{ policy }}
              />
            );
          } else {
            const validation = validationResultCreate[element.name];

            return (
              !element.hidden && (
                <InputWrapper
                  properties={element}
                  displayLabel={false}
                  error={!(validation?.isValid ?? true)}
                  helperText={validation?.helperText}
                  value={rowData[element.name]}
                  onChange={(value) => {
                    const newRow = insuredEntityFormData.find((insuredEntity) => !insuredEntity.id);
                    newRow[element.name] = value;

                    if (validation) {
                      setValidationResultCreate({
                        ...validationResultCreate,
                        [element.name]: validateInput(rowData, element),
                      });
                    }
                    forceUpdate();
                  }}
                  onKeyDown={(e) => handleSubmitKeyBinding(e, _onCreateInsuredEntity)}
                  evaluateRules={() =>
                    _evaluateRules(rowData, hasPopupVisualRule(element.visualRules))
                  }
                  referencedData={{ policy }}
                />
              )
            );
          }
        },
      });
    });

  const _evaluateRules = (rowData, popup = true) => {
    try {
      const changedInsuredEntityData = insuredEntity.data.map((entity) => {
        if (entity.visualRules && entity.visualRules.length > 0 && !entity.showOnExpand) {
          const popupRule = entity?.visualRules?.find(
            (visualRule) => visualRule.actionTrue === 'popup' || visualRule.actionFalse === 'popup',
          );
          new RulesParser(
            entity.visualRules,
            {
              entity: rowData,
              field: entity,
            },
            popup
              ? {
                  handler: onPopup,
                  elements: insuredEntity.data,
                  key: 'name',
                  currentValue: popupRule
                    ? rowData[popupRule.popUpTargetProperty.split('.')[1]]
                    : undefined,
                  submit: async (value) => {
                    const popupTargetProperty = popupRule.popUpTargetProperty.split('.')[1];
                    try {
                      if (popupRule) {
                        const popupEntity = insuredEntity.data.find(
                          (data) => data.name === popupTargetProperty,
                        );
                        const updatedProperties = {
                          [popupTargetProperty]: value,
                        };
                        if (rowData.id) {
                          popUpChangedData.current = {
                            ...popUpChangedData.current,
                            ...updatedProperties,
                          };
                        } else {
                          const inputRow = insuredEntityFormData.find(
                            (insuredEntity) => !insuredEntity.id,
                          );

                          if (inputRow) {
                            setInsuredEntities({
                              ...insuredEntities,
                              [name]: [
                                {
                                  ...inputRow,
                                  ...updatedProperties,
                                },
                                ...insuredEntityFormData.filter(
                                  (insuredEntity) => insuredEntity.id,
                                ),
                              ],
                            });
                          }
                        }
                      }
                    } catch (err) {
                      customToast.error(
                        err.messageCode,
                        {
                          id: 'insured-entity-update-error',
                        },
                        err.messages,
                      );
                    }
                  },
                }
              : undefined,
          ).evaluateRules();
        }

        return entity;
      });

      insuredEntity.data = changedInsuredEntityData;
    } catch (err) {
      console.log('evaluate rules err', err);
    }
  };
  const _handleDelete = async (data) => {
    let forceEntityDelete = false;
    await confirm({
      confirmationText: 'Delete Insured Entity',
      title: 'Delete Insured Entity',
      description:
        'Are you sure you want to delete this entity? This action will affect policy premium and cannot be undone.',
      // allowClose: false,
    })
      .then(async (data: any) => {
        forceEntityDelete = true;
      })
      .catch((err) => {
        throw new Error('Cancelled');
      });
    try {
      await deleteInsuredEntity({
        id: data.id,
        forceEntityDelete: forceEntityDelete,
      });
      const rows = [...insuredEntityFormData.filter((entity) => entity.id !== data.id)];
      const rowsGreaterThanPosition = rows
        .filter((row) => row.id && row.position > data.position)
        .map((row) => ({
          ...row,
          position: policy.status == 'CREATED_FOR_MTA' ? row.position : row.position - 1,
        }));
      setInsuredEntities({
        ...insuredEntities,
        [name]: [
          rows.find((row) => !row.id),
          ...rowsGreaterThanPosition,
          ...rows.filter((row) => row.id && row.position < data.position),
        ],
      });
    } catch (err) {
      console.log(err);
      customToast.error(err.messageCode, {
        id: 'insured-entity-delete-error-' + data.id,
      });
    }
  };

  return (
    <div>
      <MaterialTable
        title="Insured Entities"
        data={insuredEntityFormData}
        // .sort((a, b) => (a.position > b.position ? 1 : -1))}
        columns={insuredEntitiesColumns}
        tableRef={tableRef}
        components={{
          FilterRow: () => {
            return <></>;
          },
        }}
        actions={[
          (rowData) => ({
            icon: 'library_add',
            tooltip: 'Duplicate Entry',
            onClick: (event, rowData) => {
              _duplicateEntry(rowData);
            },
            hidden: !rowData.id,
          }),
          (rowData) => ({
            icon: SearchIcon,
            tooltip: 'Search',
            onClick: () => {
              setValidationResultCreate({});
              _filter(tableRef);
            },
            hidden: rowData.id,
          }),
          (rowData) => ({
            icon: CheckIcon,
            tooltip: 'Save',
            onClick: _onCreateInsuredEntity,
            hidden: rowData.id || isInsuredEntityCreateLoading,
          }),
          (rowData) => ({
            icon: ClearIcon,
            tooltip: 'Clear',
            onClick: (event, rowData) => {
              _clearInputRow();
            },
            hidden: rowData.id,
          }),
        ]}
        editable={{
          isDeleteHidden: (rowData) => !rowData.id,
          isEditHidden: (rowData) => !rowData.id,
          onRowDelete: (data: any) =>
            new Promise<void>(async (resolve, reject) => {
              try {
                await _handleDelete(data);
                resolve();
              } catch (err) {
                resolve();
              }
              // try {
              //   await deleteInsuredEntity({
              //     id: data.id,
              //     forceInvoiceGeneration: forceInvoiceGeneration,
              //   });
              //   const rows = [...insuredEntityFormData.filter((entity) => entity.id !== data.id)];
              //   const rowsGreaterThanPosition = rows
              //     .filter((row) => row.id && row.position > data.position)
              //     .map((row) => ({
              //       ...row,
              //       position: row.position - 1,
              //     }));
              //   setInsuredEntities({
              //     ...insuredEntities,
              //     [name]: [
              //       rows.find((row) => !row.id),
              //       ...rowsGreaterThanPosition,
              //       ...rows.filter((row) => row.id && row.position < data.position),
              //     ],
              //   });
              //   resolve();
              // } catch (err) {
              //   customToast.error(err.messageCode, {
              //     id: 'insured-entity-delete-error-' + data.id,
              //   });
              //   reject();
              // }
            }),

          onRowUpdate: (newData: any, oldData: any) =>
            new Promise<void>(async (resolve, reject) => {
              try {
                const updated: any = await _updateInsuredEntity({
                  ...oldData,
                  ...newData,
                  ...popUpChangedData.current,
                });
                const updatedIndex = insuredEntityFormData.findIndex(
                  (entity: any) => entity.id === oldData.id,
                );
                const properties = {};
                insuredEntity.data.forEach((entity) => {
                  properties[entity.name] = formatValueFrom(
                    updated.properties[entity.name],
                    entity,
                    policy,
                  );
                });

                setInsuredEntities({
                  ...insuredEntities,
                  [name]: [
                    ...insuredEntityFormData.slice(0, updatedIndex),
                    {
                      ...oldData,
                      position: updated.position,
                      ...properties,
                      availableCovers:
                        updated.covers.map((cover) => {
                          return {
                            id: cover.id,
                            ...Object.keys(cover.properties)
                              .map((key) => ({
                                [key]: formatValueFrom(
                                  cover.properties[key],
                                  coverProperties.find(
                                    (coverProperty) => coverProperty.name === key,
                                  ),
                                  policy,
                                ),
                              }))
                              .reduce((a, b) => Object.assign(a, b), {}),
                            name:
                              insuredEntity.availableCovers.find(
                                (availableCover) => availableCover.name === cover.coverName,
                              )?.label ?? '',
                          };
                        }) ?? [],
                      tableData: { showDetailPanel: undefined },
                    },
                    ...insuredEntityFormData.slice(updatedIndex + 1, insuredEntityFormData.length),
                  ],
                });
                popUpChangedData.current = {};
                resolve();
              } catch (err) {
                console.error(err);
                customToast.error(
                  err.messageCode,
                  {
                    id: 'insured-entity-update-error-' + oldData.id,
                  },
                  err.messages,
                );
                reject();
              }
            }),
          onRowUpdateCancelled: () => {
            popUpChangedData.current = {};
          },
        }}
        options={{
          search: false,
          paging: true,
          filtering: true,
          sorting: false,
          draggable: false,
          showTitle: false,
          pageSize: 8,
          paginationType: 'stepped',
          rowStyle: { fontFamily: 'Roboto', fontSize: 14 },
          actionsColumnIndex: -1,
        }}
        detailPanel={[
          (rowData) => ({
            disabled: !rowData.id,
            icon: () => <ChevronRightIcon style={{ display: !rowData.id ? 'none' : 'block' }} />,
            render: () => {
              return (
                <DetailPanel
                  rowId={rowData.id}
                  rowIndex={insuredEntityFormData.findIndex((entity) => entity.id === rowData.id)}
                  name={name}
                  policyId={policyId}
                  insuredEntityDescription={{ ...insuredEntity }}
                  insuredEntities={insuredEntities}
                  setInsuredEntities={setInsuredEntities}
                  coverProperties={coverProperties}
                  onPopup={onPopup}
                />
              );
            },
          }),
        ]}
      />
    </div>
  );
};

export default InsuredEntitiesForm;

import {
  Grid,
  Button,
  CircularProgress,
  Dialog,
  TextField,
  MenuItem,
  DialogContent,
  DialogActions,
  DialogTitle,
  Table,
  TableContainer,
  TableHead,
  Paper,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import { callWs } from '../../../api/websocket';
import { MESSAGE_TYPES } from '../../../api/messageTypes';
import * as customToast from '../../toast/customToast';
import BooleanQuestion from '../questions/boolean-question';

const inputDescriptions = [
  {
    name: 'mtaNumber',
    label: 'MTA Number',
    type: 'text',
    presentation: 'text',
    validation: {
      front: { required: false },
    },
  },
  {
    name: 'effectiveDate',
    label: 'Effective Date',
    type: 'date',
    presentation: 'text',
    validation: {
      front: { required: true },
    },
  },
  {
    name: 'status',
    label: 'MTA Status',
    type: 'text',
    presentation: 'text',
    validation: {
      front: { required: true },
    },
  },
];

const ActivateMtaDialog = (props) => {
  const { activateOpen, handleClose, policy, fetchPolicy } = props;

  const {
    control,
    watch,
    getValues,
    reset,
    handleSubmit,
    trigger,
    errors,
    setValue,
  } = useForm<any>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      ...inputDescriptions
        .map((inputDescription) => ({
          [inputDescription.name]: inputDescription.type === 'boolean' ? false : null,
        }))
        .reduce((acc, next) => Object.assign(acc, next), {}),
      // mergeInvoices: true,
    },
  });

  const mergeInvoicesValue = watch('mergeInvoices');
  const selectedNumberOfInvoices = watch('installments', '1');
  const [response, setResponse] = useState(0);
  const [difference, setDifference] = useState(0);
  const [errorMessage, setError] = useState(null);
  const [installments, setInstallments] = useState<any>([1]);
  const [canMerge, setCanMerge] = useState(true);
  const [invoices, setInvoices] = useState({});

  const {
    mutateAsync: mtaAnalysis,
    isLoading: isMtaInvoiceLoading,
  } = useMutation(MESSAGE_TYPES.MTA_INVOICE_ANALYSIS, (data: any) =>
    callWs(MESSAGE_TYPES.MTA_INVOICE_ANALYSIS, data),
  );

  const getAnalysisAndSaveResponse = async () => {
    try {
      const analysisResponse: any = await mtaAnalysis({ id: policy.id });
      const amount = analysisResponse?.amounts?.totalWithTaxesAndSurcharge;
      setInstallments(analysisResponse?.newInvoiceInstallmentOptions);
      setCanMerge(analysisResponse?.canMerge);
      setValue('mergeInvoices', analysisResponse?.canMerge);
      setResponse(amount);
      setDifference(analysisResponse?.amounts?.totalDiff);
      setInvoices(analysisResponse?.invoicesPreview);
      setError(analysisResponse.errorMessage);
    } catch (err) {
      customToast.error(err.messageCode, {
        toastId: `activate-mta-failed`,
      });
    }
  };

  let selectedInv;
  if (mergeInvoicesValue) {
    selectedInv = invoices['merge'];
  } else {
    selectedInv = invoices[selectedNumberOfInvoices];
  }
  const {
    mutateAsync: activateMta,
    isLoading: isActivateMtaLoading,
  } = useMutation(MESSAGE_TYPES.MTA_ACTIVATE, (data: any) =>
    callWs(MESSAGE_TYPES.MTA_ACTIVATE, data),
  );

  const handleActivateMta = async () => {
    try {
      const mta = policy?.mtas.find((element) => element.policyId === policy.id);
      await activateMta({
        id: mta?.id,
        mergeInvoices: mergeInvoicesValue,
        numberOfInvoices: selectedNumberOfInvoices,
      });
      fetchPolicy();
      window.location.reload();
    } catch (err) {
      customToast.error(err.messageCode, {
        toastId: `activate-mta-failed`,
      });
    }
  };

  useEffect(() => {
    if (activateOpen) {
      getAnalysisAndSaveResponse();
    }
  }, [activateOpen]);

  return (
    <>
      <Dialog open={activateOpen} onClose={handleClose} scroll="body">
        <DialogTitle>Activate MTA</DialogTitle>
        {isMtaInvoiceLoading ? (
          <CircularProgress size={40} />
        ) : (
          <>
            <DialogContent>
              {invoices !== null || undefined ? (
                <Grid container item spacing={4}>
                  <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'center' }}>
                    <div>
                      The new annual total is:
                      <h2
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      >
                        {response} $
                      </h2>
                      <br />
                      The difference from the previous total is:
                      {/* <br /> */}
                      <h2
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      >
                        {difference} $
                      </h2>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'center' }}>
                    {'You have the following options based on the current status of the policy'}
                  </Grid>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">Invoice Number</TableCell>
                          <TableCell align="center">Amount</TableCell>
                          <TableCell align="center">Due Date</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {selectedInv?.map((item) => (
                          <TableRow>
                            <TableCell align="center">{item.invoiceNumber}</TableCell>
                            <TableCell align="center">{item.amount}</TableCell>
                            <TableCell align="center">{item.dueDate}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Grid item xs={6} md={6} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Controller
                      render={({ onChange, value }) => (
                        <BooleanQuestion
                          questionName="mergeInvoices"
                          questionLabel="Merge Invoices?"
                          labelPlacement="top"
                          disabled={!canMerge}
                          onChange={(data) => {
                            onChange(data);
                          }}
                          value={value}
                        />
                      )}
                      defaultValue={canMerge}
                      control={control}
                      name="mergeInvoices"
                    />
                  </Grid>
                  {mergeInvoicesValue === false ? (
                    <Grid item xs={6} md={6} style={{ display: 'flex', justifyContent: 'center' }}>
                      <Controller
                        render={({ value, onChange }) => (
                          <TextField
                            required
                            fullWidth
                            select
                            label="Invoices"
                            margin="dense"
                            value={value}
                            InputLabelProps={{ shrink: true }}
                            error={errors.depositMethod !== undefined}
                            helperText={errors.depositMethod?.message}
                            onChange={(e) => {
                              onChange(e.target.value);
                            }}
                          >
                            {installments.map((invoice) => (
                              <MenuItem key={invoice} value={invoice}>
                                {invoice}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                        defaultValue={installments[0]}
                        control={control}
                        name="installments"
                        rules={{
                          required: {
                            value: true,
                            message: 'Installments are required',
                          },
                        }}
                      />
                    </Grid>
                  ) : null}
                </Grid>
              ) : (
                <></>
              )}
            </DialogContent>
            <DialogActions>
              <Grid item lg={6} xs={6} md={6} style={{ display: 'flex', justifyContent: 'center' }}>
                <Button onClick={handleActivateMta} color="primary" variant="contained">
                  {!isActivateMtaLoading ? (
                    'Activate'
                  ) : (
                    <CircularProgress color="secondary" size={24} />
                  )}
                </Button>
              </Grid>
              <Grid item lg={6} xs={6} md={6} style={{ display: 'flex', justifyContent: 'center' }}>
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
              </Grid>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};

export default ActivateMtaDialog;

import { Paper, Grid, Box, Typography, Button, CircularProgress } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useMutation } from 'react-query';
import { MESSAGE_TYPES } from '../../../../api/messageTypes';
import { callWs } from '../../../../api/websocket';
import { handleSubmitKeyBinding, rulesFromDescription } from '../../../../utils/formUtils';

import { getInputDescriptionsFromReferences } from '../../../../utils/utils';
import InputWrapper, { InputProperty } from '../../inputs/InputWrapper';
import * as customToast from '../../../../shared/toast/customToast';
import RulesParser from '../../../rule-parser/RulesParser';

interface SectionExtraFields {
  panelName?: string;
  policyData: InputProperty[];
  references: { refersTo: string; key: string }[];
}

export interface SectionExtraFieldsWrapperProps {
  sectionName: string;
  extraFields: SectionExtraFields;
  productDescription: any;
  policy: any;
  setIsPolicyChanged: (changed: boolean) => void;
  fetchPolicy: () => void;
}

const SectionExtraFieldsWrapper: React.FC<SectionExtraFieldsWrapperProps> = (props) => {
  const {
    sectionName,
    extraFields: { policyData = [], references = [], panelName = 'Additional Fields' },
    productDescription = {},
    policy,
    setIsPolicyChanged,
    fetchPolicy,
  } = props;

  const inputReferencesDescriptions = getInputDescriptionsFromReferences(
    references,
    productDescription,
  );

  const [inputDescriptions, setInputDescriptions] = useState<any[]>([
    ...policyData,
    ...inputReferencesDescriptions,
  ]);

  const defaultValues = {
    policyData: policyData
      .map((element) => ({
        [element.name]: element.defaultValue ?? null,
      }))
      .reduce((a, b) => ({ ...a, ...b }), {}),
    questions: inputReferencesDescriptions
      .filter((element) => element.reference === 'questions')
      .map((element) => ({
        [element.name]: element.defaultValue ?? null,
      }))
      .reduce((a, b) => ({ ...a, ...b }), {}),
  };

  const {
    mutateAsync: updatePolicyExtraFields,
    isLoading: isPolicyExtraFieldsLoading,
  } = useMutation(MESSAGE_TYPES.POLICY_EXTRA_FIELDS_UPDATE, (data: any) =>
    callWs(MESSAGE_TYPES.POLICY_EXTRA_FIELDS_UPDATE, data),
  );

  const _updatePolicyExtraFields = async (data) => {
    try {
      if (isPolicyExtraFieldsLoading) {
        return;
      }

      await updatePolicyExtraFields({
        policyId: policy.id,
        sectionName,
        data,
      });
      customToast.success('Additional Policy fields updated successfully', {
        id: 'policy-extra-fields-update-success',
      });
      fetchPolicy();
      setIsPolicyChanged(false);
    } catch (err) {
      customToast.error(err.messageCode);
      console.log('error while updating policy extra fields');
    }
  };

  const _evaluateRules = (policyData) => {
    try {
      const changedInsuredEntityData = inputDescriptions.map((entity) => {
        if (entity.visualRules && entity.visualRules.length > 0) {
          new RulesParser(
            entity.visualRules,
            {
              policyData: policyData,
              field: entity,
            },
            undefined,
          ).evaluateRules();
        }

        return entity;
      });

      setInputDescriptions(changedInsuredEntityData);
    } catch (err) {
      console.error('Evaluate rules error', err);
    }
  };

  const { control, errors, reset, getValues, handleSubmit } = useForm({
    defaultValues,
    mode: 'all',
  });

  useEffect(() => {
    const policyFieldValues = {};
    policyData.forEach(
      (policyField) =>
        (policyFieldValues[policyField.name] = policy?.extraFields?.[policyField.name]),
    );
    const questionFieldValues = {};
    inputDescriptions
      .filter((element) => element.reference === 'questions')
      .forEach((element) => {
        questionFieldValues[element.name] = policy?.questionAnswers?.find(
          (questionAnswer) => questionAnswer.questionId === element.name,
        )?.value;
      });

    reset({
      policyData: policyFieldValues,
      questions: questionFieldValues,
    });

    _evaluateRules({ ...policyFieldValues, uWQuestions: { ...questionFieldValues } });
  }, [policy]);

  return inputDescriptions.length > 0 ? (
    <Paper>
      <form onKeyDown={(e) => handleSubmitKeyBinding(e, handleSubmit(_updatePolicyExtraFields))}>
        <Box p="1rem">
          <Grid container spacing={3} direction="column">
            <Grid item>
              <Typography variant="h6">{panelName}</Typography>
            </Grid>

            <Grid container item spacing={2}>
              {inputDescriptions.map(
                (inputDescription) =>
                  !inputDescription.hidden && (
                    <Grid key={inputDescription.name} item xs={12} md={4}>
                      <Controller
                        render={({ onChange, value }) => (
                          <InputWrapper
                            properties={inputDescription}
                            value={value}
                            afterOnChange={() => {
                              setIsPolicyChanged(true);
                            }}
                            onChange={(newValue) => {
                              onChange(newValue);
                            }}
                            evaluateRules={() => {
                              _evaluateRules({
                                ...getValues()['policyData'],
                                uWQuestions: { ...getValues()['questions'] },
                              });
                            }}
                            displayLabel
                            error={
                              !!errors?.[inputDescription.reference ?? 'policyData']?.[
                                inputDescription.name
                              ]
                            }
                            helperText={
                              errors?.[inputDescription.reference ?? 'policyData']?.[
                                inputDescription.name
                              ]?.message
                            }
                          />
                        )}
                        control={control}
                        name={`${inputDescription.reference ?? 'policyData'}.${
                          inputDescription.name
                        }`}
                        rules={rulesFromDescription(inputDescription)}
                      />
                    </Grid>
                  ),
              )}
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={6}>
            <Grid item xs={4} md>
              <Button color="primary" onClick={handleSubmit(_updatePolicyExtraFields)}>
                {isPolicyExtraFieldsLoading ? (
                  <CircularProgress size={24} />
                ) : (
                  'Update Additional Policy fields'
                )}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
    </Paper>
  ) : (
    <></>
  );
};

export default SectionExtraFieldsWrapper;

import { memo } from 'react';
import MaterialTable, { MTableBodyRow } from '@material-table/core';
import { Checkbox } from '@material-ui/core';

const EndorsementsTable = () => {
  return (
    <MaterialTable
      // style={{ width: '100%' }}
      columns={[
        {
          title: 'Endorsment No',
          field: 'endorsmentNo',
        },
        {
          title: 'Effective Date',
          field: 'effectiveDate',
        },
        { title: 'Changes(auto computed)', field: 'changesAutoComputed' },
        { title: 'Original Premium', field: 'originalPremium' },
        {
          title: 'Endorsment Premium',
          field: 'endorsmentPremium',
        },
        { title: 'New Premium', field: 'newPremium' },
        {
          title: 'Documents',
          field: 'documents',
          render: (data) => <Checkbox checked={data.bind} color="primary" />,
          //    render: (data) => (<Button color="primary" fullWidthvariant="outlined" onClick={​​​​​handleOpen}​​​​​>Edit</Button>),
        },
        { title: 'Status(quoted/active)', field: 'status' },
        {
          title: 'Bind',
          field: 'bind',
          render: (data) => <Checkbox checked={data.bind} color="primary" />,
        },
      ]}
      data={[
        {
          endorsmentNo: 62,
          effectiveDate: '09/16/2020',
          changesAutoComputed: 'cubilia',
          originalPremium: 94,
          endorsmentPremium: 80,
          newPremium: 75,
          document: false,
          status: 'Active',
          bind: true,
        },
        {
          endorsmentNo: 52,
          effectiveDate: '11/17/2020',
          changesAutoComputed: 'penatibus',
          originalPremium: 62,
          endorsmentPremium: 76,
          newPremium: 31,
          document: true,
          status: 'Active',
          bind: true,
        },
        {
          endorsmentNo: 19,
          effectiveDate: '08/03/2021',
          changesAutoComputed: 'justo',
          originalPremium: 44,
          endorsmentPremium: 23,
          newPremium: 75,
          document: true,
          status: 'Active',
          bind: true,
        },
        {
          endorsmentNo: 63,
          effectiveDate: '11/27/2020',
          changesAutoComputed: 'molestie',
          originalPremium: 98,
          endorsmentPremium: 54,
          newPremium: 5,
          document: false,
          status: 'Quoted',
          bind: false,
        },
        {
          endorsmentNo: 51,
          effectiveDate: '12/27/2020',
          changesAutoComputed: 'eget',
          originalPremium: 89,
          endorsmentPremium: 83,
          newPremium: 42,
          document: true,
          status: 'Quoted',
          bind: true,
        },
        {
          endorsmentNo: 100,
          effectiveDate: '01/24/2021',
          changesAutoComputed: 'volutpat',
          originalPremium: 61,
          endorsmentPremium: 43,
          newPremium: 15,
          document: false,
          status: 'Quoted',
          bind: true,
        },
        {
          endorsmentNo: 18,
          effectiveDate: '01/15/2021',
          changesAutoComputed: 'montes',
          originalPremium: 42,
          endorsmentPremium: 100,
          newPremium: 19,
          document: true,
          status: 'Quoted',
          bind: false,
        },
        {
          endorsmentNo: 6,
          effectiveDate: '08/31/2020',
          changesAutoComputed: 'luctus',
          originalPremium: 79,
          endorsmentPremium: 85,
          newPremium: 73,
          document: false,
          status: 'Quoted',
          bind: false,
        },
        {
          endorsmentNo: 91,
          effectiveDate: '12/25/2021',
          changesAutoComputed: 'dapibus',
          originalPremium: 7,
          endorsmentPremium: 74,
          newPremium: 30,
          document: true,
          status: 'Quoted',
          bind: true,
        },
        {
          endorsmentNo: 23,
          effectiveDate: '05/14/2021',
          changesAutoComputed: 'eu',
          originalPremium: 61,
          endorsmentPremium: 67,
          newPremium: 85,
          document: true,
          status: 'Quoted',
          bind: true,
        },
      ]}
      title="Policy Invoices"
      components={{
        Row: (props) => (
          <MTableBodyRow
            {...props}
            onDoubleClick={(e) => {
              props.actions[1]().onClick(e, props.data);
            }}
          />
        ),
      }}
      editable={{
        isDeleteHidden: () => true,
        onRowAdd: (newData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              resolve('');
            }, 1000);
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              resolve('');
            }, 1000);
          }),
        onRowDelete: (oldData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              resolve('');
            }, 1000);
          }),
      }}
      options={{
        search: false,
        paging: false,
        filtering: true,
        doubleHorizontalScroll: true,
        addRowPosition: 'first',
        loadingType: 'linear',
        draggable: false,
        showTitle: false,
        rowStyle: (rowData) => ({
          fontFamily: 'Roboto',
          fontSize: 14,
        }),
        // maxBodyHeight: '50vh',
        // headerStyle: { position: 'sticky', top: 0 },
      }}
    />
  );
};

export default memo(EndorsementsTable);

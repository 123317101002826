import React, { memo, useState, useEffect } from 'react';
import MaterialTable from '@material-table/core';
import { Checkbox, Button, CircularProgress } from '@material-ui/core';
import { format } from 'date-fns';
import { useMutation } from 'react-query';
import { useForm } from 'react-hook-form';

import InvoiceFormModal from './invoice-form-modal/InvoiceFormModal';
import InvoiceDetailsModal from './invoice-details-modal/InvoiceDetailsModal';
import { MESSAGE_TYPES } from '../../../../../api/messageTypes';
import { callWs } from '../../../../../api/websocket';
import * as customToast from '../../../../toast/customToast';
import ErrorChecking from './ErrorChecking';
import { dateFormater } from '../../../../../utils/valueFormatter';
import InvoiceUnVoidModal from './invoice-unvoid-modal/InvoiceUnVoidModal';

const PolicyInvoices: React.FC<any> = (props) => {
  const { policy, fetchPolicy } = props;

  const [invoiceEditing, setInvoiceEditing] = useState();
  const [invoiceDetails, setInvoiceDetails] = useState();
  const [invoiceIdThatIsBeingAccessed, setInvoiceIdThatIsBeingAccessed] = useState('');
  const [invoiceDueDateConfirmation, setInvoiceDueDateConfirmation] = useState(false);

  const customCellStyle = (_, rowData) => ({
    opacity: rowData.void ? 0.5 : 1,
  });

  useEffect(() => {
    fetchPolicy();
  }, []);

  const {
    mutateAsync: getDocument,
    isLoading: isDocumentLoading,
  } = useMutation(MESSAGE_TYPES.DOCUMENT_GET, (path: any) =>
    callWs(MESSAGE_TYPES.DOCUMENT_GET, { path }),
  );

  const {
    mutateAsync: sendDocumentByEmail,
    isLoading: isSendDocumentByEmailLoading,
  } = useMutation(MESSAGE_TYPES.DOCUMENT_SEND_BY_EMAIL, (data: any) =>
    callWs(MESSAGE_TYPES.DOCUMENT_SEND_BY_EMAIL, data),
  );

  const {
    mutateAsync: voidInvoice,
    isLoading: isVoidInvoiceLoading,
  } = useMutation(MESSAGE_TYPES.POLICY_INVOICE_VOID, (data: any) =>
    callWs(MESSAGE_TYPES.POLICY_INVOICE_VOID, data),
  );

  const _handleVoidInvoice = async (data) => {
    setInvoiceIdThatIsBeingAccessed(data.id);
    try {
      await voidInvoice({
        id: data.id,
      });
      fetchPolicy();
      customToast.success('Invoice voiding was successful');
    } catch (err) {
      customToast.error(err.messageCode);
    }
  };

  const _handleUnVoidInvoicePopUp = (data) => {
    setInvoiceIdThatIsBeingAccessed(data.id);
    setInvoiceDueDateConfirmation(true);
  };

  const _handleCloseUnVoidInvoicePopUp = () => {
    setInvoiceDueDateConfirmation(false);
    fetchPolicy();
  };

  const _fetchDocument = async (path) => {
    const urlResponse: any = await getDocument(path);
    window.open(urlResponse, '_blank');
  };

  const _emailDocument = async (data) => {
    setInvoiceIdThatIsBeingAccessed(data.id);
    try {
      await sendDocumentByEmail({
        emails: data.email,
        name: '',
        path: data.path,
        customerName: data.name,
      });
      customToast.success('Email was send successfully');
    } catch (err) {
      customToast.error(err.messageCode);
    }
  };

  return (
    <>
      <MaterialTable
        style={{ width: '100%' }}
        columns={[
          {
            title: 'Error Check',
            field: 'invoiceErrors',
            render: (rowData) => <ErrorChecking errorCode={rowData.emailingProblem} />,
          },
          {
            title: 'Invoice Number',
            field: 'invoiceNumber',
            type: 'numeric',
            defaultSort: 'asc',
            cellStyle: customCellStyle,
          },
          {
            title: 'Installment',
            field: 'installment',
            type: 'numeric',
            cellStyle: customCellStyle,
          },
          {
            title: 'Issue Date',
            field: 'issueDate',
            type: 'date',
            render: (rowData) => format(dateFormater(rowData.issueDate), 'MM-dd-yyyy'),
            cellStyle: customCellStyle,
          },
          {
            title: 'Due date',
            field: 'dueDate',
            type: 'date',
            render: (rowData) => format(dateFormater(rowData.dueDate), 'MM-dd-yyyy'),
            cellStyle: customCellStyle,
          },
          {
            title: 'Total (Excl. Tax)',
            field: 'totalExcludingTax',
            type: 'numeric',
            cellStyle: customCellStyle,
          },
          { title: 'Tax', field: 'tax', type: 'numeric', cellStyle: customCellStyle },
          {
            title: 'Total (Incl. Tax)',
            field: 'totalIncludingTax',
            type: 'numeric',
            cellStyle: customCellStyle,
          },
          { title: 'Status', field: 'status', cellStyle: customCellStyle },
          {
            title: 'Total Paid',
            field: 'amountPaid',
            type: 'numeric',
            cellStyle: customCellStyle,
          },
          {
            title: 'Tax Paid',
            field: 'taxPaid',
            type: 'numeric',
            cellStyle: customCellStyle,
          },
          {
            title: 'Premium Paid',
            field: 'premiumPaid',
            type: 'numeric',
            cellStyle: customCellStyle,
          },
          {
            title: 'Remaining',
            field: 'remainingAmount',
            type: 'numeric',
            cellStyle: customCellStyle,
          },
          { title: 'Bill Type', field: 'billType', cellStyle: customCellStyle },
          {
            title: 'Adjustment',
            field: 'isAdjustment',
            render: (data) => <Checkbox disabled checked={data.isAdjustment} color="primary" />,
            cellStyle: customCellStyle,
          },
          {
            title: 'Credit',
            field: 'isCredit',
            render: (data) => <Checkbox disabled checked={data.isCredit} color="primary" />,
            cellStyle: customCellStyle,
          },
          // {
          //   title: 'Manual',
          //   field: 'isManual',
          //   render: (data) => <Checkbox disabled checked={data.isManual} color="primary" />,
          //   cellStyle: customCellStyle,
          // },
          // {
          //   title: 'Void',
          //   field: 'isVoid',
          //   render: (data) => <Checkbox disabled checked={data.isVoid} color="primary" />,
          //   cellStyle: customCellStyle,
          // },
          {
            title: 'Invoice Emailed',
            field: 'emailed',
            render: (data) => <Checkbox disabled checked={data.emailed} color="primary" />,
            cellStyle: customCellStyle,
          },
          {
            title: 'Preview Invoice',
            field: 'previewInvoice',
            render: (data) => (
              <Button
                color="primary"
                disabled={!data.path}
                variant="outlined"
                size="small"
                onClick={() => _fetchDocument(data.path)}
              >
                Preview
              </Button>
            ),
            cellStyle: customCellStyle,
          },
          {
            title: 'Resend Email',
            field: 'resendEmail',
            render: (data) => (
              <Button
                color="primary"
                disabled={!data.emailed}
                variant="outlined"
                size="small"
                onClick={() => _emailDocument(data)}
              >
                {/* In order to make the loader appear only in the row that contains the invoices that is being resend i found this solution */}
                {!isSendDocumentByEmailLoading ? (
                  'Resend'
                ) : invoiceIdThatIsBeingAccessed === data.id ? (
                  <CircularProgress size={24} />
                ) : (
                  'Resend'
                )}
              </Button>
            ),
            cellStyle: customCellStyle,
          },
        ]}
        data={policy.invoices}
        title="Policy Invoices"
        options={{
          search: false,
          paging: false,
          filtering: false,
          addRowPosition: 'first',
          loadingType: 'linear',
          draggable: false,
          showTitle: false,
          actionsColumnIndex: -1,
          rowStyle: (rowData) => ({
            fontFamily: 'Roboto',
            fontSize: 14,
          }),
        }}
        actions={[
          (rowData) => {
            return {
              icon: 'unvoid',
              tooltip: 'Unvoid',
              hidden: !rowData.isVoid,
              onClick: (evemt, rowData) => {
                _handleUnVoidInvoicePopUp(rowData);
              },
            };
          },
          (rowData) => {
            return {
              icon: 'void',
              tooltip: 'Void',
              hidden: rowData.isVoid,
              onClick: (evemt, rowData) => {
                _handleVoidInvoice(rowData);
              },
            };
          },
          (rowData) => {
            return {
              icon: 'edit',
              tooltip: 'Edit',
              hidden: rowData.status == 'Paid' || rowData.isVoid,
              onClick: (evemt, rowData) => {
                setInvoiceEditing({ ...rowData, policy: policy });
              },
            };
          },
          (rowData) => {
            return {
              icon: 'details',
              tooltip: 'Details',
              onClick: (evemt, rowData) => {
                setInvoiceDetails({ ...rowData, policy: policy });
              },
            };
          },
        ]}
        components={{
          Action: (props) => {
            const action = props.action.action(props.data);
            if (action.hidden) return <></>;

            if (action.icon === 'edit') {
              return (
                <Button
                  onClick={(event) => action.onClick(event, props.data)}
                  color="primary"
                  variant="contained"
                  style={{ textTransform: 'none', marginLeft: 3 }}
                  size="small"
                >
                  Edit
                </Button>
              );
            } else if (action.icon === 'details') {
              return (
                <Button
                  onClick={(event) => action.onClick(event, props.data)}
                  color="primary"
                  variant="contained"
                  style={{ textTransform: 'none', marginLeft: 3 }}
                  size="small"
                >
                  Details
                </Button>
              );
            } else if (action.icon === 'void') {
              return (
                <Button
                  onClick={(event) => action.onClick(event, props.data)}
                  color="primary"
                  variant="contained"
                  style={{ textTransform: 'none', marginLeft: 3 }}
                  size="small"
                >
                  {/* isVoidInvoiceLoading */}
                  {!isVoidInvoiceLoading ? (
                    'Void'
                  ) : invoiceIdThatIsBeingAccessed === props.data.id ? (
                    <CircularProgress color="secondary" size={24} />
                  ) : (
                    'Void'
                  )}
                </Button>
              );
            } else if (action.icon === 'unvoid') {
              return (
                <Button
                  onClick={(event) => action.onClick(event, props.data)}
                  color="primary"
                  variant="contained"
                  style={{ textTransform: 'none', marginLeft: 3 }}
                  size="small"
                >
                  {!invoiceDueDateConfirmation ? (
                    'Restore'
                  ) : invoiceIdThatIsBeingAccessed === props.data.id ? (
                    <CircularProgress color="secondary" size={24} />
                  ) : (
                    'Restore'
                  )}
                </Button>
              );
            } else {
              return <></>;
            }
          },
        }}
      />
      <InvoiceFormModal
        open={!!invoiceEditing}
        handleClose={() => setInvoiceEditing(undefined)}
        invoice={invoiceEditing ?? {}}
      />
      <InvoiceDetailsModal
        open={!!invoiceDetails}
        handleClose={() => setInvoiceDetails(undefined)}
        invoice={invoiceDetails ?? {}}
      />
      <InvoiceUnVoidModal
        open={!!invoiceDueDateConfirmation}
        handleClose={() => _handleCloseUnVoidInvoicePopUp()}
        invoiceIdThatIsBeingAccessed={invoiceIdThatIsBeingAccessed}
      />
    </>
  );
};

export default memo(PolicyInvoices);

import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightBold,
  },
  label: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold,
  },
  value: {
    fontSize: theme.typography.pxToRem(13),
  },
  spacingHorizontal: {
    gap: theme.spacing(6),
  },
  spacingVertical: {
    gap: theme.spacing(2),
  },
  spacingVerticalBig: {
    gap: theme.spacing(6),
  },
}));

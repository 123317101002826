import { useContext } from 'react';

import { LayoutContext } from '../providers/LayoutProvider';

export const useSidebar = (): any[] => {
  const { layoutData, setLayoutData } = useContext(LayoutContext);

  const toggleSidebar = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setLayoutData({ ...layoutData, sidebarOpen: open });
  };

  return [layoutData.sidebarOpen, toggleSidebar];
};

import { Button, Grid } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import { differenceInDays } from 'date-fns';

export interface ActionButtonsProps {
  display: boolean;
}

const ActionButtons: React.FC<any> = (props) => {
  const { display } = props;
  const { watch } = useFormContext();

  const { expirationDate, policyStatus } = watch(['expirationDate', 'policyStatus']);
  let daysUntilExpiration = differenceInDays(expirationDate, new Date());

  return (
    <>
      {display && (
        <Grid container spacing={2} style={{ marginBottom: '5px' }}>
          <Grid container item xs={12} spacing={6}>
            <Grid item xs={3}></Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={3}>
              {daysUntilExpiration <= 90 && policyStatus != 'New' && (
                <Button color="primary" variant="contained">
                  Renew Policy
                </Button>
              )}
            </Grid>

            <Grid item xs={3}>
              <Button color="primary" variant="contained">
                Endorsements
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ActionButtons;

import {
  Grid,
  Paper,
  TextField,
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  makeStyles,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Autocomplete } from '@material-ui/lab';

import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { Controller, useFormContext } from 'react-hook-form';
import CurrencyFormat from 'react-number-format';
import { format } from 'date-fns';

import * as customToast from '../../../toast/customToast';
import { handleSubmitKeyBinding, handleKey } from '../../../../utils/formUtils';
import { MESSAGE_TYPES } from '../../../../api/messageTypes';
import { callWs } from '../../../../api/websocket';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'fixed',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  bold: { fontWeight: 600 },
  paper: { minWidth: 1080 },
}));

export interface PaymentCreateProps {
  policy: any;
  open: any;
  handleClose: any;
  handleCloseSilent: any;
}

const PaymentCreate: React.FC<PaymentCreateProps> = (props) => {
  const { policy, open, handleClose, handleCloseSilent } = props;
  const { control, watch, setValue, getValues, errors, handleSubmit, reset } = useFormContext();

  const personData = policy?.insuredPersons?.map((item) => ({
    id: item.id,
    name: item.firstName + ' ' + item.lastName,
  }));

  const {
    mutateAsync: paymentCreate,
    isLoading: isPaymentCreationLoading,
  } = useMutation(MESSAGE_TYPES.PAYMENT_CREATE, (data: any) =>
    callWs(MESSAGE_TYPES.PAYMENT_CREATE, data),
  );

  const _submitPaymentCreation = async (data) => {
    try {
      const response = await paymentCreate({
        policy_id: policy.id,
        reference_code: data.referenceCode,
        transaction_date: format(data.transactionDate, 'yyyy-MM-dd'),
        amount: data.amount,
        deposit_method: data.depositMethod,
        agent_id: '60529ea3-c20c-4172-90c1-f8ee242814f6',
        account_id: '4f312246-0baf-4e94-878b-568f853d9f3b',
        insured_person_id: data.insuredPerson.id,
      });
      handleClose();
      customToast.success('Payment logged successfully', {
        id: 'payment-logged-success',
      });
    } catch (err) {
      customToast.error(err.messageCode);
      console.log('error while saving policy payment');
    }
  };

  const depositTypes = [
    { value: 1, label: 'ACH' },
    { value: 2, label: 'Check' },
    { value: 3, label: 'Credit Card' },
  ];

  const classes = useStyles();

  useEffect(() => {
    reset({
      policyId: policy.id,
      amount: null,
      referenceCode: null,
      insuredPerson: null,
      depositMethod: null,
      transactionDate: new Date(), // Set another date
    });
  }, [open]);

  return (
    <>
      <Dialog
        classes={{ paper: classes.paper }}
        open={open}
        onClose={handleClose}
        disableBackdropClick
      >
        <DialogTitle>
          <Grid container>
            <Grid item>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid item justify="center">
              <Typography variant="h6" className={classes.title}>
                Payment Creation Form
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <form onKeyDown={(e) => handleSubmitKeyBinding(e, handleSubmit(_submitPaymentCreation))}>
            <Paper>
              <Box p="1rem">
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Controller
                      render={({ onChange, value }) => (
                        <TextField
                          required
                          fullWidth
                          variant="standard"
                          type="text"
                          margin="dense"
                          label="Reference Code"
                          value={value}
                          onChange={(e) => {
                            onChange(e.target.value);
                          }}
                          InputLabelProps={{ shrink: true }}
                          error={errors.referenceCode !== undefined}
                          helperText={errors.referenceCode?.message}
                        />
                      )}
                      control={control}
                      name="referenceCode"
                      rules={{
                        required: {
                          value: true,
                          message: 'Reference Code Type is required',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      render={({ onChange, value }) => (
                        <CurrencyFormat
                          customInput={TextField}
                          thousandSeparator
                          prefix={'$ '}
                          type="text"
                          fixedDecimalScale={true}
                          decimalScale={2}
                          label="Amount"
                          fullWidth
                          variant="standard"
                          margin="dense"
                          InputLabelProps={{ shrink: true }}
                          value={value != null ? value : ''}
                          onValueChange={(values) => {
                            console.log(values);
                            onChange(values?.floatValue?.toString());
                          }}
                          onKeyDown={(e) => handleKey(e)}
                          required={true}
                          error={errors.amount !== undefined}
                          helperText={errors.amount?.message}
                        />
                        // <TextField
                        //   id="amount"
                        //   required
                        //   fullWidth
                        //   type="number"
                        //   margin="dense"
                        //   label="Amount"
                        //   InputLabelProps={{ shrink: true }}
                        //   value={parseFloat(value).toFixed(2)}
                        //   onChange={(e) => {
                        //     onChange(e.target.value);
                        //   }}
                        //   error={errors.amount !== undefined}
                        //   helperText={errors.amount?.message}
                        // />
                      )}
                      control={control}
                      name="amount"
                      rules={{
                        required: {
                          value: true,
                          message: 'Amount is required',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      render={({ onChange, value }) => (
                        <KeyboardDatePicker
                          required
                          margin="dense"
                          fullWidth
                          label="Transaction Date"
                          format="MM/dd/yyyy"
                          autoOk
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          error={errors.transactionDate !== undefined}
                          onChange={(data) => {
                            onChange(data);
                          }}
                          value={value}
                        />
                      )}
                      control={control}
                      name="transactionDate"
                      rules={{
                        required: {
                          value: true,
                          message: 'Transaction Date is required',
                        },
                      }}
                    />
                    {errors.transactionDate && (
                      <div className="invalid-feedback">{errors.transactionDate.message}</div>
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={4}>
                    <Controller
                      render={({ onChange, value }) => (
                        <Autocomplete
                          options={personData || []}
                          getOptionLabel={(option: any) => option.name}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              error={errors.insuredPerson !== undefined}
                              helperText={errors.insuredPerson?.message}
                              InputLabelProps={{ shrink: true }}
                              label="Insured Person"
                              margin="dense"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: 'disabled',
                              }}
                            />
                          )}
                          fullWidth
                          disableClearable
                          autoHighlight
                          onChange={(_, data) => {
                            onChange(data);
                          }}
                          value={value}
                        />
                      )}
                      control={control}
                      name="insuredPerson"
                      rules={{
                        required: {
                          value: true,
                          message: 'Insured Person is required',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      render={({ value, onChange }) => (
                        <TextField
                          required
                          fullWidth
                          select
                          label="Deposit method"
                          margin="dense"
                          value={value}
                          InputLabelProps={{ shrink: true }}
                          error={errors.depositMethod !== undefined}
                          helperText={errors.depositMethod?.message}
                          onChange={(e) => {
                            onChange(e.target.value);
                          }}
                        >
                          {depositTypes.map((depositType) => (
                            <MenuItem key={depositType.value} value={depositType.value}>
                              {depositType.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                      control={control}
                      name="depositMethod"
                      rules={{
                        required: {
                          value: true,
                          message: 'Deposit Method is required',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="select-insured"
                      fullWidth
                      disabled
                      margin="dense"
                      defaultValue="Signature Bank"
                      label="Deposit account"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </form>
        </DialogContent>
        <DialogActions style={{ marginRight: '2rem' }}>
          <Button onClick={handleCloseSilent} color="primary">
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={!isPaymentCreationLoading ? handleSubmit(_submitPaymentCreation) : undefined}
            variant="contained"
          >
            {isPaymentCreationLoading ? <CircularProgress size={24} /> : 'Create Payment'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default PaymentCreate;

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  makeStyles,
} from '@material-ui/core';
import { useState } from 'react';
import InputWrapper from '../../inputs/InputWrapper';

export interface PopupDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (value: any) => void;
  message?: string;
  popupInputData?: any;
  currentValue?: any;
}

const useStyles = makeStyles(() => ({
  paper: { minWidth: 500 },
}));

const PopupDialog: React.FC<PopupDialogProps> = (props) => {
  const { open, onClose, onSubmit, message, popupInputData, currentValue } = props;

  const classes = useStyles();

  const [value, setValue] = useState(currentValue);

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={open}
      onClose={onClose}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogContent>
        {message && <DialogContentText>{message}</DialogContentText>}
        {popupInputData && (
          <InputWrapper
            properties={popupInputData}
            value={value}
            onChange={(newValue) => setValue(newValue)}
            displayLabel
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
        {popupInputData && (
          <Button
            onClick={async () => {
              if (value != null) {
                try {
                  await onSubmit(value);
                  onClose();
                } catch (err) {
                  console.log('PopupDialog error', err);
                }
              }
            }}
            color="primary"
          >
            Submit
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default PopupDialog;

import { forwardRef, useEffect, useRef, useState } from 'react';
import MaterialTable, { MTableAction, Icons } from '@material-table/core';
import InputWrapper from '../../shared/components/inputs/InputWrapper';
import SearchIcon from '@material-ui/icons/Search';
import { format } from 'date-fns';
import { useMutation } from 'react-query';
import { MESSAGE_TYPES } from '../../api/messageTypes';
import { callWs } from '../../api/websocket';
import * as customToast from '../../shared/toast/customToast';
import { dateFormater } from '../../utils/valueFormatter';
import { Button } from '@material-ui/core';
import { usePageTitle } from '../../shared/hooks/use-page-title';
const tableIcons: Icons = {
  Filter: forwardRef((props, ref) => <SearchIcon {...props} ref={ref} />),
};

const InvoicesList: React.FC<any> = (props) => {
  usePageTitle('Post Invoices List');
  const [fetchedData, setFetchedData] = useState<any>([]);

  const {
    mutateAsync: getPostMailOverview,
    isLoading: isOverviewLoading,
  } = useMutation(MESSAGE_TYPES.POST_MAIL_OVERVIEW, (data: any) =>
    callWs(MESSAGE_TYPES.POST_MAIL_OVERVIEW, data),
  );

  const {
    mutateAsync: markAllInvoicesAsSent,
    isLoading: isMarkAllLoading,
  } = useMutation(MESSAGE_TYPES.MARK_ALL_INVOICES_AS_SENT, (overview_id: any) =>
    callWs(MESSAGE_TYPES.MARK_ALL_INVOICES_AS_SENT, { overview_id }),
  );

  const {
    mutateAsync: getDocument,
    isLoading: isDocumentLoading,
  } = useMutation(MESSAGE_TYPES.DOCUMENT_GET, (path: any) =>
    callWs(MESSAGE_TYPES.DOCUMENT_GET, { path }),
  );

  const _fetchOverview = async () => {
    await getPostMailOverview({})
      .then((response: any) => setFetchedData(response))
      .catch((error) => console.log(error));
  };

  const _markAllInvoicesAsSent = async (overview_id) => {
    await markAllInvoicesAsSent(overview_id)
      .then((response: any) => customToast.success('Invoices Updated Successfully'))
      .catch((error) => customToast.error(error.messageCode));
  };

  const _fetchDocument = async (path) => {
    const urlResponse: any = await getDocument(path);
    window.open(urlResponse, '_blank');
  };

  useEffect(() => {
    _fetchOverview();
  }, []);

  const combinedPath = fetchedData?.combinedInvoicePath;

  const _formatInsuredPersonsToDisplay = (insuredPersons: any) => {
    let formattedDisplay = '';

    if (insuredPersons?.length > 0) {
      const firstInsuredPerson = insuredPersons[0];
      if (firstInsuredPerson.isCompany) {
        formattedDisplay =
          firstInsuredPerson.companyName +
          (firstInsuredPerson.hasCareOfName
            ? ` (${firstInsuredPerson.firstName} ${firstInsuredPerson.lastName})`
            : '');
      } else {
        formattedDisplay = `${firstInsuredPerson.firstName} ${firstInsuredPerson.lastName}`;
      }

      formattedDisplay += insuredPersons.length > 1 ? ', ...' : '';
    }

    return formattedDisplay;
  };
  const columns: any[] = [
    {
      title: 'Policy Number',
      field: 'policyNumber',
    },
    {
      title: 'Invoice Number',
      field: 'invoiceNumber',
    },
    {
      title: 'Agent',
      field: 'agentName',
    },
    {
      title: 'Installment',
      field: 'installment',
    },
    {
      title: 'Due date',
      field: 'dueDate',
    },
    {
      title: 'Amount',
      field: 'totalDueFull',
      render: (rowData) => (
        <span>
          {
            <InputWrapper
              properties={{
                name: 'totalDueFull',
                label: '',
                type: 'float',
                presentation: 'currency',
              }}
              value={rowData.totalDueFull}
              onChange={() => {}}
              displayMode={'read'}
            ></InputWrapper>
          }
        </span>
      ),
    },
    {
      title: 'Status',
      field: 'status',
    },
    {
      title: 'Created At',
      field: 'policyCreatedAt',
      render: (rowData) => (
        <span>{format(dateFormater(rowData.policyCreatedAt), 'MM-dd-yyyy HH:mm:ss')}</span>
      ),
    },
  ];
  const _getSortFieldName = (fieldName?: string) => {
    if (!fieldName) return undefined;
    if (fieldName.includes('product')) {
      return 'product.name';
    } else {
      return fieldName;
    }
  };
  console.log(parseFloat('98.55').toFixed(2));

  return (
    <>
      <MaterialTable
        data={fetchedData?.overview}
        columns={columns}
        icons={tableIcons}
        actions={[
          {
            isFreeAction: true,
            icon: 'add',
            tooltip: 'Combined Invoices Preview',
            onClick: () => _fetchDocument(combinedPath),
          },
          {
            isFreeAction: true,
            icon: 'mark',
            tooltip: 'Mark All as sent',
            onClick: () => _markAllInvoicesAsSent(fetchedData.id),
          },
          (rowData: any) => ({
            icon: 'preview',
            tooltip: 'Preview Invoice',
            onClick: () => {
              _fetchDocument(rowData.path);
            },
          }),
        ]}
        components={{
          Action: (props) => {
            const action = props.action?.action ? props.action.action(props.data) : props.action;

            if (action.icon === 'add') {
              return (
                <Button
                  onClick={(event) => action.onClick(event)}
                  color="primary"
                  variant="contained"
                  style={{ textTransform: 'none', margin: '15px' }}
                  size="large"
                >
                  Preview Merged Invoices
                </Button>
              );
            } else if (action.icon === 'mark') {
              return (
                <Button
                  onClick={(event) => action.onClick(event)}
                  color="primary"
                  variant="contained"
                  style={{ textTransform: 'none' }}
                  size="large"
                >
                  Mark All as Sent
                </Button>
              );
            } else {
              return <MTableAction {...props} />;
            }
          },
        }}
        options={{
          draggable: false,

          showTitle: false,

          paging: false,

          rowStyle: { fontFamily: 'Roboto', fontSize: 14 },

          debounceInterval: 800,

          actionsColumnIndex: -1,
        }}
      />
    </>
  );
};

export default InvoicesList;

import {
  Grid,
  Button,
  CircularProgress,
  Dialog,
  TextField,
  MenuItem,
  DialogContent,
  DialogActions,
  DialogTitle,
} from '@material-ui/core';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { format } from 'date-fns';

import { callWs } from '../../../api/websocket';
import { MESSAGE_TYPES } from '../../../api/messageTypes';
import * as customToast from '../../toast/customToast';
import CreateMtaDialog from './CreateMtaDialog';
import ActivateMtaDialog from './ActivateMtaDialog';
import NonPaymentDialog from './NonPaymentDialog';
import CancelDialog from './CancelDialog';

const inputDescriptions = [
  {
    name: 'mtaNumber',
    label: 'MTA Number',
    type: 'text',
    presentation: 'text',
    validation: {
      front: { required: false },
    },
  },
  {
    name: 'effectiveDate',
    label: 'Effective Date',
    type: 'date',
    presentation: 'text',
    validation: {
      front: { required: true },
    },
  },
  {
    name: 'status',
    label: 'MTA Status',
    type: 'text',
    presentation: 'text',
    validation: {
      front: { required: true },
    },
  },
];

const reasons = [
  { value: 16000, label: 'Underwriting reasons' },
  { value: 16001, label: 'Risk no longer qualifies for the current program' },
  { value: 16002, label: 'Carrier is no longer covering specific coverage' },
  { value: 16003, label: 'Policy replaced with different carrier' },
];

const PolicyButtons = (props) => {
  const [open, setOpen] = useState(false);
  const [activateOpen, setActivateOpen] = useState(false);
  const [canMerge, setCanMerge] = useState(true);
  const [installments, setInstallments] = useState<any>([1]);
  const [openCancellations, setOpenCancellations] = useState(false);
  const [openNonPaymentDialog, setOpenNonPaymentDialog] = useState(false);
  const [openRenew, setOpenRenew] = useState(false);

  const [options, setOptions] = useState<any>([]);
  const [specificOptions, setSpecificOptions] = useState<any>([]);
  const { fetchPolicy, policy } = props;

  const { control, watch, getValues, reset, handleSubmit, trigger, errors } = useForm<any>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      ...inputDescriptions
        .map((inputDescription) => ({
          [inputDescription.name]: inputDescription.type === 'boolean' ? false : null,
        }))
        .reduce((acc, next) => Object.assign(acc, next), {}),
    },
  });
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0');
  var yyyy = today.getFullYear();
  const today2 = yyyy + '/' + mm + '/' + dd;

  const initialize = {
    effectiveDate: today2,
    emailDocAgent: false,
    emailDocMga: false,
    emailDocument: true,
    id: policy.id,
    mergeInvoices: true,
    installments: 0,
    postDocument: true,
  };

  const [selectedRow, setSelectedRow] = useState(initialize);

  const {
    mutateAsync: bindPolicy,
    isLoading: isPolicyBindLoading,
  } = useMutation(MESSAGE_TYPES.POLICY_BIND, (data: any) =>
    callWs(MESSAGE_TYPES.POLICY_BIND, data),
  );

  const {
    mutateAsync: renewPolicy,
    isLoading: isRenewPolicyLoading,
  } = useMutation(MESSAGE_TYPES.RENEW, (data: any) => callWs(MESSAGE_TYPES.RENEW, data));

  const {
    mutateAsync: fetchAmounts,
    isLoading: isFetchAmountsLoading,
  } = useMutation(MESSAGE_TYPES.FETCH_AMOUNTS, (data: any) =>
    callWs(MESSAGE_TYPES.FETCH_AMOUNTS, data),
  );

  const {
    mutateAsync: activateMta,
    isLoading: isActivateMtaLoading,
  } = useMutation(MESSAGE_TYPES.MTA_ACTIVATE, (data: any) =>
    callWs(MESSAGE_TYPES.MTA_ACTIVATE, data),
  );

  const {
    mutateAsync: mtaCreate,
    isLoading: isMtaCreateLoading,
  } = useMutation(MESSAGE_TYPES.MTA_CREATE, (data: any) => callWs(MESSAGE_TYPES.MTA_CREATE, data));

  const {
    mutateAsync: doNotRenewPolicy,
    isLoading: isDoNotRenewPolicyLoading,
  } = useMutation(MESSAGE_TYPES.POLICY_DO_NOT_RENEW, (data: any) =>
    callWs(MESSAGE_TYPES.POLICY_DO_NOT_RENEW, data),
  );

  const {
    mutateAsync: allowRenewalOfPolicy,
    isLoading: isAllowRenewalOfPolicyLoading,
  } = useMutation(MESSAGE_TYPES.POLICY_ALLOW_RENEWAL, (data: any) =>
    callWs(MESSAGE_TYPES.POLICY_ALLOW_RENEWAL, data),
  );

  const {
    mutateAsync: mtaAnalysis,
    isLoading: isMtaInvoiceLoading,
  } = useMutation(MESSAGE_TYPES.MTA_INVOICE_ANALYSIS, (data: any) =>
    callWs(MESSAGE_TYPES.MTA_INVOICE_ANALYSIS, data),
  );

  const handleMtaCreate = async (data) => {
    try {
      if (!isPolicyBindLoading) {
        const row = {
          effectiveDate: format(data.effectiveDate, 'yyyy-MM-dd'),
          emailDocAgent: data.emailDocAgent,
          emailDocMga: data.emailDocMga,
          emailDocument: data.emailDocument,
          policyId: policy.id,
          postDocument: data.postDocument,
        };
        await mtaCreate(row);
        fetchPolicy();
        customToast.success('Mta created successfully');
      }
    } catch (err) {
      customToast.error(err.messageCode, {
        id: 'mta-creation-error',
      });
    }
  };

  const handleMtaInvoiceFetch = async () => {
    try {
      await mtaAnalysis({ id: policy.id }).then((data: any) => {
        setCanMerge(data?.canMerge);
        setInstallments(data?.newInvoiceInstallmentOptions);
      });
    } catch (err) {
      setCanMerge(false);
      const installments = [1];
      setInstallments(installments);
      customToast.info('Policy renewal is now allowed');
    }
  };

  const handleCloseDoNotRenew = () => {
    setOpenRenew(false);
  };

  const handleDoNotRenew = async (formData) => {
    try {
      await doNotRenewPolicy({ id: policy?.id, reason: formData.Reason });
      setOpenRenew(false);
      fetchPolicy();
      customToast.success('Policy renewal reason logged successfully');
    } catch (err) {
      customToast.error(err.messageCode, {
        toastId: `do-not-renew-failed`,
      });
    }
  };

  const {
    mutateAsync: norCancellationDocumentSent,
    isLoading: isNorCancellationDocumentSentLoading,
  } = useMutation(MESSAGE_TYPES.POLICY_NOR_CANCELLATION_DOCUMENT_SENT, (data: any) =>
    callWs(MESSAGE_TYPES.POLICY_NOR_CANCELLATION_DOCUMENT_SENT, data),
  );

  const handleNorCancellationDocumentWasSent = async () => {
    try {
      await norCancellationDocumentSent({ id: policy?.id });
      fetchPolicy();
      customToast.success('Cancellation rescind document was marked as sent');
    } catch (err) {
      customToast.error(err.messageCode, {
        toastId: `cancellation-rescind-document-status-change-failed`,
      });
    }
  };

  const {
    mutateAsync: cancellationDocumentSent,
    isLoading: isCancellationDocumentSentLoading,
  } = useMutation(MESSAGE_TYPES.POLICY_CANCELLATION_DOCUMENT_SENT, (data: any) =>
    callWs(MESSAGE_TYPES.POLICY_CANCELLATION_DOCUMENT_SENT, data),
  );

  const handleCancellationDocumentWasSent = async () => {
    try {
      await cancellationDocumentSent({ id: policy?.id });
      fetchPolicy();
      customToast.success('Cancellation document was marked as sent');
    } catch (err) {
      customToast.error(err.messageCode, {
        toastId: `cancellation-document-status-change-failed`,
      });
    }
  };

  const handleAllowRenewal = async () => {
    try {
      await allowRenewalOfPolicy({ id: policy?.id });
      fetchPolicy();
      customToast.success('Policy renewal is now allowed');
    } catch (err) {
      customToast.error(err.messageCode, {
        toastId: `renewal-allowed-failed`,
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseActivate = () => {
    setActivateOpen(false);
  };

  const handleCloseNonPaymentDialog = () => {
    setOpenNonPaymentDialog(false);
  };

  const {
    mutateAsync: cancellationDropdownOptions,
    isLoading: isCancellationDropdownOptionsLoading,
  } = useMutation(MESSAGE_TYPES.POLICY_CANCELLATION_DROPDOWN_OPTIONS, (data: any) =>
    callWs(MESSAGE_TYPES.POLICY_CANCELLATION_DROPDOWN_OPTIONS, data),
  );

  const _handleOpenCancellations = async () => {
    try {
      const dropDownOptions: any = await cancellationDropdownOptions({});
      setOptions(
        dropDownOptions.filter((option) => option.value < 17100 && option.value !== 17003),
      );
      setSpecificOptions(dropDownOptions.filter((option) => option.value >= 17100));
      setOpenCancellations(true);
    } catch (err) {
      customToast.error('00008', {
        id: 'cancellation-setUp-error',
      });
    }
  };

  const _handleCloseCancellations = () => {
    setOpenCancellations(false);
  };

  const handleClaimCreate = () => {};

  const {
    mutateAsync: policyCancellationReinstate,
    isLoading: isPolicyCancellationReinstateLoading,
  } = useMutation(MESSAGE_TYPES.POLICY_CANCELLATION_REINSTATE, (data: any) =>
    callWs(MESSAGE_TYPES.POLICY_CANCELLATION_REINSTATE, data),
  );

  const handleReinstate = async () => {
    try {
      await policyCancellationReinstate({ id: policy?.id });
      fetchPolicy();
      customToast.success('Policy was reinstated successfully');
    } catch (err) {
      customToast.error(err.messageCode, {
        toastId: `policy-reinstate-failed`,
      });
    }
  };

  const handleRenew = async () => {
    await renewPolicy({ policy_id: policy?.id })
      .then(() => {})
      .catch((err) => {
        customToast.error(err.messageCode, {
          toastId: `policy-renew-failed`,
        });
      });
  };

  const handleArchive = () => {};

  const handleBindPolicy = async () => {
    try {
      await bindPolicy({ policyId: policy.id });
      fetchPolicy();
      customToast.success('Policy bound successfully');
    } catch (err) {
      customToast.error(err.messageCode, {
        id: 'policy-bind-error',
      });
    }
  };

  const DoNotRenew = () => {
    return (
      <>
        <Dialog open={openRenew} onClose={handleCloseDoNotRenew} fullWidth={true} maxWidth={'sm'}>
          <DialogTitle>Provide a non renewal reason</DialogTitle>
          <DialogContent style={{ overflow: 'hidden' }}>
            <Grid container item spacing={4}>
              <Grid item xs={6} md={12} style={{ display: 'flex', justifyContent: 'center' }}>
                <Controller
                  render={({ value, onChange }) => (
                    <TextField
                      required
                      fullWidth
                      select
                      label="Reason"
                      margin="dense"
                      value={value}
                      InputLabelProps={{ shrink: true }}
                      error={errors.depositMethod !== undefined}
                      helperText={errors.depositMethod?.message}
                      defaultValue={reasons[0]}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                    >
                      {reasons.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                  control={control}
                  name="Reason"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => handleDoNotRenew(getValues())}
              disabled={isDoNotRenewPolicyLoading}
            >
              {!isDoNotRenewPolicyLoading ? 'Submit' : <CircularProgress size={24} />}
            </Button>
            <Button onClick={() => setOpenRenew(false)} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  switch (policy.status) {
    case 'CREATED':
      return (
        <>
          <Grid item xs={2}>
            <Button color="primary" fullWidth variant="contained">
              Generate Quote
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              color="primary"
              fullWidth
              variant="contained"
              onClick={() => handleBindPolicy()}
              disabled={isPolicyBindLoading}
            >
              {!isPolicyBindLoading ? 'Bind Policy' : <CircularProgress size={24} />}
            </Button>
          </Grid>
        </>
      );
    case 'BOUND':
      return (
        <>
          <CreateMtaDialog
            open={open}
            handleClose={handleClose}
            selectedRow={selectedRow}
            installments={installments}
            canMerge={canMerge}
            handleMtaCreate={handleMtaCreate}
          />
          <Grid item xs={2}>
            <Button
              color="primary"
              fullWidth
              variant="contained"
              onClick={() => {
                setOpen(true);
                handleMtaInvoiceFetch();
              }}
              disabled={isMtaCreateLoading}
            >
              {!isMtaCreateLoading ? 'Create MTA' : <CircularProgress size={24} />}
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              color="primary"
              fullWidth
              variant="contained"
              onClick={() => handleClaimCreate()}
            >
              Create Claim
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button color="primary" fullWidth variant="contained" onClick={() => handleRenew()}>
              Renew
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              color="primary"
              fullWidth
              variant="contained"
              onClick={() => setOpenRenew(true)}
              disabled={isDoNotRenewPolicyLoading}
            >
              {!isDoNotRenewPolicyLoading ? 'Do Not Renew' : <CircularProgress size={24} />}
            </Button>
          </Grid>
          {(() => {
            if (
              policy?.cancellation?.isRescinded &&
              policy?.cancellation?.norPostageStatus !== 15002
            ) {
              return (
                <Grid item xs={2}>
                  <Button
                    color="primary"
                    fullWidth
                    variant="contained"
                    onClick={handleNorCancellationDocumentWasSent}
                    disabled={isNorCancellationDocumentSentLoading}
                  >
                    {isNorCancellationDocumentSentLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      'Cancellation Rescind Document was sent'
                    )}
                  </Button>
                </Grid>
              );
            } else if (!policy?.cancellation && policy?.pastDueInvoices) {
              return (
                <>
                  <NonPaymentDialog
                    open={openNonPaymentDialog}
                    handleClose={handleCloseNonPaymentDialog}
                    fetchPolicy={fetchPolicy}
                    policy={policy}
                  ></NonPaymentDialog>
                  <Grid item xs={2}>
                    <Button
                      color="primary"
                      fullWidth
                      variant="contained"
                      onClick={() => setOpenNonPaymentDialog(true)}
                    >
                      Start Non Payment process
                    </Button>
                  </Grid>
                  <CancelDialog
                    open={openCancellations}
                    handleClose={_handleCloseCancellations}
                    fetchPolicy={fetchPolicy}
                    policy={policy}
                    options={options}
                    specificOptions={specificOptions}
                  ></CancelDialog>
                  <Grid item xs={2}>
                    <Button
                      color="primary"
                      fullWidth
                      variant="contained"
                      onClick={_handleOpenCancellations}
                    >
                      {isCancellationDropdownOptionsLoading ? (
                        <CircularProgress size={24} color="secondary" />
                      ) : (
                        'Cancel Policy'
                      )}
                    </Button>
                  </Grid>
                </>
              );
            } else {
              return (
                <>
                  <CancelDialog
                    open={openCancellations}
                    handleClose={_handleCloseCancellations}
                    fetchPolicy={fetchPolicy}
                    policy={policy}
                    options={options}
                    specificOptions={specificOptions}
                  ></CancelDialog>
                  <Grid item xs={2}>
                    <Button
                      color="primary"
                      fullWidth
                      variant="contained"
                      onClick={_handleOpenCancellations}
                    >
                      {isCancellationDropdownOptionsLoading ? (
                        <CircularProgress size={24} color="secondary" />
                      ) : (
                        'Cancel Policy'
                      )}
                    </Button>
                  </Grid>
                </>
              );
            }
          })()}
          {/* {policy?.cancellation?.isRescinded && policy?.cancellation?.norPostageStatus !== 15002 ? (
            <Grid item xs={2}>
              <Button
                color="primary"
                fullWidth
                variant="contained"
                onClick={handleCancellationDocumentWasSent}
                disabled={isCancellationDocumentSentLoading}
              >
                {isCancellationDocumentSentLoading ? (
                  <CircularProgress size={24} />
                ) : (
                  'Cancellation Rescind Document was sent'
                )}
              </Button>
            </Grid>
          ) : (
            <>
              <NonPaymentDialog
                open={openNonPaymentDialog}
                handleClose={handleCloseNonPaymentDialog}
                fetchPolicy={fetchPolicy}
                policy={policy}
              ></NonPaymentDialog>
              <Grid item xs={2}>
                <Button
                  color="primary"
                  fullWidth
                  variant="contained"
                  onClick={() => setOpenNonPaymentDialog(true)}
                >
                  Start Non Payment process
                </Button>
              </Grid>
              <CancelDialog
                open={openCancellations}
                handleClose={handleCloseCancellations}
                fetchPolicy={fetchPolicy}
                policy={policy}
              ></CancelDialog>
              <Grid item xs={2}>
                <Button
                  color="primary"
                  fullWidth
                  variant="contained"
                  onClick={() => setOpenCancellations(true)}
                >
                  Cancel Policy
                </Button>
              </Grid>
            </>
          )} */}

          <DoNotRenew></DoNotRenew>
        </>
      );
    case 'CANCELLED':
      return (
        <>
          {policy?.cancellation?.nocPostageStatus !== 15002 ? (
            <Grid item xs={2}>
              <Button
                color="primary"
                fullWidth
                variant="contained"
                onClick={handleCancellationDocumentWasSent}
                disabled={isCancellationDocumentSentLoading}
              >
                {isCancellationDocumentSentLoading ? (
                  <CircularProgress size={24} />
                ) : (
                  'Document was sent'
                )}
              </Button>
            </Grid>
          ) : (
            <></>
          )}
          <Grid item xs={2}>
            <Button color="primary" fullWidth variant="contained" onClick={() => handleReinstate()}>
              {isPolicyCancellationReinstateLoading ? <CircularProgress size={24} /> : 'Reinstate'}
            </Button>
          </Grid>
        </>
      );
    case 'UNDERWRITTEN':
      return (
        <Grid item xs={2}>
          <Button color="primary" fullWidth variant="contained" onClick={() => handleBindPolicy()}>
            Bind
          </Button>
        </Grid>
      );
    case 'QUOTED':
      return (
        <>
          <Grid item xs={2}>
            <Button color="primary" fullWidth variant="contained" onClick={() => handleArchive()}>
              Archive
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              color="primary"
              fullWidth
              variant="contained"
              onClick={() => handleBindPolicy()}
            >
              Bind
            </Button>
          </Grid>
        </>
      );
    case 'CREATED_FOR_MTA':
      return (
        <>
          <ActivateMtaDialog
            activateOpen={activateOpen}
            handleClose={handleCloseActivate}
            installments={installments}
            canMerge={canMerge}
            policy={policy}
            fetchPolicy={fetchPolicy}
          />
          <Grid item xs={2}>
            <Button
              color="primary"
              fullWidth
              variant="contained"
              onClick={() => setActivateOpen(true)}
              disabled={isActivateMtaLoading}
            >
              {isActivateMtaLoading ? <CircularProgress size={24} /> : 'Activate MTA'}
            </Button>
          </Grid>
        </>
      );
    case 'BLOCKED':
      return (
        <Grid item xs={2}>
          <Button
            color="primary"
            fullWidth
            variant="contained"
            onClick={() => handleAllowRenewal()}
            disabled={isAllowRenewalOfPolicyLoading}
          >
            {!isAllowRenewalOfPolicyLoading ? 'Allow Renewal' : <CircularProgress size={24} />}
          </Button>
        </Grid>
      );
    default:
      return null;
  }
};

export default PolicyButtons;

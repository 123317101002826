import { memo, useState } from 'react';
import {
  Button,
  FormControlLabel,
  MenuItem,
  Switch,
  TextField,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Controller, useFormContext } from 'react-hook-form';

import { callWs } from '../../../../../api/websocket';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { MESSAGE_TYPES } from '../../../../../api/messageTypes';
import { addYears, format } from 'date-fns';
import * as customToast from '../../../../toast/customToast';

import EndorsementsDialog from '../../endorsements-dialog/EndorsementsDialog';
import { handleSubmitKeyBinding } from '../../../../../utils/formUtils';
import { decimalCount } from '../../../../../utils/utils';
import { addMonths } from 'date-fns/esm';

export interface MainPolicyDetailsProps {
  isPolicyCreated?: boolean;
  fetchPolicy?: any;
  setIsPolicyChanged?: any;
}

const MainPolicyDetails: React.FC<MainPolicyDetailsProps> = (props) => {
  const { isPolicyCreated, fetchPolicy, setIsPolicyChanged } = props;

  const { data: agentsProductsDescriptions = {} } = useQuery<any, any>(
    MESSAGE_TYPES.AGENTS_PRODUCTS_DESCRIPTIONS,
    () => callWs(MESSAGE_TYPES.AGENTS_PRODUCTS_DESCRIPTIONS),
  );

  const agents = agentsProductsDescriptions?.agentProducts?.map((agent: any) => ({
    id: agent.id,
    name: agent.name,
  }));

  const adjusters =
    agentsProductsDescriptions?.adjusters?.map((adjuster: any) => ({
      id: adjuster.id,
      name: adjuster.name,
    })) ?? [];

  const reinsurers =
    agentsProductsDescriptions?.reinsurers?.map((reinsurer: any) => ({
      id: reinsurer.id,
      name: reinsurer.name,
    })) ?? [];

  const { control, watch, setValue, getValues, errors, handleSubmit } = useFormContext();
  const history = useHistory();
  const [open2, setOpen2] = useState(false);

  // const [isPolicyCreated, setIsPolicyCreated] = useState(false);

  const [policyChangeConfirmation, setPolicyChangeConfirmation] = useState<any>();

  const handleClose2 = () => {
    setOpen2(false);
  };

  const {
    agentNettingDown,
    policyType,
    agent,
    insurer,
    adjuster,
    reinsurer,
    policyStatus,
  } = watch([
    'agentNettingDown',
    'policyType',
    'agent',
    'insurer',
    'adjuster',
    'reinsurer',
    'policyStatus',
  ]);

  function getUniqueListBy(arr = [], key = 'id') {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  }

  const products = agent
    ? getUniqueListBy(
        agentsProductsDescriptions?.agentProducts
          ?.find((agentProduct: any) => agentProduct.id === agent.id)
          ?.productDescriptions?.map((productDescription: any) => ({
            id: productDescription.product.id,
            name: productDescription.product.name,
          })),
        'id',
      )
    : [];

  if (products.length === 1 && !policyType) {
    setValue('policyType', products[0]['id']);
  }

  const insurers = policyType
    ? agentsProductsDescriptions?.agentProducts
        ?.find((agentProduct: any) => agentProduct.id === agent.id)
        ?.productDescriptions?.filter(
          (productDescription: any) => productDescription.productId === policyType,
        )
        ?.map((productDescription: any) => ({
          id: productDescription.insurer.id,
          name: productDescription.insurer.name,
          default: productDescription.pivot.isDefault,
        })) ?? []
    : [];

  if (insurers.length !== 0 && !insurer) {
    setValue(
      'insurer',
      insurers.find((item: any) => item.default),
    );
  }
  const defaultAdjuster =
    agent && insurer && !adjuster
      ? agentsProductsDescriptions?.agentProducts
          ?.find((agentProduct: any) => agentProduct.id === agent.id)
          ?.productDescriptions?.find(
            (productDescription: any) =>
              productDescription.productId === policyType &&
              productDescription.insurerId === insurer.id,
          )?.pivot?.defaultAdjuster
      : undefined;

  if (defaultAdjuster) {
    setValue(
      'adjuster',
      adjusters.find((adjuster: any) => adjuster.id === defaultAdjuster),
    );
  }

  const defaultReinsurer =
    agent && insurer && !reinsurer
      ? agentsProductsDescriptions?.agentProducts
          ?.find((agentProduct: any) => agentProduct.id === agent.id)
          ?.productDescriptions?.find(
            (productDescription: any) =>
              productDescription.productId === policyType &&
              productDescription.insurerId === insurer.id,
          )?.pivot?.defaultReinsurer
      : undefined;

  if (defaultReinsurer) {
    setValue(
      'reinsurer',
      reinsurers.find((reinsurer: any) => reinsurer.id === defaultReinsurer),
    );
  }

  if (!agentNettingDown) {
    setValue('agentNettingDownPercent', '', { shouldValidate: true });
  }

  const {
    mutateAsync: sendPolicyData,
    isLoading: isPolicySaveLoading,
  } = useMutation(MESSAGE_TYPES.POLICY_CREATE, (data: any) =>
    callWs(MESSAGE_TYPES.POLICY_CREATE, data),
  );

  const {
    mutateAsync: updatePolicyData,
    isLoading: isPolicyUpdateLoading,
  } = useMutation(MESSAGE_TYPES.POLICY_UPDATE, (data: any) =>
    callWs(MESSAGE_TYPES.POLICY_UPDATE, data),
  );

  const _updatePolicy = async (data) => {
    if (isPolicyUpdateLoading) {
      return;
    }
    try {
      const response: any = await updatePolicyData({
        id: getValues('policyId'),
        inceptionDate: format(data.inceptionDate, 'yyyy-MM-dd'),
        expirationDate: format(data.expirationDate, 'yyyy-MM-dd'),
        adjusterId: data.adjuster.id,
        reinsurerId: data.reinsurer.id,
        agentCommission: data.agentCommission,
        agentNettingDown: data.agentNettingDown,
        netDownToPct: data.agentNettingDown ? data.agentNettingDownPercent : undefined,
        taxPct: data.surplusLinesTaxPct,
        taxFee: data.surplusLinesTaxFee,
        surcharge: data.policySurcharge,
      });
      customToast.success('Policy details updated successfully', {
        id: 'policy-details-update-success',
      });
      fetchPolicy();
      setIsPolicyChanged(false);
    } catch (err) {
      customToast.error(err.messageCode);
      console.log('error while updating policy');
    }
  };

  const _onSavePolicy = async (data) => {
    if (isPolicySaveLoading) {
      return;
    }
    try {
      const response: any = await sendPolicyData({
        inceptionDate: format(data.inceptionDate, 'yyyy-MM-dd'),
        expirationDate: format(data.expirationDate, 'yyyy-MM-dd'),
        agentId: data.agent.id,
        adjusterId: data.adjuster.id,
        insurerId: data.insurer.id,
        reinsurerId: data.reinsurer.id,
        productDescriptionId: agentsProductsDescriptions?.agentProducts
          ?.find((agentProduct: any) => agentProduct.id === data.agent.id)
          ?.productDescriptions?.find(
            (productDescription: any) =>
              productDescription.productId === data.policyType &&
              productDescription.insurerId === data.insurer.id,
          )?.id,
      });
      history.replace('/policy/edit/' + response);
      customToast.success('Policy created successfully');
    } catch (err) {
      customToast.error(err.messageCode);
    }
  };

  return (
    <>
      <form
        onKeyDown={(e) =>
          handleSubmitKeyBinding(e, handleSubmit(isPolicyCreated ? _updatePolicy : _onSavePolicy))
        }
      >
        <Grid container spacing={5} style={{ margin: 0 }}>
          <Grid container item xs={12} spacing={6}>
            <Grid item xs={6} md>
              <Controller
                render={({ onChange, value }) => (
                  <Autocomplete
                    options={agents || []}
                    disabled={isPolicyCreated}
                    getOptionLabel={(option: any) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        error={errors.agent !== undefined}
                        helperText={errors.agent?.message}
                        InputLabelProps={{ shrink: true }}
                        label="Agent"
                        margin="dense"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'disabled',
                        }}
                      />
                    )}
                    fullWidth
                    disableClearable
                    autoHighlight
                    onChange={(_, data) => {
                      setIsPolicyChanged(true);
                      onChange(data);
                      setValue('policyType', null);
                      setValue('insurer', null);
                      setValue('adjuster', null);
                      setValue('reinsurer', null);
                    }}
                    value={value}
                  />
                )}
                control={control}
                name="agent"
                rules={{
                  required: {
                    value: true,
                    message: 'Agent is required',
                  },
                }}
              />
            </Grid>
            <Grid item xs={6} md>
              <Controller
                render={({ value, onChange }) => (
                  <TextField
                    fullWidth
                    margin="dense"
                    select
                    required
                    disabled={isPolicyCreated}
                    label="Policy Type"
                    helperText={errors.policyType?.message}
                    error={errors.policyType !== undefined}
                    InputLabelProps={{ shrink: true }}
                    value={value}
                    onChange={(e) => {
                      setIsPolicyChanged(true);
                      onChange(e.target.value);
                      setValue('insurer', null);
                      setValue('adjuster', null);
                      setValue('reinsurer', null);
                    }}
                  >
                    {products.map((product: any) => (
                      <MenuItem value={product.id}>{product.name}</MenuItem>
                    ))}
                  </TextField>
                )}
                control={control}
                name="policyType"
                rules={{
                  required: {
                    value: true,
                    message: 'Policy Type is required',
                  },
                }}
              />
            </Grid>
            <Grid item xs={6} md>
              <Controller
                render={({ onChange, value }) => (
                  <Autocomplete
                    options={insurers || []}
                    disabled={isPolicyCreated}
                    getOptionLabel={(option: any) => option.name}
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        error={errors.insurer !== undefined}
                        helperText={errors.insurer?.message}
                        InputLabelProps={{ shrink: true }}
                        label="Insurer"
                        margin="dense"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'disabled',
                        }}
                      />
                    )}
                    fullWidth
                    disableClearable
                    autoHighlight
                    onChange={(_, data) => {
                      setIsPolicyChanged(true);
                      onChange(data);
                      setValue('adjuster', null);
                      setValue('reinsurer', null);
                    }}
                    value={value}
                  />
                )}
                control={control}
                name="insurer"
                rules={{
                  required: {
                    value: true,
                    message: 'Insurer is required',
                  },
                }}
              />
            </Grid>
            <Grid item xs={6} md>
              <Controller
                render={({ value, onChange }) => (
                  <Autocomplete
                    options={adjusters || []}
                    getOptionLabel={(option: any) => option.name}
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        error={errors.adjuster !== undefined}
                        helperText={errors.adjuster?.message}
                        InputLabelProps={{ shrink: true }}
                        label="Adjuster"
                        margin="dense"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'disabled',
                        }}
                      />
                    )}
                    fullWidth
                    disableClearable
                    autoHighlight
                    onChange={(_, data) => {
                      setIsPolicyChanged(true);
                      onChange(data);
                    }}
                    value={value}
                  />
                )}
                control={control}
                onChange={([, data]) => data}
                name="adjuster"
                rules={{
                  required: {
                    value: true,
                    message: 'Adjuster is required',
                  },
                }}
              />
            </Grid>
            <Grid item xs={6} md>
              <Controller
                render={({ value, onChange }) => (
                  <Autocomplete
                    options={reinsurers || []}
                    getOptionLabel={(option: any) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        error={errors.reinsurer !== undefined}
                        helperText={errors.reinsurer?.message}
                        InputLabelProps={{ shrink: true }}
                        label="Reinsurer"
                        margin="dense"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'disabled',
                        }}
                      />
                    )}
                    fullWidth
                    disableClearable
                    autoHighlight
                    onChange={(_, data) => {
                      setIsPolicyChanged(true);
                      onChange(data);
                    }}
                    value={value}
                  />
                )}
                control={control}
                name="reinsurer"
                rules={{
                  required: {
                    value: true,
                    message: 'Reinsurer is required',
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={6}>
            <Grid item xs={6} md>
              <Controller
                render={({ onChange, value }) => (
                  <KeyboardDatePicker
                    required
                    margin="dense"
                    fullWidth
                    label="Inception Date"
                    format="MM/dd/yyyy"
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    error={errors.inceptionDate !== undefined}
                    onChange={(data) => {
                      setIsPolicyChanged(true);

                      setValue('expirationDate', addMonths(new Date('' + data), 12), {
                        shouldValidate: true,
                        shouldDirty: true,
                      });

                      onChange(data);
                    }}
                    value={value}
                  />
                )}
                control={control}
                name="inceptionDate"
                rules={{
                  required: {
                    value: true,
                    message: 'Inception Date is required',
                  },
                }}
              />
              {errors.inceptionDate && (
                <div className="invalid-feedback">{errors.inceptionDate.message}</div>
              )}
            </Grid>
            <Grid item xs={6} md>
              <Controller
                render={({ onChange, value }) => (
                  <KeyboardDatePicker
                    required
                    margin="dense"
                    fullWidth
                    label="Expiration Date"
                    format="MM/dd/yyyy"
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    maxDate={addMonths(getValues('inceptionDate'), 12)}
                    error={errors.expirationDate !== undefined}
                    onChange={(data) => {
                      setIsPolicyChanged(true);
                      onChange(data);
                    }}
                    value={value}
                  />
                )}
                control={control}
                name="expirationDate"
                rules={{
                  required: {
                    value: true,
                    message: 'Expiration Date is required',
                  },
                }}
              />
              {errors.expirationDate && (
                <div className="invalid-feedback">{errors.expirationDate.message}</div>
              )}
            </Grid>
            {isPolicyCreated && (
              <>
                <Grid item xs>
                  <Controller
                    render={({ onChange, value }) => (
                      <TextField
                        fullWidth
                        margin="dense"
                        label="Policy Number"
                        InputProps={{
                          readOnly: true,
                        }}
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        onChange={(data) => {
                          setIsPolicyChanged(true);
                          onChange(data);
                        }}
                        value={value}
                      />
                    )}
                    control={control}
                    name="policyNumber"
                  />
                </Grid>
                {policyStatus !== 'CANCELLED' && (
                  <Grid item xs={6} md>
                    <Controller
                      render={({ onChange, value }) => (
                        <TextField
                          fullWidth
                          margin="dense"
                          label="Quote Number"
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{ shrink: true }}
                          variant="outlined"
                          onChange={(data) => {
                            setIsPolicyChanged(true);
                            onChange(data);
                          }}
                          value={value}
                        />
                      )}
                      control={control}
                      name="quoteNumber"
                    />
                  </Grid>
                )}
                <Grid item xs={6} md>
                  <Controller
                    render={({ onChange, value }) => (
                      <TextField
                        fullWidth
                        margin="dense"
                        label="Policy Status"
                        defaultValue="Done"
                        InputProps={{
                          readOnly: true,
                        }}
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        onChange={(data) => {
                          setIsPolicyChanged(true);
                          onChange(data);
                        }}
                        value={value}
                      />
                    )}
                    control={control}
                    name="policyStatus"
                  />
                </Grid>
                {policyStatus === 'CANCELLED' && (
                  <Grid item xs={6} md>
                    <Controller
                      render={({ onChange, value }) => (
                        <KeyboardDatePicker
                          readOnly
                          margin="dense"
                          fullWidth
                          label="Cancellation Date"
                          format="MM/dd/yyyy"
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          error={errors.cancellationDate !== undefined}
                          onChange={(data) => {
                            onChange(data);
                          }}
                          value={value}
                        />
                      )}
                      control={control}
                      name="cancellationDate"
                    />
                  </Grid>
                )}
                <Grid item xs={6} md>
                  <Controller
                    render={({ onChange, value }) => (
                      <TextField
                        fullWidth
                        margin="dense"
                        label="Descriptive Status"
                        InputProps={{
                          readOnly: true,
                        }}
                        // InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        onChange={(data) => {
                          // onChange(data);
                        }}
                        value={value}
                      />
                    )}
                    control={control}
                    name="descriptiveStatus"
                  />
                </Grid>
              </>
            )}
          </Grid>

          {!isPolicyCreated && (
            <Grid container item xs={12} spacing={6}>
              <Grid item xs={4} md>
                <Button color="primary" onClick={handleSubmit(_onSavePolicy)}>
                  Continue
                </Button>
              </Grid>
            </Grid>
          )}
          {isPolicyCreated && (
            <>
              <Grid container item xs={12} spacing={6}>
                <Grid item xs={12} md>
                  <Controller
                    render={({ onChange, value }) => (
                      <TextField
                        required
                        fullWidth
                        error={errors.agentCommission !== undefined}
                        helperText={errors.agentCommission?.message}
                        InputLabelProps={{ shrink: true }}
                        type="number"
                        InputProps={{ inputProps: { min: 0, max: 100 } }}
                        label="Agent Commission"
                        onChange={(data) => {
                          setIsPolicyChanged(true);
                          onChange(data);
                        }}
                        value={value}
                      />
                    )}
                    control={control}
                    name="agentCommission"
                    rules={{
                      required: {
                        value: true,
                        message: 'Agent Commission is required',
                      },
                      max: {
                        value: 100,
                        message: 'Agent Commission must not be greater than 100',
                      },
                      min: {
                        value: 0,
                        message: 'Agent Commission must not be less than 0',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6} md style={{ alignSelf: 'center' }}>
                  <Controller
                    render={({ onChange, value }) => (
                      <FormControlLabel
                        control={
                          <Switch
                            name="checkedB"
                            color="primary"
                            onChange={(e) => {
                              setIsPolicyChanged(true);
                              onChange(e.target.checked);
                            }}
                            checked={value}
                          />
                        }
                        label="Agent is Netting down "
                      />
                    )}
                    control={control}
                    name="agentNettingDown"
                  />
                </Grid>
                <Grid item xs={6} md>
                  <Controller
                    render={({ onChange, value }) => (
                      <TextField
                        required={agentNettingDown}
                        disabled={!agentNettingDown}
                        fullWidth
                        error={errors.agentNettingDownPercent !== undefined}
                        helperText={errors.agentNettingDownPercent?.message}
                        InputLabelProps={{ shrink: true }}
                        type="number"
                        InputProps={{ inputProps: { min: 0, max: 100, step: 0.1 } }}
                        label="Net Down To %"
                        onChange={(e: any) => {
                          const value = e.target.value;
                          if (decimalCount(value) > 1) {
                            onChange(parseFloat(value).toFixed(1));
                          } else {
                            onChange(parseFloat(value));
                          }
                        }}
                        value={value}
                      />
                    )}
                    control={control}
                    name="agentNettingDownPercent"
                    rules={{
                      validate: (value) => {
                        const agentNettingDown = getValues('agentNettingDown');
                        if (!agentNettingDown) return true;

                        if (!value) {
                          return 'Agent Netting Down Percent is required';
                        }

                        if (value > 100) {
                          return 'Agent Netting Down Percent should be less than 100';
                        }

                        if (value < 0) {
                          return 'Agent Netting Down Percent should be greater than 0';
                        }
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6} md>
                  <Controller
                    render={({ onChange, value }) => (
                      <TextField
                        required
                        fullWidth
                        error={errors.surplusLinesTaxPct !== undefined}
                        helperText={errors.surplusLinesTaxPct?.message}
                        InputLabelProps={{ shrink: true }}
                        type="number"
                        InputProps={{ inputProps: { min: 0, max: 100 } }}
                        label="Surplus Lines Tax %"
                        onChange={(data) => {
                          setIsPolicyChanged(true);
                          onChange(data);
                        }}
                        value={value}
                      />
                    )}
                    control={control}
                    name="surplusLinesTaxPct"
                    rules={{
                      required: {
                        value: true,
                        message: 'Surplus Lines tax % is required',
                      },
                      max: {
                        value: 100,
                        message: 'Surplus Lines tax % must not be greater than 100',
                      },
                      min: {
                        value: 0,
                        message: 'Surplus Lines tax % must not be less than 0',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6} md>
                  <Controller
                    render={({ onChange, value }) => (
                      <TextField
                        required
                        fullWidth
                        error={errors.surplusLinesTaxFee !== undefined}
                        helperText={errors.surplusLinesTaxFee?.message}
                        InputLabelProps={{ shrink: true }}
                        type="number"
                        InputProps={{ inputProps: { min: 0 } }}
                        label="Surplus Lines Stamping Fee"
                        onChange={(data) => {
                          setIsPolicyChanged(true);
                          onChange(data);
                        }}
                        value={value}
                      />
                    )}
                    control={control}
                    name="surplusLinesTaxFee"
                    rules={{
                      required: {
                        value: true,
                        message: 'Surplus Lines tax fee is required',
                      },
                      min: {
                        value: 0,
                        message: 'Surplus Lines tax % must not be less than 0',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6} md>
                  <Controller
                    render={({ onChange, value }) => (
                      <TextField
                        required
                        fullWidth
                        error={errors.policySurcharge !== undefined}
                        helperText={errors.policySurcharge?.message}
                        InputLabelProps={{ shrink: true }}
                        type="number"
                        InputProps={{ inputProps: { min: 0, max: 100 } }}
                        label="Policy Surcharge"
                        onChange={(data) => {
                          setIsPolicyChanged(true);
                          onChange(data);
                        }}
                        value={value}
                      />
                    )}
                    control={control}
                    name="policySurcharge"
                    rules={{
                      required: {
                        value: true,
                        message: 'Policy Surcharge is required',
                      },
                      min: {
                        value: 0,
                        message: 'Policy Surcharge must not be less than 0',
                      },
                    }}
                  />
                </Grid>
                <Grid container item xs={12} spacing={6}>
                  <Grid item xs={4} md>
                    <Button
                      color="primary"
                      onClick={!isPolicyUpdateLoading ? handleSubmit(_updatePolicy) : undefined}
                    >
                      {isPolicyUpdateLoading ? (
                        <CircularProgress size={24} />
                      ) : (
                        'Update Policy Details'
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </form>

      <EndorsementsDialog open={open2} handleClose={handleClose2} />

      <Controller as={<input type="hidden"></input>} control={control} name="policyId"></Controller>
    </>
  );
};

MainPolicyDetails.defaultProps = {
  isPolicyCreated: false,
};

export default memo(MainPolicyDetails);

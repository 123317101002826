import { Grid } from '@material-ui/core';
import { usePageTitle } from '../../shared/hooks/use-page-title';
import SimpleCard from '../../shared/components/simple-card/SimpleCard';
import { useState } from 'react';
import PolicyList from '../../shared/components/policy-list/PolicyList';
import { useQuery } from 'react-query';
import { MESSAGE_TYPES } from '../../api/messageTypes';
import { callWs } from '../../api/websocket';
import cardContext from './CardContext';
const DashboardPage = () => {
  usePageTitle('Dashboard');

  const { data: statistics = {}, isFetching: isStatisticsLoading } = useQuery<any, any>(
    [MESSAGE_TYPES.POLICY_DASHBOARD_STATS],
    () =>
      callWs(MESSAGE_TYPES.POLICY_DASHBOARD_STATS, {
        additional: [
          'refer',
          'cancelled',
          'pastDueInvoices',
          'nonPayPending',
          'toBeCancelled',
          'policiesCancellation',
          'policiesNorc',
          'policiesNonRenewal',
          'policiesNornr',
        ],
      }),
    { staleTime: 0 },
  );

  const [selectedCard, setSelectedCard] = useState('all');

  const cards = [
    { id: 'all', title: 'All' },
    { id: 'refer', title: 'Policies Referral' },
    { id: 'pastDueInvoices', title: 'Past Due Invoices' },
    { id: 'nonPayPending', title: 'Non Pay Pending' },
    { id: 'toBeCancelled', title: 'Cancellation pending' },
    { id: 'policiesNorc', title: 'Cancellation Notice Rescind required' },
    { id: 'policiesNonRenewal', title: 'Non Renewal Notice required' },
    { id: 'policiesNornr', title: 'Non Renewal Notice Rescind required' },
    { id: 'renewals', title: 'Renewals Requiring UW Auth' },
    { id: 'referredEndorsements', title: 'Referred Endorsements' },
    { id: 'activeNOC', title: 'Active NOC' },
    { id: 'claims', title: 'Claims' },
    { id: 'messages', title: 'Messages' },
    { id: 'cancelled', title: 'Cancelled' },
  ];

  return (
    <cardContext.Provider value={selectedCard}>
      <Grid container spacing={4}>
        <Grid container item spacing={2}>
          {cards.map((card) => (
            <Grid item>
              <SimpleCard
                cardId={card.id}
                selectedCard={selectedCard}
                setSelectedCard={setSelectedCard}
                title={card.title}
                value={statistics[card.id] ?? '-'}
                isLoading={isStatisticsLoading}
              />
            </Grid>
          ))}
        </Grid>

        <Grid item xs={12}>
          <PolicyList referredOnly={selectedCard === 'refer'} />
        </Grid>
      </Grid>
    </cardContext.Provider>
  );
};

export default DashboardPage;

import {
  Box,
  Paper,
  Grid,
  FormControlLabel,
  Switch,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { useState, useEffect } from 'react';
import { Controller, useFormContext, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import InputWrapper from '../../../../inputs/InputWrapper';
import { MESSAGE_TYPES } from '../../../../../../api/messageTypes';
import { callWs } from '../../../../../../api/websocket';
import * as customToast from '../../../../../toast/customToast';
import { formatValueToSave } from '../../../../../../utils/valueFormatter';
import { handleSubmitKeyBinding } from '../../../../../../utils/formUtils';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const InsuredEntitySingleton = (props) => {
  const { insuredEntityName, productDescription, onPopup } = props;

  const {
    mutateAsync: createInsuredEntity,
    isLoading: isInsuredEntityCreateLoading,
  } = useMutation(MESSAGE_TYPES.INSURED_ENTITY_CREATE, (data: any) =>
    callWs(MESSAGE_TYPES.INSURED_ENTITY_CREATE, data),
  );

  const {
    mutateAsync: updateInsuredEntity,
    isLoading: isInsuredEntityUpdateLoading,
  } = useMutation(MESSAGE_TYPES.INSURED_ENTITY_UPDATE, (data: any) =>
    callWs(MESSAGE_TYPES.INSURED_ENTITY_UPDATE, data),
  );

  const { mutateAsync: deleteInsuredEntity } = useMutation(
    MESSAGE_TYPES.INSURED_ENTITY_DELETE,
    (data: any) => callWs(MESSAGE_TYPES.INSURED_ENTITY_DELETE, data),
  );

  const { watch: watchPolicyForm, getValues: getValuesPolicyForm, setValue } = useFormContext();
  const {
    control,
    errors,
    reset,
    getValues,
    formState: { isValid },
    handleSubmit,
  } = useForm({
    mode: 'onChange',
  });
  const [isEnabled, setIsEnabled] = useState(false);

  const insuredEntityFormData = watchPolicyForm(`insuredEntities.${insuredEntityName}`) ?? [];

  useEffect(() => {
    if (insuredEntityFormData.length > 0) {
      setIsEnabled(true);
      reset({
        ...insuredEntityFormData[0],
      });
    }
  }, []);

  const _saveInsuredEntity = async (data) => {
    try {
      const properties = {};

      productDescription.data
        .filter((entity) => !entity.showOnExpand)
        .forEach((entity) => {
          properties[entity.name] = formatValueToSave(data[entity.name], entity);
        });

      if (insuredEntityFormData.length > 0) {
        await updateInsuredEntity({
          id: insuredEntityFormData[0].id,
          policy_id: getValuesPolicyForm('policyId'),
          entity_type: insuredEntityName,
          properties: {
            ...properties,
          },
        });
        setValue(`insuredEntities.${insuredEntityName}`, [
          { id: insuredEntityFormData[0].id, ...properties },
        ]);
      } else {
        const id = await createInsuredEntity({
          policy_id: getValuesPolicyForm('policyId'),
          entity_type: insuredEntityName,
          properties: {
            ...properties,
          },
        });
        setValue(`insuredEntities.${insuredEntityName}`, [{ id, ...properties }]);
      }
    } catch (err) {
      customToast.error(
        err.messageCode,
        {
          id: `insured-entity-${insuredEntityName}-save-error`,
        },
        err.messages,
      );
    }
  };

  const _toggleInsuredEntity = async (checked) => {
    if (checked) {
      setIsEnabled(true);
    } else {
      if (insuredEntityFormData.length > 0) {
        try {
          await deleteInsuredEntity({
            id: insuredEntityFormData[0].id,
          });
          setValue(`insuredEntities.${insuredEntityName}`, []);
        } catch (err) {
          customToast.error(err.messageCode, {
            id: `insured-entity-${insuredEntityName}-delete-error`,
          });
        }
      }

      setIsEnabled(false);
    }
  };

  return (
    <Paper>
      <Box p={3}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  onChange={(e) => _toggleInsuredEntity(e.target.checked)}
                  checked={isEnabled}
                />
              }
              label={`Add ${insuredEntityName}`}
            />
          </Grid>
          {isEnabled && (
            <form
              style={{ width: '100%', padding: '1.25rem' }}
              onKeyDown={(e) => handleSubmitKeyBinding(e, handleSubmit(_saveInsuredEntity))}
            >
              <Grid container item spacing={3}>
                {productDescription.data.map((element) => (
                  <Grid key={element.name} item xs={12} md={4}>
                    <Controller
                      render={({ onChange, value }) => {
                        return (
                          <InputWrapper
                            properties={element}
                            value={value}
                            onChange={onChange}
                            error={!!errors?.[element.name]?.message}
                            helperText={errors?.[element.name]?.message}
                          />
                        );
                      }}
                      control={control}
                      rules={{
                        required: {
                          value: element?.validation?.front?.required,
                          message: 'The field is required',
                        },
                        min: {
                          value: element?.validation?.front?.min,
                          message: `The minimum value is ${formatter.format(
                            element?.validation?.front?.min,
                          )}`,
                        },
                        max: {
                          value: element?.validation?.front?.max,
                          message: `The maximum value is ${formatter.format(
                            element?.validation?.front?.max,
                          )}`,
                        },
                      }}
                      name={element.name}
                      defaultValue={element.default}
                    />
                  </Grid>
                ))}
                <Grid item xs={4} style={{ alignSelf: 'center' }}>
                  <Button
                    color="primary"
                    onClick={handleSubmit(_saveInsuredEntity)}
                    disabled={
                      !isValid || isInsuredEntityCreateLoading || isInsuredEntityUpdateLoading
                    }
                  >
                    {isInsuredEntityCreateLoading || isInsuredEntityUpdateLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      'Save'
                    )}
                  </Button>
                </Grid>
                <Grid item xs={7}></Grid>
              </Grid>
            </form>
          )}
        </Grid>
      </Box>
    </Paper>
  );
};

export default InsuredEntitySingleton;

import { useContext, useLayoutEffect } from 'react';

import PolicyList from '../../../shared/components/policy-list/PolicyList';
import { LayoutContext } from '../../../shared/providers/LayoutProvider';

const PolicyListPage = () => {
  const { layoutData, setLayoutData } = useContext(LayoutContext);

  useLayoutEffect(() => {
    setLayoutData({ ...layoutData, headerTitle: 'Policy List' });
  }, []);

  return <PolicyList />;
};

export default PolicyListPage;

export const validateInput = (rowData, element) => {
  let validation: { isValid: boolean; helperText?: string } = { isValid: true };

  if (validation.isValid && element.validation?.front?.required) {
    validation = !isInputEmpty(element, rowData[element.name])
      ? { isValid: true }
      : { isValid: false, helperText: `Field is required` };
  }

  if (
    validation.isValid &&
    element.validation?.front?.min !== undefined &&
    element.validation?.front?.min !== null
  ) {
    validation = !isValueLess(element, rowData[element.name])
      ? { isValid: true }
      : { isValid: false, helperText: `The minimum value is ${element.validation?.front?.min}` };
  }

  if (
    validation.isValid &&
    element.validation?.front?.max !== undefined &&
    element.validation?.front?.max !== null
  ) {
    validation = !isValueMore(element, rowData[element.name])
      ? { isValid: true }
      : { isValid: false, helperText: `The maximum value is ${element.validation?.front?.max}` };
  }

  return validation;
};

export const isInputEmpty = (element, value) => {
  switch (element.type) {
    case 'int':
    case 'float':
      return !value || value === '';
    case 'text':
    case 'longText':
    case 'enum':
      return !value || value.trim() === '';
    case 'boolean':
      return false;
    case 'date':
      return !value || value === '';
    case 'multi-enum':
      return !value || value.length === 0;
    case 'reference':
      return !value || value.length === 0;
    default:
      return true;
  }
};

export const isValueLess = (element, value) => {
  switch (element.type) {
    case 'int':
      return value ? parseInt(value) < element.validation.front.min : false;
    case 'float':
      return value ? parseFloat(value) < element.validation.front.min : false;
    // case 'date':
    //   return !value || value === '';
    default:
      return false;
  }
};

export const isValueMore = (element, value) => {
  switch (element.type) {
    case 'int':
      return value ? parseInt(value) > element.validation.front.max : false;
    case 'float':
      return value ? parseFloat(value) > element.validation.front.max : false;
    // case 'date':
    //   return !value || value === '';
    default:
      return false;
  }
};

export const enabledRules = (rowData, rules) => {
  if (!rules) return true;

  let enabled = true;
  // TODO: refactor this at a later date to able to parse more complex rules
  if (Object.keys(rules).includes('customClientDialogRule')) {
    enabled = (rowData['hasCareOfName'] && rowData['isCompany']) || !rowData['isCompany'];
  } else {
    Object.keys(rules).forEach((field) => {
      enabled = rules[field] === rowData[field];
    });
  }

  return enabled;
};

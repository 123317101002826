import { InputProperty } from '../shared/components/inputs/InputWrapper';

export const groupBy = (arr: any[] = [], property = 'name') => {
  return arr.reduce((acc, value) => {
    // Group initialization
    if (!acc[value[property]]) {
      acc[value[property]] = [];
    }

    // Grouping
    acc[value[property]].push(value);

    return acc;
  }, {});
};

export const removeNullValues = (obj: any) => {
  return Object.keys(obj)
    .filter((k) => obj[k] != null)
    .reduce((a, k) => ({ ...a, [k]: obj[k] }), {});
};

export const hasPopupVisualRule = (visualRules) => {
  return (
    visualRules?.filter(
      (visualRule) => visualRule.actionTrue === 'popup' || visualRule.actionFalse === 'popup',
    )?.length > 0 ?? false
  );
};

export const decimalCount = (num) => {
  const numStr = String(num);
  if (numStr.includes('.')) {
    return numStr.split('.')[1].length;
  }

  return 0;
};

export const roundToTwo = (num: number) => {
  return +(Math.round((num + 'e+2') as any) + 'e-2');
};

export const getInputDescriptionsFromReferences = (
  references: { refersTo: string; key: string }[],
  productDescription: any,
): (InputProperty & { reference?: string })[] => {
  const inputDescriptions = references.map((reference) => {
    if (reference.refersTo === 'questions') {
      const questionDescription = productDescription?.[reference.refersTo]?.find(
        (element) => element.id === reference.key,
      );

      return {
        label: questionDescription.question,
        name: questionDescription.id,
        ...questionDescription.data,
        visualRules: questionDescription.visualRules,
        defaultValue: questionDescription.data.default,
        reference: 'questions',
      };
    }

    return {
      ...(productDescription?.[reference.refersTo]?.find(
        (element) => element.id === reference.key,
      ) ?? {}),
      reference: reference.refersTo,
    };
  });

  return inputDescriptions;
};

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import useStyles from './SimpleCard.styles';
import { ButtonBase, CircularProgress } from '@material-ui/core';

interface SimpleCardProps {
  title: string;
  value?: string;
  cardId: string;
  isLoading: boolean;

  selectedCard: string;
  setSelectedCard: (cardId: string) => void;
}

const SimpleCard: React.FC<SimpleCardProps> = (props) => {
  const { title, value, cardId, isLoading, selectedCard, setSelectedCard } = props;
  const classes = useStyles();

  return (
    <ButtonBase
      className={classes.cardAction}
      onClick={() => {
        if (value !== '-') {
          setSelectedCard(cardId);
        }
      }}
    >
      <Card
        className={classes.root}
        style={{
          backgroundColor: cardId === selectedCard ? '#3f51b5' : undefined,
          color: cardId === selectedCard ? '#fff' : undefined,
          padding: '5px',
        }}
      >
        <CardContent style={{ padding: '5px' }}>
          <h4 style={{ textAlign: 'center', margin: '5px' }}>{title}</h4>
          <Typography align="center" variant="body2" component="p">
            {!isLoading ? (
              value
            ) : (
              <CircularProgress
                size={15}
                color={cardId === selectedCard ? 'secondary' : 'primary'}
              />
            )}
          </Typography>
        </CardContent>
      </Card>
    </ButtonBase>
  );
};

export default SimpleCard;

import { useState } from 'react';

import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { InputWrapperProps } from '../InputWrapper';
import { handleKey } from '../../../../utils/formUtils';

export interface AutocompleteInputProps extends InputWrapperProps {
  value: any;
  onChange: (value: any) => void;
  multiple?: boolean;
  getOptionLabel?: (option: any) => string;
}

const AutocompleteInput: React.FC<AutocompleteInputProps> = (props) => {
  const {
    properties,
    displayMode,
    value,
    onChange,
    afterOnChange,
    evaluateRules,
    onKeyDown,
    displayLabel,
    multiple = false,
    error,
    helperText,
    disabled,
    getOptionLabel,
  } = props;

  const [inputValue, setInputValue] = useState('');

  if (displayMode === 'read') {
    if (properties.type === 'reference') {
      return <span>{getOptionLabel?.(value) ?? ''}</span>;
    } else {
      return <span>{multiple ? value.join(', ') : value || '-'}</span>;
    }
  }

  return (
    <Autocomplete
      value={value || (multiple ? [] : '')}
      getOptionLabel={getOptionLabel}
      onChange={(_: any, newValue: string | null) => {
        onChange(newValue);
        if (evaluateRules) {
          evaluateRules(newValue);
        }
        afterOnChange?.();
      }}
      inputValue={inputValue}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      disabled={disabled}
      onKeyDown={(e) => (onKeyDown ? onKeyDown(e) : handleKey(e))}
      options={properties.values || []}
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={{ shrink: true }}
          label={displayLabel ? properties.label : undefined}
          margin="dense"
          required={properties?.validation?.front?.required}
          error={error}
          helperText={helperText}
        />
      )}
      fullWidth
      clearOnEscape
      autoHighlight
      multiple={multiple}
    />
  );
};

export default AutocompleteInput;

import React from 'react';
import { MenuItem, InputLabel, Select, FormControl, FormHelperText } from '@material-ui/core';

import { InputWrapperProps } from '../InputWrapper';
import AutocompleteInput from '../autocomplete-input/AutocompleteInput';
import { handleKey } from '../../../../utils/formUtils';

export interface EnumInputProps extends InputWrapperProps {
  value: any;
  onChange: (value: any) => void;
}

const EnumInput: React.FC<EnumInputProps> = (props) => {
  const {
    properties,
    displayMode,
    value,
    onChange,
    afterOnChange,
    evaluateRules,
    onKeyDown,
    displayLabel,
    error,
    helperText,
    disabled,
  } = props;
  const [open, setOpen] = React.useState(false);
  switch (properties.presentation) {
    case 'droplist':
      if (displayMode === 'read') {
        return <span>{value}</span>;
      }

      return (
        <div style={{ marginTop: '5.5px' }}>
          <FormControl
            fullWidth
            required={properties?.validation?.front?.required}
            error={error}
            onKeyDown={(e) => (onKeyDown ? onKeyDown(e) : handleKey(e))}
          >
            {displayLabel && (
              <InputLabel shrink={true} id={properties.name}>
                {properties.label}
              </InputLabel>
            )}
            <Select
              labelId={properties.name}
              id={properties.name}
              disabled={disabled}
              value={value != null ? value : ''}
              onOpen={(e) => {
                setOpen(true);
                handleKey(e);
              }}
              onClose={() => {
                setOpen(false);
              }}
              open={open}
              onChange={(e) => {
                onChange(e.target.value);
                if (evaluateRules) {
                  evaluateRules(e.target.value);
                }
                afterOnChange?.();
              }}
            >
              <MenuItem value="" onKeyDown={(e) => (onKeyDown ? onKeyDown(e) : handleKey(e))}>
                <em>None</em>
              </MenuItem>
              {properties?.values?.map((property) => (
                <MenuItem
                  key={property}
                  value={property}
                  onKeyDown={(e) => (onKeyDown ? onKeyDown(e) : handleKey(e))}
                >
                  {property}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{helperText}</FormHelperText>
          </FormControl>
        </div>
      );
    case 'multiselect':
      if (displayMode === 'read') {
        return <span>{value ? value.join(', ') : ''}</span>;
      }

      return (
        <FormControl
          fullWidth
          required={properties?.validation?.front?.required}
          error={error}
          onKeyDown={(e) => (onKeyDown ? onKeyDown(e) : handleKey(e))}
        >
          {displayLabel && (
            <InputLabel shrink={true} id={properties.name}>
              {properties.label}
            </InputLabel>
          )}
          <Select
            fullWidth
            labelId={properties.name}
            id={properties.name}
            disabled={disabled}
            multiple
            value={value || []}
            onChange={(e) => {
              onChange(e.target.value);
              if (evaluateRules) {
                evaluateRules(e.target.value);
              }
              afterOnChange?.();
            }}
          >
            {properties?.values?.map((childItem) => (
              <MenuItem
                key={childItem}
                value={childItem}
                onKeyDown={(e) => (onKeyDown ? onKeyDown(e) : handleKey(e))}
              >
                {childItem}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
      );
    case 'autocomplete':
    case 'multiautocomplete':
      return (
        <AutocompleteInput
          properties={properties}
          value={value}
          onChange={onChange}
          afterOnChange={afterOnChange}
          evaluateRules={evaluateRules}
          onKeyDown={onKeyDown}
          disabled={disabled}
          displayMode={displayMode}
          displayLabel={displayLabel}
          multiple={properties.presentation === 'multiautocomplete'}
          error={error}
          helperText={helperText}
        />
      );
    default:
      return <></>;
  }
};

export default EnumInput;

import { Tooltip } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { Warning } from '@material-ui/icons';
import { BackendErrors } from '../../../../enums/BackendErrors';
const ErrorChecking = ({ errorCode }) => {
  let message = BackendErrors[errorCode];

  return (
    <>
      {errorCode === 14000 ? (
        <Tooltip title={message}>
          <IconButton>
            <Check style={{ fill: 'green' }}></Check>
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title={message}>
          <IconButton>
            <Warning style={{ fill: 'orange' }}></Warning>
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};

export default ErrorChecking;

import { forwardRef, memo, useEffect, useRef } from 'react';
import MaterialTable, { Icons } from '@material-table/core';
import SearchIcon from '@material-ui/icons/Search';

import { MESSAGE_TYPES } from '../../../../../api/messageTypes';
import { callWs } from '../../../../../api/websocket';
import { USState } from '../../../../enums/USState';
import InputWrapper from '../../../inputs/InputWrapper';

const tableIcons: Icons = {
  Filter: forwardRef((props, ref) => <SearchIcon {...props} ref={ref} />),
};

export interface ClientsSearchProps {
  filterRows: any;
  handleAdd: (data: any) => void;
  existedIds?: string[];
  setFilterRows: (filterRows: any) => void;
}

const inputDescriptions = [
  {
    name: 'isCompany',
    label: 'Is company?',
    type: 'boolean',
    presentation: 'switch',
    required: true,
  },
  {
    name: 'companyName',
    label: 'Company Name',
    type: 'text',
    presentation: 'text',
    required: false,
    rules: {
      enable: {
        isCompany: true,
      },
    },
  },
  {
    name: 'hasCareOfName',
    label: 'Has care of name?',
    type: 'boolean',
    presentation: 'switch',
    required: true,
  },

  {
    name: 'firstName',
    label: 'First Name',
    type: 'text',
    presentation: 'text',
    required: false,
    rules: {
      enable: {
        hasCareOfName: true,
      },
    },
  },
  {
    name: 'lastName',
    label: 'Last Name',
    type: 'text',
    presentation: 'text',
    required: false,
    rules: {
      enable: {
        hasCareOfName: true,
      },
    },
  },
  {
    name: 'contactEmail',
    label: 'Email',
    type: 'text',
    presentation: 'email',
    required: true,
  },
  {
    name: 'primaryPhone',
    label: 'Primary Phone',
    type: 'text',
    presentation: 'text',
    required: true,
  },
  {
    name: 'addressLine1',
    label: 'Address 1',
    type: 'text',
    presentation: 'text',
    required: true,
  },
  {
    name: 'addressLine2',
    label: 'Address 2',
    type: 'text',
    presentation: 'text',
    required: false,
  },
  {
    name: 'country',
    label: 'Country',
    type: 'enum',
    presentation: 'autocomplete',
    required: true,
    isDisabled: true,
    defaultValue: 'US',
    values: ['US', 'GR'],
  },
  {
    name: 'state',
    label: 'State',
    type: 'enum',
    presentation: 'autocomplete',
    required: true,
    values: Object.keys(USState),
  },
  {
    name: 'city',
    label: 'City',
    type: 'text',
    presentation: 'text',
    required: true,
  },
  {
    name: 'zip',
    label: 'Zip Code',
    type: 'text',
    presentation: 'text',
    required: true,
  },
];

const ClientsSearch: React.FC<ClientsSearchProps> = (props) => {
  const { filterRows, handleAdd, existedIds = [], setFilterRows } = props;

  const tableRef = useRef<any>();

  const columns: any[] = inputDescriptions.map((element) => ({
    title: element.label,
    field: element.name,
    defaultFilter: filterRows[element.name],

    render: (rowData) => {
      return (
        <InputWrapper
          properties={element}
          value={rowData[element.name]}
          onChange={() => {}}
          displayMode="read"
          displayLabel={false}
        />
      );
    },
  }));

  useEffect(() => {
    setTimeout(() => {
      tableRef.current &&
        tableRef.current.onQueryChange({
          filters: Object.keys(filterRows).map((key) => ({
            column: {
              field: key,
            },
            value: filterRows[key],
          })),
        });
    }, 1000);
  }, [filterRows]);

  return (
    <MaterialTable
      style={{ width: '100%' }}
      tableRef={tableRef}
      columns={columns}
      icons={tableIcons}
      data={(query) =>
        new Promise(async (resolve, reject) => {
          const filters = {
            ...query.filters
              .map((filter: any) => {
                return {
                  [filter.column.field]: filter.value,
                };
              })
              .reduce((acc, next) => Object.assign(acc, next), {}),
          };
          const response: any = await callWs(MESSAGE_TYPES.PERSONS_SEARCH, {
            paginate: {
              page: query.page + 1,
              per_page: query.pageSize,
            },
            filter: filters,
          });

          resolve({
            data: response?.data ?? [],
            page: response?.currentPage - 1 ?? 0,
            totalCount: response?.total ?? 0,
          });
        })
      }
      actions={[
        (rowData: any) => ({
          icon: 'add',
          tooltip: 'Add client',
          onClick: () => {
            handleAdd(rowData);
          },
          disabled: existedIds.includes(rowData.id),
        }),
      ]}
      options={{
        search: false,
        paging: true,
        filtering: true,
        grouping: false,
        sorting: false,
        draggable: false,
        showTitle: false,
        pageSize: 5,
        pageSizeOptions: [5],
        rowStyle: { fontFamily: 'Roboto', fontSize: 14 },
        actionsColumnIndex: -1,
        debounceInterval: 800,
      }}
      localization={{
        body: {
          emptyDataSourceMessage: 'No Clients found.',
        },
      }}
    />
  );
};

export default memo(ClientsSearch);

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  makeStyles,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { MESSAGE_TYPES } from '../../../../api/messageTypes';
import { callWs } from '../../../../api/websocket';
import InputWrapper from '../../inputs/InputWrapper';
import * as customToast from '../../../toast/customToast';

export interface EmailDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (value: any) => void;
  fileName: string;
  path: string;
  emails?: any;
}

const useStyles = makeStyles(() => ({
  paper: { minWidth: 500 },
}));

const EmailDialog: React.FC<EmailDialogProps> = (props) => {
  const { open, onClose, onSubmit, fileName, path, emails } = props;

  const classes = useStyles();

  const [value, setValue] = useState(emails);

  const {
    mutateAsync: sendDocumentByEmail,
    isLoading: isSendDocumentByEmailLoading,
  } = useMutation(MESSAGE_TYPES.DOCUMENT_SEND_BY_EMAIL, (data: any) =>
    callWs(MESSAGE_TYPES.DOCUMENT_SEND_BY_EMAIL, data),
  );

  return (
    <Dialog classes={{ paper: classes.paper }} open={open} onClose={onClose}>
      <DialogTitle>Email Document</DialogTitle>
      <DialogContent>
        <TextField
          label={`Send ${fileName}`}
          fullWidth
          type="text"
          multiline
          rows="4"
          value={value}
          onChange={(event) => {
            setValue(event.target.value);
          }}
          helperText="Separate multiple emails with comma eg ( email1@test.com, email2@test.com )"
        ></TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
        <Button
          onClick={async () => {
            try {
              await sendDocumentByEmail({ emails: value, name: fileName, path: path });
              customToast.success('Email was send successfully');
              onClose();
            } catch (err) {
              customToast.error(err.messageCode);
            }
          }}
          color="primary"
          disabled={isSendDocumentByEmailLoading}
        >
          {isSendDocumentByEmailLoading ? (
            <CircularProgress color="primary" size={24} />
          ) : (
            'Send Email'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmailDialog;
